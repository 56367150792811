import { Card, Container } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { AuthenticatedUser, BrokerContainer, Project, UserBillableUnitInfo } from '../api/CloudApi/types'
import NavigationBar from '../components/navigation/NavigationBar'
import CloudApi from '../api/CloudApi'
import { NavLink } from 'react-router-dom'
import {
    AddIcon,
    ArrowEastIcon,
    ArrowNorthIcon,
    ArrowSouthIcon,
    ArrowWestIcon,
    BrokersIcon,
    ClickableArrowIcon,
    DeleteIcon,
    ErrorIcon,
    RefreshIcon,
} from '../assets/Icons'
import CreateBrokerModal from '../components/modals/CreateBrokerModal'
import LoadingContainer from '../components/LoadingContainer'
import ErrorContainer from '../components/ErrorContainer'
import NotFoundContaner from '../components/NotFoundContainer'
import { toast } from 'react-toastify'
import HelpOverlayModalModal from '../components/modals/HelpOverlayModal'
import DemoHelpCard from '../components/cards/DemoHelpCard'
import { isDemo, isGuidedDemo } from '../utils/demo'
import { hasPermission, Permission } from '../utils/permission'
import { OrganisationRoutes, ProjectRoutes } from '../Routes'
import { BreadCrumb, BreadCrumbs } from '../types/BreadCrumbs'
import CliHintContainer from '../components/CliHintContainer'
import BrokerListItem from '../components/BrokerListItem'
import { PageDetails } from '../utils/pageDetails'
import { ConfirmDialogProperties } from '../types/ConfirmDialogProperties'
import { formattedToastMessage } from '../utils/toast'
import { ProductAnalyticsContext } from '../utils/ProductAnalytics'

interface BrokerListProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined
    projects: Array<Project>
    currentProject: Project | undefined
    currentUser: AuthenticatedUser | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export default function BrokerList(props: BrokerListProps) {
    const [pageState, setPageState] = useState<'LOADING' | 'DONE' | 'ERROR'>('LOADING')
    const [brokers, setBrokers] = useState<Array<BrokerContainer>>()
    const [showCreateBrokerModal, setShowCreateBrokerModal] = useState<boolean>(false)
    const [showHelpModal, setShowHelpModal] = useState(false)
    const [showDemoModal, setShowDemoModal] = useState(isGuidedDemo())

    const HIGHLIGHT_HELP_OBJECT_STYLE = showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}
    const HIGHLIGHT_DEMO_OBJECT_STYLE = showDemoModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}

    useEffect(() => {
        if (props.currentProject?.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Brokers - ${props.currentProject?.displayName}`,
                productAnalyticsEventName: 'Project Brokers',
            })
        }
    }, [props.currentProject])

    useEffect(() => {
        console.debug('Mounted broker list page!')
    }, [])

    useEffect(() => {
        if (props.currentProject) {
            setBrokerState()
        }
    }, [props.currentProject])

    const setBrokerState = async () => {
        const projectUid = props.currentProject?.uid
        const user = props.currentUser
        if (projectUid && user) {
            try {
                setPageState('LOADING')
                const brokers = await CloudApi.listNonPersonalBrokers(projectUid, user)
                setBrokers(brokers)
                setPageState('DONE')
            } catch (err) {
                setPageState('ERROR')
            }
        }
    }

    const deleteBroker = async (brokerName: string) => {
        if (props.currentProject?.uid && props.currentUser) {
            setPageState('LOADING')
            try {
                await CloudApi.deleteBroker(props.currentProject.uid, brokerName, props.currentUser)
                toast.success(formattedToastMessage(`Broker deleted`, `Successfully deleted the broker ${brokerName}.`))
                setBrokerState()
                setPageState('DONE')
            } catch (err: any) {
                toast.error(formattedToastMessage('Error', `Failed to delete the broker.`))
            }
        }
    }

    const createBrokerFakeDropzone = () => {
        if (hasPermission(Permission.PROJECT_EDITOR_BROKER, props.currentBillableUnit, props.currentProject)) {
            return (
                <>
                    <button
                        className="btn w-100 m-0 p-0"
                        onClick={() => setShowCreateBrokerModal(true)}
                        style={HIGHLIGHT_HELP_OBJECT_STYLE}
                    >
                        {helpTextCreateBrokerDropzone()}
                        <div className="d-flex" style={{ height: '47px !important' }}>
                            <div className="col-12 dropzone p-1 remotive-primary-0-background rounded-2 w-100 ">
                                <p className="m-0 remotive-font-md remotive-primary-70-color">
                                    <AddIcon className="me-1" sx={{ fontSize: 35 }} /> Click here to{' '}
                                    <b>create a new broker</b>
                                </p>
                            </div>
                        </div>
                    </button>
                </>
            )
        }
        return <></>
    }

    const demoMessage = () => {
        return (
            <DemoHelpCard
                title={
                    <div className="text-start">
                        <p className="remotive-font-md mb-1">
                            <b>These are all the brokers in this project.</b> A broker is required to be able to play a
                            recording, they are created and managed here.
                        </p>
                        <p className="remotive-font-sm m-0">
                            We will create a personal broker for you automatically, each user will have a personal
                            broker per project. We have already uploaded the appropriate signal database for the demo
                            recording, you don't have to do anything here.
                        </p>
                    </div>
                }
            />
        )
    }

    const brokerListBody = () => {
        if (brokers !== undefined && brokers.length > 0) {
            return (
                <>
                    {helpTextBrokerDetails()}
                    {brokers.map((broker, index) => {
                        return (
                            <div
                                key={broker.url}
                                className="rounded remotive-primary-10-background m-1 mx-0 px-1"
                                style={index === 0 ? HIGHLIGHT_HELP_OBJECT_STYLE : {}}
                            >
                                <BrokerListItem
                                    currentProject={props.currentProject!}
                                    currentBillableUnit={props.currentBillableUnit!}
                                    broker={broker}
                                    deleteBrokerFunction={() =>
                                        props.openConfirmationDialog({
                                            dialogTitle: 'Are you sure?',
                                            bodyText: (
                                                <>
                                                    Are you sure you want to delete the broker <b>{broker.name}</b>?
                                                </>
                                            ),
                                            bodySubtitle: 'You can not undo this action.',
                                            confirmButtonText: 'Yes, delete it',
                                            cancelButtonText: 'No, cancel',
                                            handleCancelFunction: () => console.log,
                                            handleConfirmFunction: () => deleteBroker(broker.name),
                                        } as ConfirmDialogProperties)
                                    }
                                />
                            </div>
                        )
                    })}
                    {createBrokerFakeDropzone()}
                </>
            )
        }
        return (
            <div>
                <NotFoundContaner
                    infoText="We couldn't find any brokers..."
                    secondaryText="Create one in a few seconds!"
                />
                <button
                    className="btn remotive-btn remotive-btn-primary"
                    onClick={() => setShowCreateBrokerModal(true)}
                >
                    Create new broker
                </button>
            </div>
        )
    }

    const helpTextCreateBrokerButton = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginLeft: -200 }}
                >
                    <div className="d-flex">
                        <p className="text-light text-start m-0">
                            Click this button to <br />
                            create a new broker
                        </p>
                        <ArrowEastIcon sx={{ fontSize: 30 }} className="ms-3" />
                    </div>
                </div>
            </>
        )
    }

    const demoTextGoToRecordings = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showDemoModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginLeft: -10, marginTop: 100 }}
                >
                    <div className="d-flex align-items-center">
                        <ArrowWestIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0 fs-6">1. Go here to choose a recording to play</p>
                    </div>
                </div>
            </>
        )
    }

    const helpTextCreateBrokerDropzone = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex justify-content-center flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: 60, marginLeft: 100 }}
                >
                    <div className="d-flex flex-column  align-items-center">
                        <ArrowNorthIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0">
                            You can also click here <br />
                            to create a new broker
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const helpTextBrokerDetails = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: -90, marginLeft: 130 }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <p className="text-light text-start m-0">
                            Click to see more information <br />
                            about this broker
                        </p>
                        <ArrowSouthIcon sx={{ fontSize: 30 }} className="mt-2" />
                    </div>
                </div>
            </>
        )
    }

    const createBroker = () => {
        setShowCreateBrokerModal(true)
        setShowDemoModal(false)
        setShowHelpModal(false)
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center text-truncate">
                    <div className="d-flex align-items-center text-truncate">
                        <BrokersIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 mb-0 lexend-light text-truncate">Brokers</p>
                    </div>
                    <div>
                        {helpTextCreateBrokerButton()}
                        <button
                            style={{ ...HIGHLIGHT_DEMO_OBJECT_STYLE, ...HIGHLIGHT_HELP_OBJECT_STYLE }}
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1 "
                            onClick={() => createBroker()}
                        >
                            <AddIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        <button
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                            onClick={() => {
                                setBrokers(undefined)
                                setBrokerState()
                            }}
                        >
                            <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const getBody = () => {
        switch (pageState) {
            case 'LOADING':
                return <LoadingContainer spinnerSize="sm" />

            case 'ERROR':
                return <ErrorContainer errorText="We couldn't fetch your brokers..." />

            case 'DONE':
                return (
                    <>
                        {isDemo() && demoMessage()}
                        {brokerListBody()}
                    </>
                )

            default:
                return <LoadingContainer spinnerSize="sm" />
        }
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
            ],
            current: {
                title: 'Brokers',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                setDemoOverlayFunction={setShowDemoModal}
                zIndexOnTop={showDemoModal}
                isDemoGuideActive={showDemoModal}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                {demoTextGoToRecordings()}
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <Card className="shadow-sm rounded-4 border-0 mb-4" style={{ minHeight: '400px' }}>
                        <Card.Body className="pb-3 pt-3">{getBody()}</Card.Body>
                    </Card>
                </div>
                <CliHintContainer
                    productAnalyticsProperties={{
                        productAnalyticsContext: ProductAnalyticsContext.BROKERS_LIST,
                        currentBillableUnit: props.currentBillableUnit,
                        currentUser: props.currentUser,
                    }}
                    hints={[
                        {
                            title: 'List brokers',
                            command: `remotive cloud brokers list --project ${props.currentProject?.uid}`,
                        },
                        {
                            title: 'Create new broker',
                            subtitle:
                                'For most cases you do not need to start a broker, we will create a personal broker for you automatically',
                            command: `remotive cloud brokers create new-broker-name --project ${props.currentProject?.uid}`,
                        },
                    ]}
                />
            </Container>

            {/* Modals */}
            <CreateBrokerModal
                currentProject={props.currentProject}
                show={showCreateBrokerModal}
                handleCloseFunction={() => {
                    setShowCreateBrokerModal(false)
                    setShowDemoModal(isGuidedDemo())
                }}
                reloadBrokersList={() => setBrokerState()}
            />
            <HelpOverlayModalModal
                handleCloseFunction={() => {
                    setShowHelpModal(false)
                    setShowDemoModal(false)
                }}
                show={showHelpModal || showDemoModal}
            />
        </div>
    )
}
