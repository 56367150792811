import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded'
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded'
import HelpOutlineRounded from '@mui/icons-material/HelpOutlineRounded'
import WarningAmberRounded from '@mui/icons-material/WarningAmberRounded'

interface TextBannerProps {
    className?: string
    type: BannerType
    boldText: string
    bodyTextElement: JSX.Element
}

type TextBannerOptions = {
    icon: JSX.Element
    background: string
    textColor: string
}

export enum BannerType {
    SUCCESS,
    WARNING,
    ERROR,
    INFO,
}

const ICON_SIZE = 20

const DEFAULT_STYLE: TextBannerOptions = {
    icon: (
        <>
            <HelpOutlineRounded sx={{ fontSize: ICON_SIZE }} />
        </>
    ),
    background: 'remotive-primary-30-background',
    textColor: 'remotive-primary-90-color',
}

const styles = new Map<BannerType, TextBannerOptions>([
    [
        BannerType.SUCCESS,
        {
            icon: (
                <>
                    <CheckCircleOutlineRounded sx={{ fontSize: ICON_SIZE }} className="remotive-success-70-color" />
                </>
            ),
            background: 'remotive-success-30-background',
            textColor: 'remotive-success-90-color',
        },
    ],
    [
        BannerType.WARNING,
        {
            icon: (
                <>
                    <WarningAmberRounded sx={{ fontSize: ICON_SIZE }} className="remotive-warning-80-color" />
                </>
            ),
            background: 'remotive-warning-30-background',
            textColor: 'remotive-warning-90-color',
        },
    ],
    [
        BannerType.ERROR,
        {
            icon: (
                <>
                    <ErrorOutlineRounded className="text-danger" sx={{ fontSize: ICON_SIZE }} />
                </>
            ),
            background: 'bg-danger-subtle',
            textColor: 'text-danger',
        },
    ],
    [BannerType.INFO, DEFAULT_STYLE],
])

export default function TextBanner(props: TextBannerProps) {
    const style = styles.get(props.type) ?? DEFAULT_STYLE

    return (
        <div className={`rounded-4 shadow-sm ${style.background} p-2 px-3 ${props.className}`}>
            <div className="d-flex align-items-center">
                <div className="me-3 remotive-font-sm">{style.icon}</div>
                <p className={`${style.textColor} remotive-font-sm lexend-bold m-0 text-nowrap`}>{props.boldText}</p>
                <div className="remotive-font-sm ms-3">{props.bodyTextElement}</div>
            </div>
        </div>
    )
}
