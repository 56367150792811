import { Container } from 'react-bootstrap'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { NAVIGATION_SIDE_BAR_ID } from '../components/navigation/NavigationBar'

interface NavbarAwareContainerProps {
    content: JSX.Element
}

const PAGE_CARD_SIDE_PADDING = -8

export default function NavbarAwareContainer(props: NavbarAwareContainerProps) {
    const { height, width } = useWindowDimensions()

    return (
        <Container
            style={{
                maxWidth:
                    width -
                    ((document?.getElementById(NAVIGATION_SIDE_BAR_ID)?.getBoundingClientRect().width ?? 0) + PAGE_CARD_SIDE_PADDING),
            }}
            fluid
            className="mt-5 pb-5 d-flex flex-column"
        >
            {props.content}
        </Container>
    )
}
