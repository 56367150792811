import React, { useEffect } from 'react'
import { Form, Modal } from 'react-bootstrap'

interface FilePreviewModalProperties {
    show: boolean
    text: string
    title: string
    handleCloseFunction: () => void
}

function TextAreaModal(props: FilePreviewModalProperties) {
    const closeModal = () => {
        props.handleCloseFunction()
    }

    useEffect(() => {
        if (props.show) {
            console.log('show')
        }
    }, [props.show])

    return (
        <Modal show={props.show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            as="textarea"
                            readOnly
                            className={'remotive-font-sm'}
                            defaultValue={props.text}
                            rows={30}
                        />
                    </Form.Group>
                </Form>
                <Modal.Footer>
                    <p className={'remotive-font-sm'}>[Raw contents - max 10kb preview]</p>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}

export default TextAreaModal
