import Dropzone, {
    DropzoneRef,
    Accept,
    FileRejection,
    DropEvent,
} from 'react-dropzone'
import { useState, forwardRef } from 'react'
import { toast } from 'react-toastify'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { Project, RecordingSession } from 'src/api/CloudApi/types'
import {
    createAnalyticsTrackingKey,
    ProductAnalyticsProperties,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from 'src/utils/ProductAnalytics'
import { ComponentState } from 'src/types/ComponentState'
import CloudApi from 'src/api/CloudApi'
import { formattedToastMessage } from 'src/utils/toast'
import { CloudUploadIcon } from 'src/assets/Icons'
import InlineFileUploadProgressContainer from 'src/components/InlineFileUploadProgressContainer'
import { UserRecordingSessionContext } from 'src/types/Context'

interface MediaFileDropzoneProps {
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties

    hasPermissionToUpload?: boolean
    onUploadComplete?: Function
}

const ACCEPTED_MEDIA_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'image/webp': ['.webp'],
    'application/zip': ['.zip'],
    'application/x-ipynb+json': ['.ipynb'],
    'video/quicktime': ['.mov'],
    'video/mp4': ['.mp4'],
}

export default forwardRef(function MediaFileDropzone(
    props: MediaFileDropzoneProps,
    refFromParent: React.Ref<DropzoneRef>
) {
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DEFAULT)
    const [uploadPercent, setUploadPercent] = useState<number>(0)
    const [isHoveringDropzone, setIssHoveringDropzone] = useState<boolean>(false)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.productAnalyticsProperties?.currentUser,
        billableUnit: props.productAnalyticsProperties?.currentBillableUnit,
    } as ProductAnalyticsProps)

    const AnalyticsActions = {
        UPLOAD_MEDIA_FILE: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'UploadMediaFiles'
        ),
        UPLOAD_MEDIA_FILE_FAILED_UNSUPPORTED: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'UploadMediaFilesFailedUnsupportedFileType'
        ),
        UPLOAD_MEDIA_FILE_FAILED_UNKNOWN: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'UploadMediaFilesFailedUnknownError'
        ),
    }

    const uploadMediaFile = async (file: File, project: Project, recordingSession: RecordingSession) => {
        const filesWithSameName = recordingSession.mediaFiles.filter((mediaFile) => mediaFile.fileName === file.name)
        if (filesWithSameName && filesWithSameName.length > 0) {
            toast.warning(`File ${file.name} already exists`)
            return
        }

        const config = {
            onUploadProgress: function (progressEvent: AxiosProgressEvent) {
                console.log(progressEvent)
                setUploadPercent(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1)))
            },
        } as AxiosRequestConfig<EventTarget>

        try {
            setComponentState(ComponentState.UPLOADING)
            await CloudApi.uploadRecordingSessionMediaFile(project.uid, recordingSession.sessionId, file, config)
            toast.success(`Successfully uploaded media file ${file.name}`)
            if (props.onUploadComplete !== undefined) {
                props.onUploadComplete()
            }
            setUploadPercent(0)
        } catch (err) {
            console.error(err)
            toast.error('Failed to upload media file')
        }
        setComponentState(ComponentState.DEFAULT)
    }

    const clickableUploadFileArea = () => {
        return (
            <div className="d-flex p-1 justify-content-center align-items-center h-100">
                <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                    <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} />{' '}
                    {isHoveringDropzone ? (
                        <>
                            Drop the media file to <b>upload it!</b>
                        </>
                    ) : (
                        <>
                            Drag a file here or click to <b>upload a media file</b>
                        </>
                    )}
                </p>
            </div>
        )
    }

    const onMediaFileDrop = <T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent,
        project: Project,
        recordingSession: RecordingSession
    ): void => {
        if (acceptedFiles[0] !== undefined) {
            productAnalyticsClient.track(AnalyticsActions.UPLOAD_MEDIA_FILE)
            uploadMediaFile(acceptedFiles[0], project, recordingSession)
        } else if (fileRejections[0] !== undefined) {
            productAnalyticsClient.track(AnalyticsActions.UPLOAD_MEDIA_FILE_FAILED_UNSUPPORTED)
            toast.error(
                formattedToastMessage(
                    'Unsupported format',
                    `Failed to upload ${fileRejections[0].file.name}, that file format is currently not supported.`
                )
            )
        } else {
            productAnalyticsClient.track(AnalyticsActions.UPLOAD_MEDIA_FILE_FAILED_UNKNOWN)
            toast.error(formattedToastMessage('Upload failed', `Failed to upload file due to an unknown error.`))
        }

        setIssHoveringDropzone(false)
    }

    const mediaFileDropzone = (project: Project, recordingSession: RecordingSession) => {
        const isUploading = componentState === ComponentState.UPLOADING
        return (
            <>
                <Dropzone
                    multiple={false}
                    accept={ACCEPTED_MEDIA_FILE_TYPES as Accept}
                    ref={refFromParent}
                    disabled={isUploading}
                    onDragEnter={() => {
                        setIssHoveringDropzone(true)
                    }}
                    onDragLeave={() => {
                        setIssHoveringDropzone(false)
                    }}
                    onDrop={(acceptedFiles, fileRejections, event) =>
                        onMediaFileDrop(acceptedFiles, fileRejections, event, project, recordingSession)
                    }
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            className={`dropzone rounded-2 ${
                                isHoveringDropzone ? 'remotive-primary-10-background' : 'remotive-primary-0-background'
                            }`}
                            style={{
                                height: '94px',
                            }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <div className="w-100">
                                {isUploading ? (
                                    <InlineFileUploadProgressContainer
                                        inProgressText="Uploading media file..."
                                        finishedText="Upload complete, finishing up..."
                                        currentPercent={uploadPercent}
                                    />
                                ) : (
                                    clickableUploadFileArea()
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
            </>
        )
    }

    const component = () => {
        if (!props.hasPermissionToUpload) {
            return <></>
        }
        return mediaFileDropzone(
            props.userRecordingSessionContext.currentProject,
            props.userRecordingSessionContext.currentRecordingSession
        )
    }

    return component()
})
