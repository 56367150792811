import { useState } from 'react'
import { useEffect } from 'react'
import { Card, Container, Spinner } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'

import CloudApi from '../api/CloudApi'
import { AuthenticatedUser, Project, UserBillableUnitInfo, UserInfoWithRoles } from '../api/CloudApi/types'
import {
    AddIcon,
    ArrowEastIcon,
    ArrowNorthIcon,
    DeleteIcon,
    EditIcon,
    RefreshIcon,
    ShowIcon,
    UsersIcon,
} from '../assets/Icons'
import CreateBuUserModal from '../components/modals/CreateBuUserModal'
import EditBuUserModalModal from '../components/modals/EditBuUserModal'
import ErrorContainer from '../components/ErrorContainer'
import HelpOverlayModalModal from '../components/modals/HelpOverlayModal'
import LoadingContainer from '../components/LoadingContainer'
import NavigationBar from '../components/navigation/NavigationBar'
import { hasPermission, Permission } from '../utils/permission'
import { OrganisationRoutes } from '../Routes'
import { BreadCrumb, BreadCrumbs } from '../types/BreadCrumbs'
import { PageDetails } from '../utils/pageDetails'
import { ConfirmDialogProperties } from '../types/ConfirmDialogProperties'

interface UserListProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    currentUser: AuthenticatedUser | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

enum PageState {
    LOADING,
    DONE,
    ERROR,
    DELETING,
    DELETE_ERROR,
}

export default function UserList(props: UserListProps) {
    const [buUsers, setBuUsers] = useState<Array<UserInfoWithRoles>>([])
    const [pageState, setPageState] = useState(PageState.LOADING)
    const [showEditUserModal, setShowEditUserModal] = useState(false)
    const [isEditModalReadOnly, setIsEditModalReadOnly] = useState<boolean>(false)
    const [showCreateUserModal, setShowCreateUserModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState<UserInfoWithRoles>()
    const [showHelpModal, setShowHelpModal] = useState(false)

    const HIGHLIGHT_HELP_OBJECT_STYLE = showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}

    useEffect(() => {
        if (props.currentBillableUnit?.organisation.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Users - ${props.currentBillableUnit?.organisation.displayName}`,
                productAnalyticsEventName: 'Organisation Users',
            })
        }
    }, [props.currentBillableUnit])

    useEffect(() => {
        console.debug('Mounted organisation user list page!')
    }, [])

    useEffect(() => {
        if (props.currentBillableUnit) {
            setUserState()
        }
    }, [props.currentBillableUnit])

    const setUserState = async () => {
        const buUid = props.currentBillableUnit?.organisation.uid
        if (buUid) {
            setPageState(PageState.LOADING)
            try {
                const buUsers = (await CloudApi.listBuUsers(buUid)).data
                setBuUsers(buUsers)
                setPageState(PageState.DONE)
            } catch (err) {
                setPageState(PageState.ERROR)
            }
        }
    }

    const deleteUser = async (user: UserInfoWithRoles) => {
        if (props.currentUser?.uid === user.user.uid) {
            toast.warning('You cannot delete yourself from an organisation')
            return
        }

        const buOrg = props.currentBillableUnit?.organisation
        if (buOrg) {
            setPageState(PageState.LOADING)
            try {
                await CloudApi.deleteBuUser(user, buOrg)
            } catch (err: any) {
                toast.error('Failed to delete user')
            }
            setUserState()
        }
    }

    const editUser = (user: UserInfoWithRoles, isReadOnly: boolean = false) => {
        setSelectedUser(user)
        setIsEditModalReadOnly(isReadOnly)
        setShowEditUserModal(true)
    }

    const helpTextAddUser = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginLeft: -240 }}
                >
                    <div className="d-flex">
                        <p className="text-light text-start m-0">
                            Click to add a user to the <br />
                            current organisation
                        </p>
                        <ArrowEastIcon sx={{ fontSize: 30 }} className="ms-3" />
                    </div>
                </div>
            </>
        )
    }

    const helpTextEditUser = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex align-items-end flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: 60, marginLeft: -110 }}
                >
                    <div className="d-flex flex-column align-items-end">
                        <ArrowNorthIcon sx={{ fontSize: 30 }} className="mb-3" />
                        <p className="text-light text-start m-0">
                            Use these buttons to <br />
                            edit or delete a user
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const viewUserRolesButton = (buUser: UserInfoWithRoles) => {
        return (
            <button className={'btn remotive-btn-no-bg p-1 px-0 border-0'} onClick={() => editUser(buUser, true)}>
                <ShowIcon sx={{ fontSize: 20 }} />
            </button>
        )
    }

    const editUserRolesButton = (buUser: UserInfoWithRoles) => {
        return (
            <button
                className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                disabled={
                    !hasPermission(Permission.ORG_EDITOR_USER, props.currentBillableUnit, props.currentProject) ||
                    buUser.user.uid === props.currentUser?.uid
                }
                onClick={() => editUser(buUser)}
            >
                <EditIcon sx={{ fontSize: 20 }} />
            </button>
        )
    }

    const userList = () => {
        return buUsers
            .sort((user) => {
                const isCurrentUser = user.user.uid === props.currentUser?.uid
                return isCurrentUser ? -1 : 0 // Keep sorting from backend but put the current user on top
            })
            .map((buUser, index) => {
                const isCurrentUser = buUser.user.uid === props.currentUser?.uid
                return (
                    <div
                        key={buUser.user.uid}
                        className={`rounded remotive-primary-${isCurrentUser ? '20' : '10'}-background m-1 mx-0 px-1`}
                        style={index === 0 ? HIGHLIGHT_HELP_OBJECT_STYLE : {}}
                    >
                        <div className="d-flex mb-1">
                            <div className="col-5 col-md-6 p-1 border-end">
                                <p className="m-0 remotive-font-md text-truncate">{buUser.user.displayName}</p>
                                <p className="m-0 remotive-font-sm text-secondary text-truncate">{`${
                                    buUser.roles.length
                                } ${buUser.roles.length > 1 ? 'roles' : 'role'}`}</p>
                            </div>
                            <div className="col-5 p-1 px-2 text-start">
                                <p className="m-0 remotive-font-md text-truncate">Email</p>
                                <p className="remotive-font-sm text-secondary mb-0 text-truncate">
                                    {buUser.user.email || '-'}
                                </p>
                            </div>
                            <div className="col-2 col-md-1 px-1 d-flex justify-content-center align-center">
                                {index === 0 ? helpTextEditUser() : <></>}
                                {isCurrentUser ? viewUserRolesButton(buUser) : editUserRolesButton(buUser)}
                                <button
                                    className={'btn remotive-btn-no-bg p-1 px-0 border-0 ms-2'}
                                    disabled={
                                        isCurrentUser ||
                                        !hasPermission(
                                            Permission.ORG_EDITOR_USER,
                                            props.currentBillableUnit,
                                            props.currentProject
                                        )
                                    }
                                    onClick={() =>
                                        props.openConfirmationDialog({
                                            dialogTitle: 'Are you sure?',
                                            bodyText: (
                                                <>
                                                    Are you sure you want to delete the user{' '}
                                                    <b>{buUser.user.displayName}?</b>
                                                </>
                                            ),
                                            bodySubtitle: 'A user can be invited to an organistaion again.',
                                            confirmButtonText: 'Yes, delete',
                                            cancelButtonText: 'No, cancel',
                                            handleCancelFunction: () => console.log,
                                            handleConfirmFunction: () => deleteUser(buUser),
                                        } as ConfirmDialogProperties)
                                    }
                                >
                                    <DeleteIcon sx={{ fontSize: 20 }} />
                                </button>
                            </div>
                        </div>
                    </div>
                )
            })
    }

    const pageBody = () => {
        switch (pageState) {
            case PageState.LOADING:
                return <LoadingContainer spinnerSize="sm" />

            case PageState.ERROR:
                return <ErrorContainer errorText="We couldn't fetch users..." />

            case PageState.DELETE_ERROR:
                return <ErrorContainer errorText="We couldn't delete the user..." />

            case PageState.DONE:
                return <>{userList()}</>

            case PageState.DELETING:
                return (
                    <>
                        <div className="text-center h-100 mt-5 mb-5">
                            <p className={`fs-5 m-1`}>Deleting user...</p>
                            <Spinner className={`remotive-primary-50-color`} size="sm" />
                        </div>
                    </>
                )

            default:
                return <LoadingContainer spinnerSize="sm" />
        }
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <UsersIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 mb-0 lexend-light">Users</p>
                    </div>
                    <div>
                        {helpTextAddUser()}
                        <button
                            style={HIGHLIGHT_HELP_OBJECT_STYLE}
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1"
                            disabled={
                                !hasPermission(
                                    Permission.ORG_EDITOR_USER,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => setShowCreateUserModal(true)}
                        >
                            <AddIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        <button
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                            onClick={() => setUserState()}
                        >
                            <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
            ],
            current: {
                title: 'Users',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                isDemoGuideActive={false}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <Card className="shadow-sm rounded-4 border-0 mb-4" style={{ minHeight: '400px' }}>
                        <Card.Body className="pb-3 pt-3 text-start">{pageBody()}</Card.Body>
                    </Card>
                </div>
            </Container>

            {/* Modals */}
            <CreateBuUserModal
                currentBu={props.currentBillableUnit}
                show={showCreateUserModal}
                handleCloseFunction={() => setShowCreateUserModal(false)}
                reloadUsersList={() => setUserState()}
            />
            <EditBuUserModalModal
                currentBu={props.currentBillableUnit}
                selectedUser={selectedUser}
                show={showEditUserModal}
                isReadOnly={isEditModalReadOnly}
                handleCloseFunction={() => setShowEditUserModal(false)}
                reloadUsersList={() => setUserState()}
            />
            <HelpOverlayModalModal show={showHelpModal} handleCloseFunction={() => setShowHelpModal(false)} />
        </div>
    )
}
