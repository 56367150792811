import Dropzone, {
    DropzoneRef,
    DropzoneRootProps,
    DropzoneInputProps,
    Accept,
    FileRejection,
    DropEvent,
    FileWithPath,
} from 'react-dropzone'
import { useState, forwardRef } from 'react'
import { toast } from 'react-toastify'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { Project, RecordingSession } from 'src/api/CloudApi/types'
import {
    createAnalyticsTrackingKey,
    ProductAnalyticsContext,
    ProductAnalyticsProperties,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from 'src/utils/ProductAnalytics'
import { ComponentState } from 'src/types/ComponentState'
import { ALLOWED_RECORDING_FILE_NAME_ENDINGS } from 'src/utils/files'
import CloudApi from 'src/api/CloudApi'
import { formattedToastMessage } from 'src/utils/toast'
import { CloudUploadIcon } from 'src/assets/Icons'
import InlineFileUploadProgressContainer from 'src/components/InlineFileUploadProgressContainer'
import { UserRecordingSessionContext } from 'src/types/Context'
import { hasPermission, Permission } from 'src/utils/permission'

interface TransformationFileDropzoneProps {
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties

    hasPermissionToUpload: boolean

    isUploading: boolean
    uploadPercent: number

    transformationFilesPreparedForUpload: Array<FileWithPath>
    setTranformationFilesPreparedForUpload: (files: Array<FileWithPath>) => void
}

export default forwardRef(function TransformationFileDropzone(
    props: TransformationFileDropzoneProps,
    refFromParent: React.Ref<DropzoneRef>
) {
    const [isHoveringDropzone, setIssHoveringDropzone] = useState<boolean>(false)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.productAnalyticsProperties?.currentUser,
        billableUnit: props.productAnalyticsProperties?.currentBillableUnit,
    } as ProductAnalyticsProps)

    const AnalyticsActions = {
        UPLOAD_CONFIGURATION: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'PrepareToUploadConfiguration'
        ),
        UPLOAD_CONFIGURATION_UNKNOWN_ERROR: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'PrepareToUploadConfigurationFailedUnknownError'
        ),
    }

    const clickableUploadFileArea = () => {
        return (
            <div className="d-flex p-1 justify-content-center align-items-center h-100">
                <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                    <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} />{' '}
                    {isHoveringDropzone ? (
                        <>
                            Drop the transformations folder to <b>upload it!</b>
                        </>
                    ) : (
                        <>
                            Drag a folder here or click to <b>upload a transformations folder</b>
                        </>
                    )}
                </p>
            </div>
        )
    }

    const onFileDrop = <T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent,
        project: Project,
        recordingSession: RecordingSession
    ): void => {
        if (acceptedFiles.length > 0) {
            productAnalyticsClient.track(AnalyticsActions.UPLOAD_CONFIGURATION)
            acceptedFiles.forEach((f) => console.log((f as FileWithPath).path))
            props.setTranformationFilesPreparedForUpload(acceptedFiles)
        } else {
            productAnalyticsClient.track(AnalyticsActions.UPLOAD_CONFIGURATION_UNKNOWN_ERROR)
            toast.error(formattedToastMessage('Upload failed', `Failed to upload folder due to an unknown error.`))
        }

        setIssHoveringDropzone(false)
    }

    const transformationsFolderDropzone = (project: Project, recordingSession: RecordingSession) => {
        return (
            <>
                <Dropzone
                    useFsAccessApi={false}
                    multiple={true}
                    ref={refFromParent}
                    disabled={props.isUploading}
                    onDragEnter={() => {
                        setIssHoveringDropzone(true)
                    }}
                    onDragLeave={() => {
                        setIssHoveringDropzone(false)
                    }}
                    onDrop={(acceptedFiles, fileRejections, event) =>
                        onFileDrop(acceptedFiles, fileRejections, event, project, recordingSession)
                    }
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            className={`dropzone rounded-2 ${
                                isHoveringDropzone ? 'remotive-primary-10-background' : 'remotive-primary-0-background'
                            }`}
                            style={{
                                height: '94px',
                            }}
                            {...getRootProps()}
                        >
                            <input
                                {...getInputProps({
                                    // Cast to bypass TypeScript error
                                    ...(getInputProps() as any),
                                    webkitdirectory: 'true',
                                    directory: 'true',
                                })}
                            />
                            <div className="w-100">
                                {props.isUploading ? (
                                    <InlineFileUploadProgressContainer
                                        inProgressText="Uploading transformations folder..."
                                        finishedText="Upload complete, finishing up..."
                                        currentPercent={props.uploadPercent}
                                    />
                                ) : (
                                    clickableUploadFileArea()
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
            </>
        )
    }

    const component = () => {
        if (props.hasPermissionToUpload) {
            return transformationsFolderDropzone(
                props.userRecordingSessionContext.currentProject,
                props.userRecordingSessionContext.currentRecordingSession
            )
        }
        return <></>
    }

    return component()
})
