import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import CloudApi from 'src/api/CloudApi'
import { BrokerConfigurationFolder } from 'src/api/CloudApi/types'
import { DeleteIcon, DownloadIcon } from 'src/assets/Icons'
import { ConfirmDialogProperties } from 'src/types/ConfirmDialogProperties'
import { UserRecordingSessionContext } from 'src/types/Context'
import { hasPermission, Permission } from 'src/utils/permission'
import {
    createAnalyticsTrackingKey,
    ProductAnalyticsProperties,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from 'src/utils/ProductAnalytics'
import { formattedToastMessage } from 'src/utils/toast'

interface TransformationFileListItemProps {
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties
    transformationFolder: BrokerConfigurationFolder

    openConfirmationDialog: Function
    refreshRecordingSession: Function

    recordingRequiresFurtherConfiguration: boolean
}

export function TransformationFileListItem(props: TransformationFileListItemProps) {
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.productAnalyticsProperties.currentUser,
        billableUnit: props.productAnalyticsProperties.currentBillableUnit,
    } as ProductAnalyticsProps)

    const AnalyticsActions = {
        DOWNLOAD_CONFIGURATION: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'DownloadConfiguration'
        ),
        DELETE_CONFIGURATION: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'DeleteConfiguration'
        ),
    }

    const deleteTransformationFolder = async (folder: BrokerConfigurationFolder, skipToast: boolean = false) => {
        try {
            setIsDeleting(true)
            await CloudApi.deleteBrokerConfigFolder(
                props.userRecordingSessionContext.currentProject.uid,
                props.userRecordingSessionContext.currentRecordingSession.sessionId,
                folder
            )
            await props.refreshRecordingSession()
            toast.success(formattedToastMessage('Sucess', `Successfully deleted signal transformation ${folder.name}`))
        } catch (err: any) {
            toast.error(formattedToastMessage('Error', 'Failed to delete signal transformation.'))
        }
        setIsDeleting(false)
    }

    const downloadBrokerConfigAsZip = async (folder: BrokerConfigurationFolder) => {
        setIsLoadingDownload(true)
        const response = await CloudApi.downloadBrokerConfiguration(
            props.userRecordingSessionContext.currentProject.uid,
            props.userRecordingSessionContext.currentRecordingSession.sessionId,
            folder
        )
        var link = document.createElement('a')
        link.download = `${folder.name}.zip`
        var blob = new Blob([response.data], { type: 'application/zip' })
        link.href = window.URL.createObjectURL(blob)
        link.click()
        setIsLoadingDownload(false)
    }

    const component = () => {
        const isDefaultConfigAndIsNotProperlyConfigured =
            props.transformationFolder.name === 'default' && props.recordingRequiresFurtherConfiguration === true
        return (
            <>
                <div className={`d-flex rounded remotive-primary-10-background m-1`}>
                    <div className="col-10 col-md-11 px-2 pt-1 pb-1">
                        <div className="h-100 border-end">
                            <p className="m-0 remotive-font-md text-truncate">{props.transformationFolder.name}</p>
                            {props.transformationFolder.name !== 'default' && (
                                <p className="m-0 text-secondary remotive-font-sm text-truncate">{`${props.transformationFolder.files.length} file(s)`}</p>
                            )}
                            {props.transformationFolder.name === 'default' && (
                                <p className="m-0 text-secondary remotive-font-sm text-truncate">
                                    This will be autogenerated from recording
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="col-2 col-md-1 d-flex justify-content-center align-items-center remotive-primary-60-color">
                        <button
                            className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_VIEWER_RECORDING,
                                    props.userRecordingSessionContext.currentBillableUnit,
                                    props.userRecordingSessionContext.currentProject
                                ) || isDefaultConfigAndIsNotProperlyConfigured
                            }
                            onClick={() => {
                                productAnalyticsClient.track(AnalyticsActions.DOWNLOAD_CONFIGURATION)
                                downloadBrokerConfigAsZip(props.transformationFolder)
                            }}
                        >
                            {isLoadingDownload ? (
                                <>
                                    <Spinner size="sm" style={{ height: 13, width: 13, marginRight: 4, marginLeft: 3 }} />
                                </>
                            ) : (
                                <>
                                    <DownloadIcon sx={{ fontSize: 20 }} />
                                </>
                            )}
                        </button>
                        <button
                            className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.userRecordingSessionContext.currentBillableUnit,
                                    props.userRecordingSessionContext.currentProject
                                ) || props.transformationFolder.name === 'default'
                            }
                            onClick={() =>
                                props.openConfirmationDialog({
                                    dialogTitle: 'Are you sure?',
                                    bodyText: (
                                        <>
                                            Are you sure you want to delete the signal transformation{' '}
                                            <b>{props.transformationFolder.name}?</b>
                                        </>
                                    ),
                                    bodySubtitle: 'You can not undo this action.',
                                    confirmButtonText: 'Yes, delete it',
                                    cancelButtonText: 'No, cancel',
                                    handleCancelFunction: () => console.log,
                                    handleConfirmFunction: () => {
                                        productAnalyticsClient.track(AnalyticsActions.DELETE_CONFIGURATION)
                                        deleteTransformationFolder(props.transformationFolder)
                                    },
                                } as ConfirmDialogProperties)
                            }
                        >
                            {isDeleting ? (
                                <>
                                    <Spinner size="sm" style={{ height: 13, width: 13, marginRight: 4, marginLeft: 3 }} />
                                </>
                            ) : (
                                <>
                                    <DeleteIcon sx={{ fontSize: 20 }} />
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </>
        )
    }

    return component()
}
