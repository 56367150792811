import { ProgressBar, Spinner } from 'react-bootstrap'
import { SuccessIcon } from '../assets/Icons'

interface InlineFileUploadProgressContainerProps {
    inProgressText: string
    finishedText: string
    currentPercent: number
}

export default function InlineFileUploadProgressContainer(props: InlineFileUploadProgressContainerProps) {
    const fileUploadProgressContainer = () => {
        return (
            <div className="d-flex flex-column justify-content-center h-100 w-100">
                {props.currentPercent < 100 && (
                    <>
                        <div className="d-flex align-items-center justify-content-center mb-2">
                            <Spinner size="sm" className={`remotive-primary-50-color`} />
                            <p className={`remotive-font-md m-0 ms-2`}>{props.inProgressText}</p>
                        </div>
                        <div className="d-flex justify-content-center  align-items-center">
                            <ProgressBar
                                className={`col-12 col-lg-8 remotive-primary-0-color`}
                                label={`${props.currentPercent}%`}
                                now={props.currentPercent}
                            />
                        </div>
                    </>
                )}
                {props.currentPercent >= 100 && (
                    <>
                        <div className='d-flex align-items-center justify-content-center'>
                            <SuccessIcon sx={{ fontSize: 30 }} className=" remotive-success-60-color" />
                            <p className={`remotive-font-md m-0 ms-2`}>{props.finishedText}</p>
                        </div>
                    </>
                )}
            </div>
        )
    }

    return fileUploadProgressContainer()
}
