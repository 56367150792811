export enum ComponentState {
    LOADING,
    LOADING_VIDEO,
    DONE,
    DEFAULT,
    ERROR,
    UPLOADING,
    DELETING,
    SAVING,
    CRUNCHING,
    PROCESSING_REQUIRED,
    NOT_ENABLED,
    QUEUED,
}
