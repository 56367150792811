import { useState } from 'react'
import { RecordingFile, SignalDatabase } from 'src/api/CloudApi/types'
import RecordingFileListItem from 'src/components/RecordingFileListItem'
import { ConfirmDialogProperties } from 'src/types/ConfirmDialogProperties'
import { UserRecordingSessionContext } from 'src/types/Context'
import EditRecordingConfigurationModal from './EditRecordingConfigurationModal'

interface RecordingFilesSectionProps {
    userRecordingSessionContext: UserRecordingSessionContext
    availableSignalDatabases: Array<SignalDatabase>
    allNamespaces: Array<string>

    refreshRecordingSession: Function
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export default function RecordingFilesSection(props: RecordingFilesSectionProps) {
    const [showEditRecordingConfigurationModal, setShowEditRecordingConfigurationModal] = useState<boolean>(true)
    const [recordingFileToEdit, setRecordingFileToEdit] = useState<RecordingFile>()

    const editRecording = (recordingFile: RecordingFile) => {
        setRecordingFileToEdit(recordingFile)
        setShowEditRecordingConfigurationModal(true)
    }

    const recordingFileList = () => {
        return props.userRecordingSessionContext.currentRecordingSession.recordings.map((recordingFile) => {
            return (
                <div key={recordingFile.fileName}>
                    <RecordingFileListItem
                        allNamespaces={props.allNamespaces}
                        availableSignalDatabases={props.availableSignalDatabases}
                        currentUser={props.userRecordingSessionContext.currentUser}
                        refreshRecordingSession={props.refreshRecordingSession}
                        parentRecordingSession={props.userRecordingSessionContext.currentRecordingSession}
                        recordingFile={recordingFile}
                        editRecordingFunction={(recordingFile: RecordingFile) => editRecording(recordingFile)}
                        project={props.userRecordingSessionContext.currentProject!}
                        billableUnit={props.userRecordingSessionContext.currentBillableUnit!}
                        openConfirmationDialog={props.openConfirmationDialog}
                    />
                </div>
            )
        })
    }

    const component = () => {
        return (
            <>
                <>
                    <div className="d-flex flex-row flex-truncate">
                        <p className="fs-6 mb-0 me-3">
                            <b>Recording files</b>
                        </p>
                    </div>
                    <p className="mb-0 remotive-font-sm text-secondary">{`These are all the recording files from this recording session. Together with a signal database you can play these files on a RemotiveBroker.`}</p>
                    <>{recordingFileList()}</>
                </>
                <EditRecordingConfigurationModal
                    refreshRecordingSession={props.refreshRecordingSession}
                    availableSignalDatabases={props.availableSignalDatabases}
                    allNamespaces={props.allNamespaces}
                    currentProject={props.userRecordingSessionContext.currentProject}
                    currentRecordingSession={props.userRecordingSessionContext.currentRecordingSession}
                    currentRecordingFile={recordingFileToEdit}
                    show={showEditRecordingConfigurationModal}
                    handleCloseFunction={() => setShowEditRecordingConfigurationModal(false)}
                />
            </>
        )
    }

    return component()
}
