import React, { Fragment, useState } from 'react'
import { formatNumberWithMetricPrefix } from '../../../../utils/formatNumberWithMetricPrefix'
import {
    AuthenticatedUser,
    Project,
    RecordingAnnotation,
    RecordingAnnotationComment,
    RecordingAnnotationCommentRequest,
    RecordingSession,
} from '../../../../api/CloudApi/types'
import { CrossIcon, DeleteIcon, EditIcon, MoreVerticalIcon, SaveIcon } from '../../../../assets/Icons'
import { formatAsDateTimeWithMonthAsWord } from '../../../../utils/datetime'
import { Button, Dropdown, Form, Spinner } from 'react-bootstrap'
import CloudApi from '../../../../api/CloudApi'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../../utils/toast'

type AnnotationCommentProps = {
    refreshAnnotation: Function
    currentUser: AuthenticatedUser | undefined
    currentProject: Project | undefined
    currentRecordingSession: RecordingSession | undefined
    currentAnnotation: RecordingAnnotation
    comment: RecordingAnnotationComment
    maxWidth?: number | undefined
}
// ✅ https://google.com | ✅ http://google.com | ✅ www.google.com | ❌ google.com
const URL_REGEX =
    /((https?:\/\/)|(www\.))[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,63}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi

function isBlankOrEmptyNewLines(input: string): boolean {
    return /^\s*$/.test(input)
}

export default function AnnotationComment(props: AnnotationCommentProps) {
    const [isEditModeActive, setIsEditModeActive] = useState(false)
    const [editedComment, setEditedComment] = useState(props.comment.text)

    const [showCommentOptionsDropdown, setShowCommentOptionsDropdown] = useState(false)
    const [isRequestBeingSent, setIsRequestBeingSent] = useState(false)

    const editComment = () => {
        setIsEditModeActive(true)
    }

    const cancelEditComment = () => {
        setIsEditModeActive(false)
        setEditedComment(props.comment.text)
    }

    const submitEditedComment = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession,
        currentAnnotation: RecordingAnnotation,
        comment: RecordingAnnotationComment,
        editedCommentText: string
    ) => {
        try {
            setIsRequestBeingSent(true)
            const request = { text: editedCommentText } as RecordingAnnotationCommentRequest
            const response = await CloudApi.editRecordingAnnotationComment(
                currentProject,
                currentRecordingSession,
                currentAnnotation,
                props.comment,
                request
            )
            if (response.status >= 200 && response.status < 300) {
                await props.refreshAnnotation()
                setIsEditModeActive(false)
            }
        } catch (err: any) {
            console.error(err)
            toast.error(formattedToastMessage('Error', 'Failed to save the edited comment, please try again later.'))
        }
        setIsRequestBeingSent(false)
    }

    const deleteComment = async (
        currentProject: Project | undefined,
        currentRecordingSession: RecordingSession | undefined,
        currentAnnotation: RecordingAnnotation,
        comment: RecordingAnnotationComment
    ) => {
        setIsRequestBeingSent(true)
        try {
            await CloudApi.deleteRecordingAnnotationComment(
                currentProject!,
                currentRecordingSession!,
                currentAnnotation,
                comment
            )
            await props.refreshAnnotation()
        } catch (e: any) {
            console.error(e)
            toast.error(formattedToastMessage('Error', 'Failed to delete comment, please try again later.'))
        }
        setIsRequestBeingSent(false)
    }

    const commentOptionsDropdown = (
        currentProject: Project | undefined,
        currentRecordingSession: RecordingSession | undefined,
        currentAnnotation: RecordingAnnotation,
        comment: RecordingAnnotationComment
    ) => {
        return (
            <>
                <Dropdown style={{ marginLeft: -85 }} className="" show={showCommentOptionsDropdown}>
                    <Dropdown.Menu className="py-1 d-flex flex-column align-items-start remotive-dropdown-light border-0 shadow">
                        <Dropdown.Item
                            as={Button}
                            disabled={comment.author.uid !== props.currentUser?.uid}
                            className="m-0 py-1 px-2 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                deleteComment(currentProject, currentRecordingSession, currentAnnotation, comment)
                            }}
                        >
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                <p className="me-3 m-0 remotive-font-sm">Delete</p>
                                <DeleteIcon sx={{ fontSize: 14 }} style={{ marginBottom: 1 }} />
                            </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                            as={Button}
                            className="m-0 py-1 px-2 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                editComment()
                            }}
                        >
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                <p className="me-3 m-0 remotive-font-sm">Edit</p>
                                <EditIcon sx={{ fontSize: 14 }} />
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    const makeLinksClickable = (text: string) => {
        return text.split(' ').map((it, index) => {
            if (it.match(URL_REGEX)) {
                const href = it.startsWith('http') ? it : `https://${it}`
                console.log(href)
                return (
                    <span key={index}>
                        <a href={href} target="_blank" rel="noopener noreferrer">
                            {it}
                        </a>{' '}
                    </span>
                )
            }
            return <span key={index}>{`${it} `}</span>
        })
    }

    const annotationComment = (
        currentUser: AuthenticatedUser | undefined,
        currentProject: Project | undefined,
        currentRecordingSession: RecordingSession | undefined,
        currentAnnotation: RecordingAnnotation,
        comment: RecordingAnnotationComment
    ) => {
        {
            const isCurrentUsersComment = currentUser?.uid === comment.author.uid
            return (
                <div className={`px-2 py-1 d-flex justify-content-${isCurrentUsersComment ? 'end' : 'start'}`}>
                    <div
                        className={`rounded-3 ${
                            isCurrentUsersComment ? 'remotive-success-10-background' : 'remotive-primary-10-background'
                        } mb-1 p-1`}
                        style={{ width: props.maxWidth ? props.maxWidth : 250, minHeight: 35 }}
                    >
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="d-flex flex-row p-2 pb-0 align-items-center">
                                    {/*<ProfileIcon sx={{ fontSize: 13 }} className='me-1'/>*/}
                                    <p className="mb-0 remotive-font-xs lexend-bold">{comment.author.displayName}</p>
                                </div>
                                <div>
                                    {showCommentOptionsDropdown &&
                                        commentOptionsDropdown(
                                            currentProject,
                                            currentRecordingSession,
                                            currentAnnotation,
                                            comment
                                        )}
                                    {isCurrentUsersComment && (
                                        <button
                                            onClick={() => setShowCommentOptionsDropdown(!showCommentOptionsDropdown)}
                                            onBlur={() => setShowCommentOptionsDropdown(false)}
                                            className="btn remotive-btn-no-bg p-0 m-0"
                                        >
                                            {isRequestBeingSent ? (
                                                <Spinner
                                                    size="sm"
                                                    className="border-2"
                                                    style={{ height: 11, width: 11 }}
                                                />
                                            ) : (
                                                <MoreVerticalIcon sx={{ fontSize: 15 }} />
                                            )}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="p-2">
                                {isEditModeActive ? (
                                    <>
                                        <Form.Control
                                            className="remotive-font-sm text-wrap"
                                            placeholder="Add your comment here..."
                                            as={'textarea'}
                                            disabled={isRequestBeingSent}
                                            value={editedComment}
                                            onKeyDownCapture={(it) => {
                                                if (!isBlankOrEmptyNewLines(editedComment) && it.key === 'Enter') {
                                                    if (
                                                        props.currentProject !== undefined &&
                                                        props.currentRecordingSession !== undefined &&
                                                        props.currentAnnotation !== undefined
                                                    ) {
                                                        submitEditedComment(
                                                            props.currentProject,
                                                            props.currentRecordingSession,
                                                            props.currentAnnotation,
                                                            props.comment,
                                                            editedComment
                                                        )
                                                    } else
                                                        toast.error(
                                                            formattedToastMessage(
                                                                'Error',
                                                                'Could not submit the comment due to a parameter error. Please refresh the page and try again.'
                                                            )
                                                        )
                                                }
                                            }}
                                            onChange={(it: any) => {
                                                setEditedComment(it.target.value)
                                            }}
                                            style={{ resize: 'none' }}
                                        />
                                        <div className="d-flex justify-content-end">
                                            <button
                                                disabled={isRequestBeingSent}
                                                onClick={() => cancelEditComment()}
                                                className="btn remotive-btn-primary remotive-btn-sm mb-0"
                                            >
                                                <span className="d-none d-md-inline-block">Cancel</span>
                                                <span className="d-md-none">
                                                    <CrossIcon sx={{ fontSize: 15 }} />
                                                </span>
                                            </button>
                                            <button
                                                disabled={isRequestBeingSent || isBlankOrEmptyNewLines(editedComment)}
                                                onClick={() => {
                                                    if (!isBlankOrEmptyNewLines(editedComment)) {
                                                        if (
                                                            props.currentProject !== undefined &&
                                                            props.currentRecordingSession !== undefined &&
                                                            props.currentAnnotation !== undefined
                                                        ) {
                                                            submitEditedComment(
                                                                props.currentProject,
                                                                props.currentRecordingSession,
                                                                props.currentAnnotation,
                                                                props.comment,
                                                                editedComment
                                                            )
                                                        } else
                                                            toast.error(
                                                                formattedToastMessage(
                                                                    'Error',
                                                                    'Could not submit the comment due to a parameter error. Please refresh the page and try again.'
                                                                )
                                                            )
                                                    }
                                                }}
                                                className="btn remotive-btn-success remotive-btn-sm mb-0 d-flex align-items-center justify-content-center"
                                            >
                                                {isRequestBeingSent ? (
                                                    <Spinner size="sm" style={{ height: 10, width: 10 }} />
                                                ) : (
                                                    <>
                                                        <span className="d-none d-md-inline-block">Save</span>
                                                        <span className="d-md-none">
                                                            <SaveIcon sx={{ fontSize: 15 }} />
                                                        </span>
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <p className="mb-0 remotive-font-sm text-wrap text-break">
                                            {makeLinksClickable(comment.text)}
                                        </p>
                                    </>
                                )}
                            </div>
                            <div className="d-flex justify-content-end p-1">
                                <p className="mb-0 remotive-font-xs text-secondary">
                                    {formatAsDateTimeWithMonthAsWord(comment.created, false)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return annotationComment(
        props.currentUser,
        props.currentProject,
        props.currentRecordingSession,
        props.currentAnnotation,
        props.comment
    )
}
