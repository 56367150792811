import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Form, InputGroup, Spinner } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import { UserBillableUnitInfo, UserInfoWithRoles } from '../../api/CloudApi/types'
import { ErrorIcon } from '../../assets/Icons'
import { toast } from 'react-toastify'

interface EditBuUserModalProps {
    currentBu: UserBillableUnitInfo | undefined
    selectedUser: UserInfoWithRoles | undefined
    show: boolean
    isReadOnly: boolean
    handleCloseFunction: () => void
    reloadUsersList: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

enum Role {
    ADMINISTRATOR = 'org/administrator',
    BILLING_VIEWER = 'org/billingViewer', // This roles is not used at the moment
    LICENSE_VIEWER = 'org/licenseViewer',
    PROJECT_EDITOR = 'org/projectEditor',
    MEMBER = 'org/member',
}

enum RequestState {
    NOT_STARTED,
    LOADING,
    DONE,
    ERROR,
}

export default function EditBuUserModal(props: EditBuUserModalProps) {
    const [selectedRoles, setSelectedRoles] = useState<Array<Role>>(
        props.selectedUser?.roles.map((role) => role as Role) || []
    )
    const [requestState, setRequestState] = useState<RequestState>(RequestState.NOT_STARTED)

    useEffect(() => {
        console.debug('Mounted edit organisation user modal!')
    }, [])

    useEffect(() => {
        setSelectedRoles(props.selectedUser?.roles.map((role) => role as Role) || [])
    }, [props.show])

    useEffect(() => {
        if (requestState === RequestState.DONE) {
            closeModal()
            props.reloadUsersList()
        }
    }, [requestState])

    const validateInput = (): boolean => {
        if (props.selectedUser === undefined) {
            alert('You select a user to edit')
            return false
        }
        return true
    }

    const resetState = () => {
        setSelectedRoles([])
        setRequestState(RequestState.NOT_STARTED)
    }

    const editProjectUser = async () => {
        const buUid = props.currentBu?.organisation.uid
        if (validateInput() && props.selectedUser && selectedRoles && buUid) {
            setRequestState(RequestState.LOADING)
            const roles = selectedRoles.includes(Role.MEMBER) ? selectedRoles : [...selectedRoles, Role.MEMBER]
            const userInfo = { user: props.selectedUser.user, roles } as UserInfoWithRoles
            try {
                await CloudApi.editBuUser(buUid, userInfo)
                setRequestState(RequestState.DONE)
            } catch (err) {
                setRequestState(RequestState.ERROR)
                toast.error('Failed to save user')
            }
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    const toggleRole = (role: Role) => {
        if (selectedRoles.includes(role)) {
            setSelectedRoles([...selectedRoles.filter((storedRole) => storedRole !== role)])
        } else {
            setSelectedRoles([...selectedRoles, role])
        }
    }

    const rolesList = () => {
        return (
            <>
                <div>
                    <Form.Check
                        type="checkbox"
                        checked={selectedRoles.includes(Role.ADMINISTRATOR)}
                        className="remotive-font-md"
                        label="Administrator"
                        disabled={props.isReadOnly}
                        onChange={() => toggleRole(Role.ADMINISTRATOR)}
                        style={{ marginBottom: '-4px' }}
                    />
                    <p className="remotive-font-sm text-secondary mb-2">
                        Full access. Can manage users, licenses and projects for this organisation.
                    </p>
                </div>
                <div>
                    <Form.Check
                        type="checkbox"
                        className="remotive-font-md"
                        checked={selectedRoles.includes(Role.LICENSE_VIEWER)}
                        label="License Viewer"
                        disabled={props.isReadOnly}
                        onChange={() => toggleRole(Role.LICENSE_VIEWER)}
                        style={{ marginBottom: '-4px' }}
                    />
                    <p className="remotive-font-sm text-secondary mb-2">Can see licenses for this organisation</p>
                </div>
                <div>
                    <Form.Check
                        type="checkbox"
                        className="remotive-font-md"
                        checked={selectedRoles.includes(Role.PROJECT_EDITOR)}
                        label="Project Admin"
                        disabled={props.isReadOnly}
                        onChange={() => toggleRole(Role.PROJECT_EDITOR)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">
                        Can create projects. The user that creates a project will automatically have full access rights.
                        The access right to manage (edit / delete) projects can be restricted per project.
                    </p>
                </div>
                <div>
                    <Form.Check
                        type="checkbox"
                        className="remotive-font-md"
                        checked={true}
                        disabled
                        label="Member"
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">
                        All user can be invited to projects. To give more access rights, please choose additional roles.
                    </p>
                </div>
            </>
        )
    }

    const getModalContent = () => {
        const title = 'Edit organisation user'
        switch (requestState) {
            case RequestState.LOADING:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Editing user...</p>
                                <Spinner className="remotive-primary-50-color" size="sm" />
                            </div>
                        </Modal.Body>
                    </>
                )

            case RequestState.ERROR:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-3 mt-3 text-center">
                                <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                                <p className="fs-5 m-0">There was an issue editing the user...</p>
                                <p className="remotive-font-sm text-secondary" style={{ marginTop: '-5px' }}>
                                    Please try again later
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            case RequestState.NOT_STARTED:
            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        Name
                                    </InputGroup.Text>
                                    <Form.Control
                                        disabled
                                        className="rounded border-light-gray"
                                        value={props.selectedUser?.user.displayName || 'N/A'}
                                    />{' '}
                                </InputGroup>
                            </div>
                            <div className="mb-4">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        Email
                                    </InputGroup.Text>
                                    <Form.Control
                                        disabled
                                        className="rounded border-light-gray"
                                        value={props.selectedUser?.user.email || 'N/A'}
                                    />{' '}
                                </InputGroup>
                            </div>
                            <div className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        <p className="h-100">Role</p>
                                    </InputGroup.Text>
                                    <div className="w-75">{rolesList()}</div>
                                </InputGroup>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn remotive-btn remotive-btn-success align-center"
                                    disabled={props.isReadOnly}
                                    onClick={() => editProjectUser()}
                                >
                                    <div className="d-flex align-items-center text-light">
                                        <p className="text-light m-0">Save</p>
                                    </div>
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal bsPrefix="" show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
