import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import VideoPlayer from './VideoPlayer'
import { MediaFile } from '../api/CloudApi/types'

interface ViewFileModalProps {
    onVideoLoadedCallback: Function
    fileToView: MediaFile | undefined
    show: boolean
    handleCloseFunction: () => void
}

export default function ViewFileModal(props: ViewFileModalProps) {
    useEffect(() => {
        console.debug('Mounted view file modal!')
        return () => {
            console.debug('Unmounted view file modal!')
        }
    }, [])

    const closeModal = () => {
        props.handleCloseFunction()
    }

    return (
        <>
            <Modal
                className="m-0 p-0 bg-transparent border-0"
                contentClassName="bg-transparent border-0"
                backdropClassName="opacity-75"
                size="xl"
                centered
                show={props.show}
                onHide={() => closeModal()}
            >
                <Modal.Header className='border-0' closeButton closeVariant='white'/>
                <Modal.Body className="p-0 m-0 bg-transparent border-0 d-flex align-items-center justify-content-center">
                    <VideoPlayer
                        onVideoLoadedCallback={props.onVideoLoadedCallback}
                        videoSource={props.fileToView?.downloadUrl || 'Error'}
                        videoSourceType={'video/mp4'}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}
