import { useState } from 'react'
import { useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import CloudApi from '../../api/CloudApi'
import { AuthenticatedUser, License, Project, UserBillableUnitInfo, UserLicense } from '../../api/CloudApi/types'
import { ErrorIcon, LicenseIcon, ProfileIcon, RefreshIcon, SuccessIcon } from '../../assets/Icons'
import ErrorContainer from '../../components/ErrorContainer'
import LoadingContainer from '../../components/LoadingContainer'
import NavigationBar from '../../components/navigation/NavigationBar'
import NotFoundContaner from '../../components/NotFoundContainer'
import { LicenseCounter } from './LicenseCounter'
import { OrganisationRoutes } from '../../Routes'
import { BreadCrumb, BreadCrumbs } from '../../types/BreadCrumbs'
import { PageDetails } from '../../utils/pageDetails'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'

interface LicenseListProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentUser: AuthenticatedUser | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

enum PageState {
    LOADING,
    DONE,
    ERROR,
}

export default function LicenseList(props: LicenseListProps) {
    const [userLicenses, setUserLicenses] = useState<Array<UserLicense>>([])
    const [pageState, setPageState] = useState(PageState.LOADING)
    const [filter, setFilter] = useState('all')

    useEffect(() => {
        if (props.currentBillableUnit?.organisation.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Licenses - ${props.currentBillableUnit?.organisation.displayName}`,
                productAnalyticsEventName: 'Organisation Licenses',
            })
        }
    }, [props.currentBillableUnit])

    useEffect(() => {
        console.debug('Mounted organisation user list page!')
    }, [])

    useEffect(() => {
        if (props.currentBillableUnit) {
            setLicenseState()
        }
    }, [props.currentBillableUnit])

    useEffect(() => {
        setLicenseState()
    }, [filter])

    const setLicenseState = async () => {
        const buUid = props.currentBillableUnit?.organisation.uid
        if (buUid) {
            setPageState(PageState.LOADING)
            try {
                const licenses = (await CloudApi.listLicenses(buUid, filter)).data
                setUserLicenses(licenses)
                setPageState(PageState.DONE)
            } catch (err: any) {
                setPageState(PageState.ERROR)
            }
        }
    }

    const licenseList = (licenses: Array<License>) => {
        return licenses.map((license) => {
            return (
                <div key={license.hash} className="rounded remotive-primary-10-background row m-1">
                    <div className="col-6 px-2 pt-1 pb-1">
                        <div className="border-end">
                            <p className="m-0 remotive-font-md">License</p>
                            <p className="m-0 remotive-font-sm text-secondary text-truncate">
                                {license.hash === 'CI License' && 'CI License'}
                                {license.hash !== 'CI License' &&
                                    Array.from(license.hash).map((value, index) => {
                                        const isFirstCharacter = () => index === 0
                                        const isLastCharacter = () => index + 1 === license.hash.length
                                        if (!isFirstCharacter() && !isLastCharacter() && (index + 1) % 4 === 0) {
                                            return value.concat('-')
                                        }
                                        return value
                                    })}
                            </p>
                        </div>
                    </div>
                    <div className="col-2 px-2 pt-1 pb-1">
                        <div className="border-end text-truncate">
                            <p className="m-0 remotive-font-md text-truncate">Created</p>
                            <p className="m-0 remotive-font-sm text-secondary text-truncate">
                                {license.created.substring(0, 10)}
                            </p>
                        </div>
                    </div>
                    <div className="col-2 px-2 pt-1 pb-1">
                        <div className="border-end">
                            <p className="m-0 remotive-font-md text-truncate">Expires</p>
                            <div className="d-flex align-items-center text-truncate">
                                {Date.parse(license.expires) > Date.now() ? (
                                    <SuccessIcon className="remotive-success-50-color" sx={{ fontSize: 13 }} />
                                ) : (
                                    <ErrorIcon className="text-danger" sx={{ fontSize: 13 }} />
                                )}
                                <p className="m-0 remotive-font-sm text-secondary ms-1 text-truncate">
                                    {license.expires.substring(0, 10)}{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 px-2 pt-1 pb-1">
                        <p className="m-0 remotive-font-md text-truncate">Renewed</p>
                        <p className="m-0 remotive-font-sm text-secondary text-truncate">{license.renewCount} times</p>
                    </div>
                </div>
            )
        })
    }

    const userLicenseList = () => {
        if (userLicenses.length > 0) {
            return userLicenses.map((userLicense, index) => {
                return (
                    <div key={index} className="mt-1 mb-4">
                        <div className="">
                            <div className="pb-0">
                                <p className="m-0 remotive-font-md">
                                    <ProfileIcon sx={{ fontSize: 20 }} className="me-1" />
                                    {userLicense.creator}
                                </p>
                                <p className="m-0 remotive-font-sm text-secondary ms-4">{`${userLicense.licenses.length} licenses`}</p>
                            </div>
                            {userLicense.licenses[0].hash === 'CI License' && (
                                <div className="">{licenseList(userLicense.licenses.slice(0, 1))}</div>
                            )}
                            {userLicense.licenses[0].hash !== 'CI License' && (
                                <div className="">{licenseList(userLicense.licenses)}</div>
                            )}
                        </div>
                    </div>
                )
            })
        }
        return (
            <NotFoundContaner
                infoText="We couldn't find any licenses..."
                secondaryText="All licenses in your organistaion will appear here"
            />
        )
    }

    const licenseFilterAndStats = (alignment: 'center' | 'between') => {
        return (
            <div className={`d-flex flex-wrap justify-content-${alignment} align-items-center mb-3`}>
                {<LicenseCounter userLicenses={userLicenses} />}
                {filterButtons()}
            </div>
        )
    }

    const pageBody = () => {
        switch (pageState) {
            case PageState.LOADING:
                return <LoadingContainer spinnerSize='sm' />

            case PageState.ERROR:
                return <ErrorContainer errorText="We couldn't fetch the licenses..." />

            case PageState.DONE:
                return (
                    <>
                        <div className="d-none d-sm-block">{licenseFilterAndStats('between')}</div>
                        <div className="d-block d-sm-none">{licenseFilterAndStats('center')}</div>

                        {userLicenseList()}
                    </>
                )

            default:
                return <LoadingContainer spinnerSize='sm' />
        }
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <LicenseIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 mb-0 lexend-light">Licenses</p>
                    </div>
                    <div>
                        <button
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                            onClick={() => setLicenseState()}
                        >
                            <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
            ],
            current: {
                title: 'Licenses',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    const filterButtons = () => {
        return (
            <div className="d-flex justify-content-center flex-wrap">
                <button
                    className={`btn remotive-btn-sm remotive-btn-${filter === 'all' ? 'success' : 'primary'}`}
                    onClick={() => setFilter('all')}
                >
                    All
                </button>
                <button
                    className={`btn remotive-btn-sm remotive-btn-${filter === 'valid' ? 'success' : 'primary'}`}
                    onClick={() => setFilter('valid')}
                >
                    Valid
                </button>
                <button
                    className={`btn remotive-btn-sm remotive-btn-${filter === 'expired' ? 'success' : 'primary'}`}
                    onClick={() => setFilter('expired')}
                >
                    Expired
                </button>
            </div>
        )
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                isDemoGuideActive={false}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <Card className="shadow-sm rounded-4 border-0 mb-4" style={{ minHeight: '400px' }}>
                        <Card.Body className="pb-3 pt-3 text-start">{pageBody()}</Card.Body>
                    </Card>
                </div>
            </Container>

            {/* Modals below*/}
        </div>
    )
}
