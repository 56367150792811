import { RecordingAnnotation } from "../../../api/CloudApi/types"
import { addDurationToIso8061Timestamp, formatAsDateTimeWithMonthAsWord, formatAsHHMMSSmmm } from "../../../utils/datetime"

export const getAnnotationCreatedByLabel = (annotation: RecordingAnnotation) => {
    return `By ${annotation.createdBy.displayName} on ${formatAsDateTimeWithMonthAsWord(
        annotation.created,
        false
    )}`
}

export const getAnnotationTimestampRelativeToGraph = (annotation: RecordingAnnotation) => {
    return annotation.duration !== undefined
        ? `From ${formatAsHHMMSSmmm(annotation.timestamp)} to ${formatAsHHMMSSmmm(
              addDurationToIso8061Timestamp(annotation.timestamp, annotation.duration)
          )}`
        : `At ${formatAsHHMMSSmmm(annotation.timestamp)}`
}