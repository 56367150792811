import { Form, Modal } from 'react-bootstrap'
import { Project, RecordingSession } from '../api/CloudApi/types'
import { useEffect, useState } from 'react'
import LoadingContainer from '../components/LoadingContainer'
import CloudApi from '../api/CloudApi'
import ErrorContainer from '../components/ErrorContainer'

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

interface CopyRecordingSessionProps {
    show: boolean
    recordingSession?: string
    currentProject?: Project
    projects: Array<Project>
    onModalClose: () => void
}

enum CopyState {
    NOT_STARTED,
    LOADING,
    ERROR,
}

export default function CopyRecordingSessionModal(props: CopyRecordingSessionProps) {
    const [availableProjects, setAvailableProjects] = useState<Array<Project>>()
    const [selectedProject, setSelectedProject] = useState<string>()
    const [copyState, setCopyState] = useState(CopyState.NOT_STARTED)
    const [errorText, setErrorText] = useState<string>()

    useEffect(() => {
        if (props.projects) {
            const availableProjects = props.projects.filter((p) => p.uid !== props!.currentProject?.uid)
            setAvailableProjects(availableProjects)
            setSelectedProject(availableProjects[0]?.uid)
        }
    }, [props.projects])

    const handleCopyRecordingSessionToProject = async () => {
        try {
            setCopyState(CopyState.LOADING)
            await CloudApi.copyRecordingSession(props.currentProject!.uid, props.recordingSession!, selectedProject!)
            close()
        } catch (e: any) {
            if (e.response.status === 409) {
                setErrorText(`Recording already exists in project ${selectedProject}`)
            }
            setCopyState(CopyState.ERROR)
        }
    }

    const close = () => {
        setErrorText(undefined)
        setCopyState(CopyState.NOT_STARTED)
        props.onModalClose()
    }

    const getBody = () => {
        switch (copyState) {
            case CopyState.ERROR:
            case CopyState.NOT_STARTED:
                return (
                    <>
                        <Modal.Header
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                            closeButton
                            closeVariant="white"
                        >
                            <Modal.Title>Copy recording</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="h-100 mt-3 mb-3">
                                <Form>
                                    <div key={`remotive-recording`} className="mb-3">
                                        <p className={'m-1 p-1 border-bottom '}>Select destination project</p>

                                        {availableProjects && (
                                            <div className={'m-2 remotive-font-md remotive-primary-70-color'}>
                                                <Form.Control
                                                    as={'select'}
                                                    id={'asdf'}
                                                    className={'remotive-font-md remotive-primary-70-color'}
                                                    onChange={(e: any) => setSelectedProject(e.target.value)}
                                                    contentEditable={true}
                                                >
                                                    {availableProjects.map((option, index) => (
                                                        <option
                                                            value={option.uid}
                                                            key={option.uid}
                                                            selected={option.uid === selectedProject || index === 0}
                                                        >
                                                            {option.displayName}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                <div className={'m-2'}>
                                                    <Form.Text id="dbcHelp" muted>
                                                        All files in this recording session will be copied to the
                                                        selected project together with all required signal databases.
                                                    </Form.Text>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            </div>
                            {copyState == CopyState.ERROR && (
                                <ErrorContainer errorText="Could not copy recording" errorSubText={errorText} />
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className={'d-flex flex-column flex-grow-0'}>
                                <button
                                    className="btn remotive-btn remotive-btn-primary flex-shrink-1"
                                    onClick={handleCopyRecordingSessionToProject}
                                    disabled={selectedProject === undefined}
                                >
                                    Copy
                                </button>
                            </div>
                        </Modal.Footer>
                    </>
                )
            case CopyState.LOADING:
                return <LoadingContainer spinnerSize='sm' loadingText={'Copying...'} />
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={close}>
                {getBody()}
            </Modal>
        </>
    )
}
