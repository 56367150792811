import React, { useEffect, useRef, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { ProcessingUploadFile, ProcessingUploadFileStatus, Project, UserBillableUnitInfo } from '../api/CloudApi/types'
import ProcessingUploadListItem from './ProcessingUploadListItem'
import useInterval from '../hooks/useInterval'

interface ProcessingUploadAccordionProps {
    componentKey: string
    project: Project
    billableUnit: UserBillableUnitInfo
    onProcessingFinishedCallback: Function
    getUploads: () => Promise<Array<ProcessingUploadFile> | undefined>
    delete: (processedFile: ProcessingUploadFile) => Promise<void>
}

const PROCESSING_UPLOADS_POLLING_INTERVAL = 1_000

export default function ProcessingUploadsAccordion(props: ProcessingUploadAccordionProps) {
    const [processingUploads, setProcessingUploads] = useState<Array<ProcessingUploadFile>>([])
    const isPollingActive = useRef<boolean>(false)
    const previousProcessingUploads = useRef(processingUploads)

    useEffect(() => {
        fetchProcessingUploads(props.project)
    }, [props.componentKey])

    useInterval(() => {
        if (isPollingActive.current) {
            fetchProcessingUploads(props.project)
        }
    }, PROCESSING_UPLOADS_POLLING_INTERVAL)

    useEffect(() => {
        // Keep polling for processing uploads
        isPollingActive.current =
            processingUploads.filter(
                (it) =>
                    it.status === ProcessingUploadFileStatus.RUNNING || it.status === ProcessingUploadFileStatus.READY
            ).length > 0

        if (isProcessingOfAtLeastOneItemDone()) {
            props.onProcessingFinishedCallback()
        }
        previousProcessingUploads.current = processingUploads
    }, [processingUploads])

    const isProcessingOfAtLeastOneItemDone = () => {
        const previousDone = previousProcessingUploads.current.filter(
            (u) => u.status === ProcessingUploadFileStatus.DONE
        ).length
        const done = processingUploads.filter((u) => u.status === ProcessingUploadFileStatus.DONE).length

        return previousDone !== done
    }

    const fetchProcessingUploads = async (project: Project) => {
        const processing = await props.getUploads()
        if (processing) {
            setProcessingUploads(processing)
        }
    }

    const processingUploadsAccordion = () => {
        return (
            <Accordion flush defaultActiveKey={'0'} className="p-2 px-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div className="d-flex align-items-center">
                            <p className={'remotive-font-md m-0 p-0'}>
                                <b>Processing</b>
                            </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className={'flex-box align-content-start p-0 pt-1'}>
                        <>{processingUploadsList()}</>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }

    const processingUploadsToDisplay = processingUploads.filter(
        (it) =>
            it.status === ProcessingUploadFileStatus.RUNNING ||
            it.status === ProcessingUploadFileStatus.READY ||
            it.status === ProcessingUploadFileStatus.FAILED
    )

    const processingUploadsList = () => {
        if (processingUploadsToDisplay.length > 0) {
            return (
                <>
                    {processingUploadsToDisplay
                        .sort((recordingA, recordingB) => recordingA.errors.length - recordingB.errors.length)
                        .map((recording) => {
                            return (
                                <div key={`${props.componentKey}-${recording.uploadId}`}>
                                    <ProcessingUploadListItem
                                        background="white"
                                        uploadFile={recording}
                                        billableUnit={props.billableUnit}
                                        project={props.project}
                                        delete={props.delete}
                                        refreshProcessingUploadState={() => fetchProcessingUploads(props.project)}
                                    />
                                </div>
                            )
                        })}
                </>
            )
        } else {
            return <></>
        }
    }

    const processingUploadsSection = () => {
        return processingUploadsToDisplay.length > 0 ? (
            <div className="mb-1 remotive-primary-10-background rounded-3">{processingUploadsAccordion()}</div>
        ) : (
            <></>
        )
    }

    return processingUploadsSection()
}
