import { useState } from 'react'
import { useEffect } from 'react'
import { Card, Container, Spinner } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { AuthenticatedUser, BillableUnit, Project, UserBillableUnitInfo } from '../api/CloudApi/types'
import { SettingsIcon, WarningIcon } from '../assets/Icons'
import ErrorContainer from '../components/ErrorContainer'
import LoadingContainer from '../components/LoadingContainer'
import NavigationBar from '../components/navigation/NavigationBar'
import CloudApi from '../api/CloudApi'
import { OrganisationRoutes } from '../Routes'
import { BreadCrumb, BreadCrumbs } from '../types/BreadCrumbs'
import { PageDetails } from '../utils/pageDetails'
import { ConfirmDialogProperties } from '../types/ConfirmDialogProperties'

interface OrganisationSettingsProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    currentUser: AuthenticatedUser | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

enum PageState {
    LOADING,
    DONE,
    ERROR,
    DELETING,
    DELETE_ERROR,
}

export default function OrganisationSettings(props: OrganisationSettingsProps) {
    const [pageState, setPageState] = useState(PageState.LOADING)

    useEffect(() => {
        if (props.currentBillableUnit?.organisation.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Settings - ${props.currentBillableUnit?.organisation.displayName}`,
                productAnalyticsEventName: 'Organisation Settings',
            })
        }
    }, [props.currentBillableUnit])

    useEffect(() => {
        console.debug('Mounted organisation settings page!')
        setPageState(PageState.DONE)
    }, [])

    const billingSettingsHeader = () => {
        return (
            <>
                <div className="text-start mb-1 d-flex align-items-center">
                    <p className="fs-6 m-0 lexend-light">Billing</p>
                </div>
            </>
        )
    }

    const billingSettings = () => {
        return <></>
    }

    const billingSettingsBody = () => {
        switch (pageState) {
            case PageState.DONE:
                return <>{billingSettings()}</>

            case PageState.LOADING:
            default:
                return <LoadingContainer spinnerSize="sm" />
        }
    }

    const generalSettingsHeader = () => {
        return (
            <>
                <div className="text-start mb-1 d-flex align-items-center">
                    <p className="fs-6 m-0 lexend-light">General</p>
                </div>
            </>
        )
    }

    const generalSettings = () => {
        return (
            <>
                <div>
                    <p>
                        You are editing settings for <b>{props.currentBillableUnit?.organisation.displayName}</b>
                    </p>
                </div>

                <p className="mb-1 remotive-font">
                    <b>Danger zone</b>
                </p>
                <div style={{ border: '2px dashed #000' }} className="p-2">
                    <div>
                        <div>
                            <p className="mb-0 remotive-font-md">
                                <b>Delete organisation</b>
                            </p>
                            <p className="m-0 remotive-font-sm">
                                Deleting an organisation will delete everything that has been uploaded in any project
                                under this organisation
                            </p>
                        </div>
                        <div>
                            <button
                                onClick={() =>
                                    props.openConfirmationDialog({
                                        dialogTitle: 'Are you sure?',
                                        bodyText: (
                                            <>
                                                Are you sure you want to <b>DELETE</b> the organisation{' '}
                                                <b>{props.currentBillableUnit?.organisation.displayName}</b>?
                                            </>
                                        ),
                                        bodySubtitle:
                                            'Everyone will lose access to this organisation in the RemotiveCloud platform.',
                                        confirmButtonText: 'Yes, delete it',
                                        cancelButtonText: 'No, cancel',
                                        handleCancelFunction: () => console.log,
                                        handleConfirmFunction: () => deleteOrganisation(),
                                    } as ConfirmDialogProperties)
                                }
                                className="btn remotive-btn-md text-white remotive-btn-transparent bg-danger"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const generalSettingsBody = () => {
        switch (pageState) {
            case PageState.DONE:
                return <>{generalSettings()}</>

            case PageState.LOADING:
            default:
                return <LoadingContainer spinnerSize="sm" />
        }
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <SettingsIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 mb-0 lexend-light">Organisation settings</p>
                    </div>
                </div>
            </>
        )
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
            ],
            current: {
                title: 'Settings',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    const deleteOrganisation = async () => {
        const response = await CloudApi.deleteBillableUnit({
            uid: props.currentBillableUnit?.organisation.uid,
        } as BillableUnit)

        if (response.status === 200) {
            const availableBusAfterDeletion = props.billableUnits.filter(
                (billableUnit) => billableUnit.organisation.uid !== props.currentBillableUnit?.organisation.uid
            )
            if (availableBusAfterDeletion.length > 0) {
                window.location.replace(
                    CloudApi.getOrganisationHomeUrlFromUid(availableBusAfterDeletion[0].organisation.uid)
                )
            } else {
                CloudApi.removeAuthKey()
                window.location.replace('/')
            }
        }
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                isDemoGuideActive={false}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>

                <div className="mt-2">
                    <div>{generalSettingsHeader()}</div>
                    <div>
                        <Card className="shadow-sm rounded-4 border-0" style={{ minHeight: '200px' }}>
                            <Card.Body className="pb-1 mb-0 pt-3 text-start">{generalSettingsBody()}</Card.Body>
                        </Card>
                    </div>

                    {/*
                    <div>{billingSettingsHeader()}</div>
                    <div>
                        <Card className="shadow-sm border-0 mb-4" style={{ minHeight: '300px' }}>
                            <Card.Body className="rounded remotive-primary-0-background pb-3 pt-3 text-start">
                                {billingSettingsBody()}
                            </Card.Body>
                        </Card>
                    </div>
                     */}
                </div>
            </Container>
        </div>
    )
}
