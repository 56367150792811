import { useEffect, useState } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import CloudApi from '../api/CloudApi'
import { AuthenticatedUser, CreateBuRequest } from '../api/CloudApi/types'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../utils/toast'
import { PageDetails } from '../utils/pageDetails'

interface OrganisationToBeDeletedProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    currentUser: AuthenticatedUser | undefined
}

export default function OrganisationToBeDeleted(props: OrganisationToBeDeletedProps) {
    const [isCreating, setIsCreating] = useState(false)

    useEffect(() => {
        console.log(props.currentUser)
    }, [props.currentUser])

    useEffect(() => {
        props.updateCurrentPageDetails({
            documentTitle: 'Organisation is scheduled for deletion',
            productAnalyticsEventName: 'Organisation is scheduled for deletion',
        })
    }, [])

    const logo = () => {
        return (
            <>
                <div className="text-end me-3">
                    <img width="110" src="./remotive-labs-logo-pos@3x.png" />
                </div>
            </>
        )
    }

    const backToLoginAction = () => {
        CloudApi.removeAuthKey()
        window.location.replace('/')
    }

    const createOrganisationAction = () => {
        const submit = async () => {
            setIsCreating(true)
            const res = await CloudApi.createBillableUnit({
                displayName: `${props.currentUser ? `${props.currentUser.firstName}'s` : 'My'} Organisation`,
            } as CreateBuRequest)

            if (res.status === 200) {
                const response = await CloudApi.defaultUserBu()
                window.location.replace(CloudApi.getOrganisationHomeUrlFromUid(response.data.uid))
            } else {
                toast.error(
                    formattedToastMessage(
                        'Something went wrong when creating your organisation',
                        'Please reach out to us for further assistance, support@remotivelabs.com.'
                    )
                )
            }

            setIsCreating(false)
        }

        submit()
    }

    const overviewDescription = () => {
        return (
            <>
                <Card.Title className="mt-3 mb-4 fs-4 lexend-bold">Scheduled for deletion</Card.Title>
                <div className="d-flex justify-content-center">
                    <p className="remotive-font mb-2 px-3" style={{ maxWidth: 680 }}>
                        Your current <b>organisation</b> is scheduled for deletion and will be removed soon.{' '}
                        <b>Your account</b> will also be deleted soon unless you create a new organisation or are
                        invited to an existing one. If this is intended, you don't have to do anything further.
                    </p>
                </div>
            </>
        )
    }

    const selectableOptions = () => {
        return (
            <div className="d-flex flex-wrap align-items-center justify-content-center mb-5 mt-5 px-5">
                <div className="col-12 col-md-8 col-lg-5">{backToLoginOption()}</div>
                <div className="col-12 col-lg-2">
                    <div className="d-flex flex-row justify-content-evenly my-5">
                        <hr className="d-block d-lg-none w-25" />
                        <p className="fs-3 m-0">
                            <b>OR</b>
                        </p>
                        <hr className="d-block d-lg-none w-25" />
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-5">{createNewOrganisationOption()}</div>
            </div>
        )
    }

    const backToLoginOption = () => {
        return (
            <div style={{ height: 320 }} className="d-flex flex-column justify-content-between">
                <Card.Title className="mt-3 mb-4 fs-5 lexend-bold">Do nothing</Card.Title>
                <p className="remotive-font-md mb-2 text-start">
                    Your current <b>organisation</b> and <b>account</b> will be deleted. If this is intended, you don't
                    have to do anything further. All resources tied to this organisation will be removed, this includes
                    all projects, files, recordings etc.
                </p>
                <p className="mb-0 remotive-font-sm text-secondary text-start">
                    If you think this was an error, please contact us at{' '}
                    <a href="mailto:support@remotivelabs.com">support@remotivelabs.com</a> as soon as possible.
                </p>
                <button
                    disabled={isCreating}
                    className="btn remotive-btn-primary remotive-btn w-100 mt-4"
                    onClick={() => backToLoginAction()}
                >
                    Back to login
                </button>
            </div>
        )
    }

    const createNewOrganisationOption = () => {
        return (
            <div style={{ height: 320 }} className="d-flex flex-column justify-content-between">
                <Card.Title className="mt-3 mb-4 fs-5 lexend-bold">Create a new organisation</Card.Title>
                <p className="remotive-font-md mb-2 text-start">
                    Your can keep using the cloud console by creating a new organisation. This organisation will not be
                    connected to the organisation that is scheduled for deletion.
                </p>
                <p className="mb-0 remotive-font-sm text-secondary text-start">
                    Creating a new organisation will use your existing email, <b>{props.currentUser?.email}</b>. No
                    users, projects, files, recordings etc. will carry forward to the new organisation.
                </p>
                <button
                    className="btn remotive-btn-success remotive-btn w-100 mt-4"
                    onClick={() => createOrganisationAction()}
                    disabled={isCreating}
                >
                    {isCreating ? (
                        <div className="px-5">
                            <Spinner size="sm" />
                        </div>
                    ) : (
                        'Create organisation'
                    )}
                </button>
            </div>
        )
    }

    return (
        <>
            <div className="position-fixed">
                <video
                    crossOrigin="anonymous"
                    style={{ WebkitFilter: 'blur(8px)', objectFit: 'cover' }}
                    className="position-fixed w-100 h-100"
                    id="section-c6a9cb6-player"
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    preload={'none'}
                    src="https://remotivelabs.com/wp-content/uploads/2022/10/remotiveLabs.mp4"
                ></video>
                <div className="w-100 h-100 p-5 position-fixed remotive-primary-90-background opacity-75"></div>
            </div>
            <div className="h-100 w-100">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div style={{ maxWidth: 1200, margin: 'auto' }} className="col-12 col-md-10 col-xxl-8 py-2">
                        <Card className="border-0 shadow-lg rounded-4 p-2 py-3 mx-2">
                            {logo()}
                            {overviewDescription()}
                            {selectableOptions()}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}
