import { Duration, ZonedDateTime } from "@js-joda/core"
import { annotationBackgroundColor, commentIconAsSvgString, RenderableAnnotation, RenderableAnnotationProps } from "."
import { RecordingAnnotation } from "../../../../api/CloudApi/types"
import { Chart } from 'highcharts/highstock'

export function createDefaultRenderableAnnotation(props: RenderableAnnotationProps): RenderableAnnotation {
    const timestamp = ZonedDateTime.parse(props.annotation.timestamp).toInstant().toEpochMilli()

    const hasDuration = (): boolean => {
        return props.annotation.duration !== undefined && props.annotation.duration !== null
    }

    const getDuration = (annotation: RecordingAnnotation | undefined): number => {
        if (annotation?.duration !== undefined && annotation.duration !== null) {
            return Duration.parse(annotation.duration).toNanos() / 1000
        }
        return 0
    }

    return {
        onRender(chart: Chart) {},

        plotLines: hasDuration()
            ? []
            : [
                  {
                      id: `${props.annotation.title}-${props.annotation.created}-line`,
                      value: ZonedDateTime.parse(props.annotation.timestamp).toInstant().toEpochMilli(),
                      width: 2,
                      zIndex: 1,
                      events: {
                          click: function (e: any) {
                              props.onClick(props.annotation)
                          },
                      },
                  },
              ],

        plotBands: !hasDuration()
            ? undefined
            : {
                  id: `${props.annotation.title}-${props.annotation.created}-band`,
                  from: ZonedDateTime.parse(props.annotation.timestamp).toInstant().toEpochMilli(),
                  to: timestamp + getDuration(props.annotation),
                  zIndex: props.maxDuration - getDuration(props.annotation), // High duration, low Z
                  events: {
                      click: function (e: any) {
                          props.onClick(props.annotation)
                      },
                  },
                  color: annotationBackgroundColor(props.annotation, props.selectedAnnotation, '80%'),
                  borderWidth: 1,
                  borderColor: annotationBackgroundColor(props.annotation, props.selectedAnnotation, '80%'),
                  label: {
                      align: 'left',
                      useHTML: true,
                      text: `
                                <div class="lexend-regular text-dark px-2">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <p class="remotive-font-xs mb-0 me-2">${props.annotation.title}</p>
                                        <p class="mb-0 remotive-font-xs">${
                                            props.annotation.comments.length
                                        } <span>${commentIconAsSvgString('white')}</span></p>
                                    </div>
                                    
                                </div>`,
                  },
              },

        highchartAnnotations: hasDuration()
            ? undefined
            : {
                  id: `${props.annotation.title}-${props.annotation.created}-anno`,
                  events: {
                      click: function (e: any) {
                          props.onClick(props.annotation)
                      },
                  },
                  draggable: '',
                  labels: [
                      {
                          backgroundColor: annotationBackgroundColor(props.annotation, props.selectedAnnotation, '80%'),
                          borderWidth: 0,
                          borderRadius: 10,
                          //key: `${annotation.id}`,
                          useHTML: true,
                          formatter: function (): string {
                              return `
                                <div class="lexend-regular text-light px-2">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <p class="remotive-font-xs mb-0 me-2">${props.annotation.title}</p>
                                        <p class="mb-0 remotive-font-xs">${
                                            props.annotation.comments.length
                                        } <span>${commentIconAsSvgString('white')}</span></p>
                                    </div>
                                    
                                </div>`
                          },
                          point: {
                              xAxis: 0,
                              yAxis: 0,
                              x: ZonedDateTime.parse(props.annotation.timestamp).toInstant().toEpochMilli(),
                              y: props.findClosestYValue(), // Set the label y-value to the closest y-value of timestamp
                          },
                      },
                  ],
              },
    }
}