import Highcharts, { Chart } from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {
    AuthenticatedUser,
    RecordingAnnotation,
    SignalEntry,
    SignalListEntry,
    SignalMetadata,
} from '../../../../../api/CloudApi/types'
import { AnalyticsPanelType, GraphSettings, PanelKey, PlottableSignalEntry, SharedExtremes } from '../../Types'
import { Duration, ZonedDateTime } from '@js-joda/core'
import { useEffect, useRef, useState } from 'react'
import { AnnotationTheme, createRenderableAnnotation, RenderableAnnotationProps } from '../../RenderableAnnotation'
import { ANNOTATION_HEADER_RECT_KEY } from '../../RenderableAnnotation/CustomRectDrawer'
import LoadingContainer from '../../../../../components/LoadingContainer'

interface TimeSeriesChartProps {
    currentUser: AuthenticatedUser | undefined
    maxHeight: number
    panelKey: PanelKey
    plottableSignalEntries: Array<PlottableSignalEntry> | undefined
    availableAnnotations: Array<RecordingAnnotation> | undefined
    selectedSignals: Array<SignalEntry>
    hiddenSignals: Array<SignalEntry>
    graphSettings: GraphSettings
    selectedAnnotation: RecordingAnnotation | undefined
    isAddAnnotationActive: boolean
    sharedExtremes: SharedExtremes
    setSharedExtrems: (newExtremes: SharedExtremes) => void
    setSelectedAnnotation: (annotation: RecordingAnnotation | undefined) => void
    setShowAddAnnotationModal: (show: boolean) => void
    setLastClickedAnnotationTimestamp: (timestamp: number) => void
    setLastClickedAnnotationTimestampEnd: (timestamp: number) => void
}

enum highchartsConstructorType {
    DEFAULT_CHART = 'chart',
    STOCK_CHART = 'stockChart',
    MAP_CHART = 'mapChart',
    GANTT_CHART = 'ganttChart',
}

export default function TimeSeriesChart(props: TimeSeriesChartProps) {
    const chartRef = useRef<HighchartsReact.RefObject>(null)
    const [annotationsToShowInGraph, setAnnotationsToShowInGraph] = useState<Array<RecordingAnnotation>>(
        props.availableAnnotations ?? []
    )

    useEffect(() => {
        if (props.availableAnnotations) {
            if (props.graphSettings.showOnlyMyAnnotations) {
                setAnnotationsToShowInGraph(
                    props.availableAnnotations.filter((it) => {
                        console.log(`createdBy.uid=${it.createdBy.uid} and currentUser.uid=${props.currentUser?.uid}`)
                        return it.createdBy.uid === props.currentUser?.uid
                    })
                )
            } else if (props.graphSettings.showAllAnnotations) {
                setAnnotationsToShowInGraph(props.availableAnnotations)
            } else {
                setAnnotationsToShowInGraph([])
            }
        }
    }, [props.graphSettings, props.availableAnnotations])

    function findClosestYValueFromXValue(
        timestamp_iso: string,
        plottableSignalEntriesWithoutHiddenSignals: Array<PlottableSignalEntry>
    ): any {
        const timestamp = ZonedDateTime.parse(timestamp_iso).toInstant().toEpochMilli()

        const closestTimestamp = plottableSignalEntriesWithoutHiddenSignals
            .flatMap((it) => {
                // Flatten and return all x, y value pairs
                const closestDataPoint = it.signalData.flatMap((signalDataPoint) => {
                    return { x: signalDataPoint[0], y: signalDataPoint[1] }
                })
                return closestDataPoint
            })
            .find((dataPoint) => dataPoint.x > timestamp)
        return closestTimestamp?.y ?? 0
    }

    const storeExtremes = (e: Highcharts.AxisSetExtremesEventObject) => {
        switch (e.trigger as Highcharts.AxisExtremesTriggerValue) {
            case 'navigator':
            case 'pan':
            case 'scrollbar':
            case 'zoom':
            case 'rangeSelectorButton':
                props.setSharedExtrems({ xMax: Math.trunc(e.max), xMin: Math.trunc(e.min) })
                //syncExtremes(e)
                break

            case 'rangeSelectorInput':
            case 'traverseUpButton':
                // do nothing
                break
        }
    }

    const constructSignalNameKey = (signalName: string, frameName: string, namespace: string) => {
        const name = `${namespace}-${frameName}-${signalName}`.toLowerCase()
        return name
    }
    const highchartsOptions = (
        availableAnnotations: Array<RecordingAnnotation>,
        plottableSignalEntries: Array<PlottableSignalEntry>,
        hiddenSignals: SignalEntry[],
        graphSettings: GraphSettings,
        panelKey: PanelKey,
        isAddAnnotationActive: boolean,
        sharedExtremes: SharedExtremes,
        setShowAddAnnotationModal: (show: boolean) => void,
        setSelectedAnnotation: (selectedAnnotation: RecordingAnnotation | undefined) => void
    ) => {
        const plottableSignalEntriesWithoutHiddenSignals =
            plottableSignalEntries.filter(
                (it) =>
                    !hiddenSignals.find(
                        (signal) =>
                            constructSignalNameKey(signal.name, signal.frameName, signal.namespace) ===
                            constructSignalNameKey(
                                it.signalEntry.name,
                                it.signalEntry.frameName,
                                it.signalEntry.namespace
                            )
                    )
            ) ?? []
        const series = plottableSignalEntriesWithoutHiddenSignals.map((entry, i) => {
            const unit = entry.signalEntry.unit
            const color =
                Highcharts.getOptions().colors![
                    props.selectedSignals.findIndex(
                        (it) =>
                            constructSignalNameKey(it.name, it.frameName, it.namespace) ===
                            constructSignalNameKey(
                                entry.signalEntry.name,
                                entry.signalEntry.frameName,
                                entry.signalEntry.namespace
                            )
                    )
                ]
            const signalKey = constructSignalNameKey(
                entry.signalEntry.name,
                entry.signalEntry.frameName,
                entry.signalEntry.namespace
            )
            const attributes = {
                dataGrouping: {
                    enabled: false,
                },
                boostThreshold: 1000,
                id: signalKey,
                name: `${entry.signalEntry.name} ${unit}`,
                data: entry.signalData,
                selected: true,
                yAxis: i,
                color: color,
                tooltip: {
                    valueDecimals: 5,
                },
            }
            return attributes
        })

        // Get the maxDuration on the annotations and use this to get the correct Z index by reversing
        // high duration to low zIndex
        const maxDuration = Math.max(
            ...annotationsToShowInGraph
                .filter((b) => b.duration !== undefined && b.duration !== null)
                .map((band) => (Duration.parse(band.duration!).toNanos() / 1000) as number)
        )

        const renderableAnnotations = annotationsToShowInGraph.map((a) =>
            createRenderableAnnotation(AnnotationTheme.EXPERIMENTAL, {
                annotation: a,
                maxDuration: maxDuration,
                selectedAnnotation: props.selectedAnnotation,
                findClosestYValue: () =>
                    findClosestYValueFromXValue(a.timestamp, plottableSignalEntriesWithoutHiddenSignals),
                onClick: setSelectedAnnotation,
            } as RenderableAnnotationProps)
        )

        const yAxis = plottableSignalEntriesWithoutHiddenSignals.map((entry, i) => {
            const a = {
                //showLastLabel: true,
                visible: graphSettings.showYAxis,
                zoomEnabled: true,
                crosshair: true,
                lineWidth: 1,
                opposite: i % 2 === 0,
                min: props.graphSettings.useSuggestedMinMax ? entry.signalEntry.min : undefined,
                max: props.graphSettings.useSuggestedMinMax ? entry.signalEntry.max : undefined,
                title: {
                    text: entry.signalEntry.name,
                    style: {
                        color: Highcharts.getOptions().colors![i],
                    },
                },
            }
            return a
        })

        const options: any = {
            credits: {
                enabled: false,
            },
            boost: {
                debug: { showSkipSummary: true },
                //usePreAllocated: true,
                //useGPUTranslations: true,
            },
            chart: {
                // BEGIN Custom attributes
                chartType: AnalyticsPanelType.SIGNAL_TIME_SERIES,
                panelKey: panelKey.key,
                // END Custom attributes
                style: {
                    fontFamily: 'LexendDecaRegular',
                },
                backgroundColor: '#fff',
                zooming: {
                    type: 'x',
                    mouseWheel: false,
                },
                panning: true,
                panKey: 'shift',
                zoomType: 'x',
                height: '440',
                events: {
                    render: function (this: Chart) {
                        const chart = this

                        // @ts-ignore
                        if (chart[ANNOTATION_HEADER_RECT_KEY]) {
                            // @ts-ignore
                            const allHeaders = chart[ANNOTATION_HEADER_RECT_KEY]
                            // We need to perform deletion of annotation-headers here for now.
                            // After a redraw this will destroy any annotation header that was potentially removed
                            Object.keys(allHeaders)
                                .filter((key) => !availableAnnotations.map((a) => String(a.id)).includes(key))
                                .forEach((key) => {
                                    allHeaders[key].destroy()
                                })
                        } else {
                            // @ts-ignore
                            chart[ANNOTATION_HEADER_RECT_KEY] = {}
                        }
                        renderableAnnotations.forEach((a) => a.onRender(chart))
                    },

                    selection: function (event: any) {
                        if (isAddAnnotationActive) {
                            props.setLastClickedAnnotationTimestamp(event.xAxis[0].min)
                            props.setLastClickedAnnotationTimestampEnd(event.xAxis[0].max)
                            setShowAddAnnotationModal(true)
                            return false
                        }
                        return true
                    },

                    click: function (event: any) {
                        if (isAddAnnotationActive) {
                            props.setLastClickedAnnotationTimestamp(event.xAxis[0].value)
                            setShowAddAnnotationModal(true)
                        }
                    },
                    afterUpdate: (it: Highcharts.Chart) => {
                        if (
                            chartRef.current?.chart !== undefined &&
                            props.availableAnnotations !== undefined &&
                            props.plottableSignalEntries !== undefined &&
                            props.sharedExtremes.xMin !== undefined &&
                            props.sharedExtremes.xMax !== undefined
                        ) {
                            // TODO this is the only place where we interace with the chart directly.
                            // Would be nice to be able to remove this one day and only rely on the React state changes...
                            chartRef.current.chart.xAxis[0].setExtremes(
                                props.sharedExtremes.xMin,
                                props.sharedExtremes.xMax,
                                true,
                                false,
                                { trigger: 'setExtremesIfPresentOnAfterUpdate' }
                            )
                        }
                    },
                },
            },

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                            maxHeight: 200,
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal',
                            },
                        },
                    },
                ],
            },

            navigator: { enabled: true },

            rangeSelector: {
                buttonPosition: {
                    y: -9,
                },
                buttons: [
                    {
                        type: 'second',
                        count: 5,
                        text: '5s',
                    },
                    {
                        type: 'second',
                        count: 10,
                        text: '10s',
                    },
                    {
                        type: 'second',
                        count: 30,
                        text: '30s',
                    },
                    {
                        type: 'minute',
                        count: 1,
                        text: '1min',
                    },
                    {
                        type: 'minute',
                        count: 5,
                        text: '5m',
                    },
                    {
                        type: 'all',
                        text: 'All',
                    },
                ],
                inputEnabled: false, // it supports only days
                selected: 5,
            },

            legend: {
                enabled: false,
            },

            series: series,

            plotOptions: {
                series: {
                    animation: false,
                },
                flags: {
                    accessibility: {
                        exposeAsGroupOnly: true,
                        description: 'Flagged events.',
                    },
                },
                line: {
                    marker: {
                        enabled: true,
                    },
                },
            },
            yAxis: yAxis,
            xAxis: {
                min: sharedExtremes.xMin,
                max: sharedExtremes.xMax,
                visible: graphSettings.showXAxis,
                type: 'datetime',
                crosshair: {
                    snap: false,
                },
                events: {
                    setExtremes: (event: Highcharts.AxisSetExtremesEventObject) => {
                        // Do nothing
                    },
                    afterSetExtremes: storeExtremes,
                },

                plotBands: renderableAnnotations.map((a) => a.plotBands).filter((a) => a !== undefined),

                plotLines: renderableAnnotations.flatMap((a) => a.plotLines).filter((a) => a !== undefined),
            },

            annotations: renderableAnnotations.map((a) => a.highchartAnnotations).filter((a) => a !== undefined),

            /*            annotations: annotationsToShowInGraph
                .filter((it) => it.durationMs === null)
                .map((it) => {
                    return {
                        id: `${it.title}-${it.created}`,
                        events: {
                            click: function (e: any) {
                                setSelectedAnnotation(
                                    availableAnnotations.find((annotation) => annotation.timestamp === it.timestamp)
                                )
                            },
                        },
                        draggable: false,
                        labels: [
                            {
                                backgroundColor: annotationBackgroundColor(it, '80%'),
                                borderWidth: 0,
                                borderRadius: 10,
                                key: `${it.id}`,
                                useHTML: true,
                                formatter: function (): string {
                                    return `
                                <div class="lexend-regular text-light px-2">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <p class="remotive-font-xs mb-0 me-2">${it.title}</p>
                                        <p class="mb-0 remotive-font-xs">${
                                            it.comments.length
                                        } <span>${commentIconAsSvgString('white')}</span></p>
                                    </div>
                                    
                                </div>`
                                },
                                point: {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x: ZonedDateTime.parse(it.timestamp).toInstant().toEpochMilli(),
                                    y: findClosestYValueFromXValue(it.timestamp, plottableSignalData), // Set the label y-value to the closest y-value of timestamp
                                },
                            },
                        ],
                    }


                }),*/
        }

        return options
    }

    const higchartsGraphOrEmpty = () => {
        if (props.selectedSignals.length > 0 && props.selectedSignals.length === props.hiddenSignals.length) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <p className="m-0 remotive-font-xs">All signals are hidden</p>
                </div>
            )
        }
        if (
            (props.selectedSignals.length > 0 && props.plottableSignalEntries === undefined) ||
            props.availableAnnotations === undefined
        ) {
            return <LoadingContainer loadingText="" infoText="Plotting signal data..." spinnerSize="sm" />
        }
        if (props.plottableSignalEntries === undefined || props.availableAnnotations === undefined) {
            return <></>
        }
        return (
            <HighchartsReact
                data-ANALYTICS_CHART_TYPE_KEY={AnalyticsPanelType.SIGNAL_TIME_SERIES}
                highcharts={Highcharts}
                options={highchartsOptions(
                    props.availableAnnotations,
                    props.plottableSignalEntries,
                    props.hiddenSignals,
                    props.graphSettings,
                    props.panelKey,
                    props.isAddAnnotationActive,
                    props.sharedExtremes,
                    props.setShowAddAnnotationModal,
                    props.setSelectedAnnotation
                )}
                constructorType={highchartsConstructorType.STOCK_CHART}
                className="chart"
                updateArgs={[true, true, false]} //redraw, oneToOne, Animation
                ref={chartRef}
            />
        )
    }

    //console.log(`Rendering due to state change in ${props.panelKey.key}`)

    return (
        <div
            className={`${props.selectedAnnotation === undefined ? 'col-12' : 'col-6 col-md-8 col-xxl-9 pe-1'}`}
            style={{
                maxHeight: props.maxHeight,
            }}
            id={props.panelKey.key}
        >
            {higchartsGraphOrEmpty()}
        </div>
    )
}
