import { useCallback, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../../../utils/toast'
import { LatLonCoordinate } from '../../../../../types/LatLonCoordinate'

type GoogleWorldMapProps = {
    coordinates: Array<LatLonCoordinate>
}

const ZOOM_LEVEL = 14
const ROUTE_OPACITY = 0.95

const DEFAULT_LAT = 0 // Google Maps API default value
const DEFAULT_LNG = 180 // Google Maps API default value

const ROUTE_START_MARKER_COLOR = '#59d48a'
const ROUTE_START_MARKER_SIZE = 55
const ROUTE_END_MARKER_COLOR = '#0b3f70'
const ROUTE_END_MARKER_SIZE = 45

export default function GoogleWorldMap(props: GoogleWorldMapProps) {
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [errorText, setErrorText] = useState<string | undefined>()

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || 'N/A',
    })

    useEffect(() => {
        console.log(`Mounting Google Maps map, ${map}`)
    }, [])

    useEffect(() => {
        console.log(`loaded=${isLoaded}`)
    }, [isLoaded])

    useEffect(() => {
        console.log(`coordinates length=${props.coordinates.length}`)
    }, [props.coordinates])

    useEffect(() => {
        if (map && props.coordinates.length > 0) {
            setStartingPointMarker()
            drawDrivePath()
            setEndPointMarker()
        }
    }, [map, props.coordinates])

    useEffect(() => {
        if (
            isLoaded &&
            props.coordinates?.[0]?.latitude !== undefined &&
            props.coordinates?.[0]?.longitude !== undefined
        ) {
            zoomAndCenterMarker()
        }
    }, [isLoaded, props.coordinates])

    useEffect(() => {
        if (errorText !== undefined) {
            toast.error(
                formattedToastMessage(
                    errorText,
                    'Double check the settings in the map panel and make sure that the selected latitude and longitude signals correspond to the actual latitude and longitude values.'
                ),
                { autoClose: 20_000 }
            )
        }
    }, [errorText])

    const onLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance)
    }, [])

    const drawDrivePath = () => {
        console.log('Drawing drive path')
        const drivePath = new google.maps.Polyline({
            strokeColor: '#0b3f70',
            strokeOpacity: ROUTE_OPACITY,
            strokeWeight: 5,
            geodesic: true,
            path: props.coordinates.map((it) => {
                return { lat: it.latitude, lng: it.longitude }
            }),
        })
        drivePath.setMap(map)
    }

    const setStartingPointMarker = () => {
        console.log('Setting start marker')
        const iconSvg = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
            <svg width="${ROUTE_START_MARKER_SIZE}" height="${ROUTE_START_MARKER_SIZE}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z" fill="${ROUTE_START_MARKER_COLOR}" stroke="#FFF" stroke-width="1"/>
            </svg>
        `)}`
        const position = { lat: props.coordinates[0].latitude, lng: props.coordinates[0].longitude }
        const markerViewWithText = new google.maps.Marker({
            icon: iconSvg,
            clickable: true,
            zIndex: 1,
            opacity: ROUTE_OPACITY,
            position,
            title: 'Start',
        })
        markerViewWithText.addListener('click', () => {
            map?.setZoom(ZOOM_LEVEL)
            map?.setCenter(position)
        })
        markerViewWithText.setMap(map)
    }

    const setEndPointMarker = () => {
        console.log('Setting stop marker')
        const iconSvg = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
            <svg width="${ROUTE_END_MARKER_SIZE}" height="${ROUTE_END_MARKER_SIZE}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z" fill="${ROUTE_END_MARKER_COLOR}" stroke="#FFF" stroke-width="1"/>
            </svg>
        `)}`
        const position = {
            lat: props.coordinates[props.coordinates.length - 1].latitude,
            lng: props.coordinates[props.coordinates.length - 1].longitude,
        }
        const markerViewWithText = new google.maps.Marker({
            icon: iconSvg,
            zIndex: 2,
            opacity: ROUTE_OPACITY,
            position,
            title: 'Stop',
        })
        markerViewWithText.addListener('click', () => {
            map?.setZoom(ZOOM_LEVEL)
            map?.setCenter(position)
        })
        markerViewWithText.setMap(map)
    }

    const zoomAndCenterMarker = () => {
        if (isLoaded) {
            const typedMap = map as google.maps.Map
            if (!!typedMap) {
                console.log(`Centering on ${props.coordinates[0].latitude} and ${props.coordinates[0].longitude}`)
                typedMap.setCenter(
                    new google.maps.LatLng(props.coordinates[0].latitude, props.coordinates[0].longitude)
                )
                typedMap.setZoom(ZOOM_LEVEL)
            }
        }
    }

    return (
        <>
            {isLoaded && (
                <>
                    <GoogleMap
                        options={{
                            zoomControl: true,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }}
                        mapContainerClassName={`w-100 h-100`}
                        onLoad={onLoad}
                    />
                </>
            )}
            {!isLoaded && (
                <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                    <Spinner size='sm' />
                    <p>Loading...</p>
                </div>
            )}
        </>
    )
}
