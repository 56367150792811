import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import {
    AuthenticatedUser,
    DatabaseSelectionType,
    Organisation,
    Project,
    RecordingFile,
    RecordingMetaData,
    RecordingSession,
    SignalDatabase,
    UserBillableUnitInfo,
} from '../api/CloudApi/types'
import { DeleteIcon, DownloadIcon, EditIcon, WarningIcon } from '../assets/Icons'
import { Permission, hasPermission } from '../utils/permission'
import CloudApi from '../api/CloudApi'
import { ComponentState } from '../types/ComponentState'
import { ConfirmDialogProperties } from '../types/ConfirmDialogProperties'
import {
    ProductAnalyticsContext,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../utils/ProductAnalytics'
import TextBanner, { BannerType } from './TextBanner'
import { calculatePercentage } from '../utils/numberFormatting'
import { formattedToastMessage } from '../utils/toast'

const AnalyticsActions = {
    DOWNLOAD_RECORDING_FILE: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_FILES_TAB, 'DownloadRecordingFile'),
    EDIT_RECORDING_FILE: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_FILES_TAB, 'EditRecordingFile'),
    DELETE_RECORDING_FILE: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_FILES_TAB, 'DeleteRecordingFile'),
}

interface RecordingFileListItemProps {
    availableSignalDatabases: Array<SignalDatabase>
    allNamespaces: Array<string>
    currentUser: AuthenticatedUser | undefined
    recordingFile: RecordingFile
    parentRecordingSession: RecordingSession
    project: Project
    billableUnit: UserBillableUnitInfo
    refreshRecordingSession: Function
    editRecordingFunction: (recordingFile: RecordingFile) => void
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

const AUTOMATCH_THRESHOLD = 70 // percent

type RecordingFileIssue = {
    type: BannerType
    bodyTextElement: JSX.Element
    boldText: string
}

export default function RecordingFileListItem(props: RecordingFileListItemProps) {
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DONE)
    const [isAcceptingPoorMatch, setIsAcceptingPoorMatch] = useState<boolean>(false)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.currentUser,
        billableUnit: props.billableUnit,
    } as ProductAnalyticsProps)

    const deleteRecordingFile = async (recordingFile: RecordingFile) => {
        try {
            setComponentState(ComponentState.DELETING)
            await CloudApi.deleteRecordingFile(props.project.uid, props.parentRecordingSession.sessionId, recordingFile)
            await props.refreshRecordingSession() // Fetch recording session again with the updated details
            toast.success(`Successfully deleted recording file ${recordingFile.displayName}`)
        } catch (err) {
            toast.error(`Failed to delete recording ${recordingFile.displayName}`)
            setComponentState(ComponentState.DONE)
        }
    }

    const downloadRecordingFile = async (recordingFileName: string) => {
        try {
            setComponentState(ComponentState.LOADING)
            const { downloadUrl } = (
                await CloudApi.getRecordingFile(
                    props.project.uid,
                    props.parentRecordingSession.sessionId,
                    recordingFileName
                )
            ).data
            if (downloadUrl) {
                window.open(downloadUrl)
            }
            setComponentState(ComponentState.DONE)
        } catch (err) {
            toast.error(`Failed to download ${recordingFileName}`)
            setComponentState(ComponentState.DONE)
        }
    }

    const matchPercentageAsNumber = (metadata: RecordingMetaData | undefined) => {
        if (metadata?.automatchDetails?.matchingFrameIds === undefined || metadata?.totalFrameIds === undefined) {
            return 0
        }
        return calculatePercentage(metadata.automatchDetails.matchingFrameIds, metadata.totalFrameIds)
    }

    const isMissingDatabase = (metadata: RecordingMetaData | undefined) => {
        if (metadata === undefined) {
            return false
        }
        return metadata.databaseDetails === undefined || metadata.databaseDetails === null
    }

    const isConfiguredSignalDatabaseAvailable = (metadata: RecordingMetaData | undefined) => {
        return (
            metadata?.databaseDetails !== undefined &&
            props.availableSignalDatabases.map((it) => it.name).includes(metadata.databaseDetails.name)
        )
    }

    const isNamespaceNotSet = (metadata: RecordingMetaData) => {
        return metadata.namespace === undefined || metadata.namespace === null
    }

    const isNamespaceDuplicated = (currentNamespace: string, allNamespaces: Array<string>) => {
        return allNamespaces.filter((it) => it === currentNamespace).length > 1
    }

    const isPoorMatch = (metadata: RecordingMetaData | undefined) => {
        const matchPercentage = matchPercentageAsNumber(metadata)
        return matchPercentage > 0 && matchPercentageAsNumber(metadata) < AUTOMATCH_THRESHOLD
    }

    const getDatabaseIssue = (): RecordingFileIssue | undefined => {
        if (isPoorMatch(props.recordingFile.metadata) && props.recordingFile.metadata?.databaseDetails?.name === undefined) {
            const metadata = props.recordingFile.metadata
            const automatchDetails = metadata?.automatchDetails
            return {
                type: BannerType.WARNING,
                bodyTextElement: (
                    <div className="d-flex flex-row align-items-center">
                        <p className="m-0">
                            <b>{props.recordingFile.metadata?.automatchDetails?.database}</b> is the best match but only
                            match <b>{matchPercentageAsNumber(props.recordingFile.metadata)}%</b> of the frames in this
                            file.
                        </p>
                        {metadata && automatchDetails && (
                            <button
                                className="btn remotive-btn-sm remotive-btn-primary my-0 ms-3 d-flex align-items-center justify-content-center"
                                style={{ minWidth: 94 }}
                                disabled={isAcceptingPoorMatch}
                                onClick={() => {
                                    if (metadata.namespace !== undefined) {
                                        acceptPoorMatch(
                                            props.project,
                                            props.parentRecordingSession,
                                            props.recordingFile,
                                            metadata.namespace,
                                            automatchDetails.database
                                        )
                                    } else {
                                        toast.error(
                                            formattedToastMessage(
                                                'File update failed',
                                                'This file seems to missing a namespace. Please set the namespace field before trying again.'
                                            )
                                        )
                                    }
                                }}
                            >
                                {isAcceptingPoorMatch ? (
                                    <Spinner
                                        size="sm"
                                        className="remotive-font-xs mx-0 p-0 d-block"
                                        style={{ marginTop: 2.5, marginBottom: 2.5, height: 10, width: 10 }}
                                    />
                                ) : (
                                    <p className="remotive-font-xs m-0 text-nowrap">Use it anyway</p>
                                )}
                            </button>
                        )}
                    </div>
                ),
                boldText: 'Is it a match?',
            }
        } else if (isMissingDatabase(props.recordingFile.metadata)) {
            return {
                type: BannerType.WARNING,
                bodyTextElement: (
                    <div className="d-flex flex-row align-items-center">
                        <p className="m-0">There is no signal database set for this file.</p>
                        <button
                            onClick={() => props.editRecordingFunction(props.recordingFile)}
                            disabled={
                                !hasPermission(Permission.PROJECT_EDITOR_RECORDING, props.billableUnit, props.project)
                            }
                            className="btn remotive-btn-sm remotive-btn-primary my-0    ms-3"
                        >
                            <p className="remotive-font-xs m-0">Set database</p>
                        </button>
                    </div>
                ),
                boldText: 'Signal database missing',
            }
        } else if (!isConfiguredSignalDatabaseAvailable(props.recordingFile.metadata)) {
            return {
                type: BannerType.WARNING,
                bodyTextElement: (
                    <p className="m-0">
                        The specified signal database does not exist in this project, upload it or change the signal
                        database.
                    </p>
                ),
                boldText: 'Signal database unavailable',
            }
        }
    }

    const acceptPoorMatch = async (
        project: Project,
        recordingSession: RecordingSession,
        recordingFile: RecordingFile,
        recordingFileNamespace: string,
        signalDatabaseNameToAccept: string
    ) => {
        setIsAcceptingPoorMatch(true)
        try {
            await CloudApi.updateRecordingFileConfiguration(
                project.uid,
                recordingSession.sessionId,
                recordingFile.fileName,
                recordingFileNamespace,
                signalDatabaseNameToAccept
            )
            await props.refreshRecordingSession()
        } catch (e: any) {
            toast.error(
                formattedToastMessage(
                    'File update failed',
                    'We encountered an unknown error when updating the recording file configuration. Please try again later.'
                )
            )
        }
        setIsAcceptingPoorMatch(false)
    }

    const checkForIssues = () => {
        const issues: Array<RecordingFileIssue> = []
        if (props.recordingFile.metadata === undefined) {
            return [
                {
                    type: BannerType.ERROR,
                    bodyTextElement: <p className="m-0">There is no metadata available for this recording</p>,
                    boldText: 'Error',
                },
            ]
        }

        const datbaseIssues = getDatabaseIssue()
        if (datbaseIssues !== undefined) {
            issues.push(datbaseIssues)
        }

        if (isNamespaceNotSet(props.recordingFile.metadata)) {
            issues.push({
                type: BannerType.WARNING,
                bodyTextElement: <p className="m-0">There is no namespace associated with this recording</p>,
                boldText: 'Namespace missing',
            })
        } else if (isNamespaceDuplicated(props.recordingFile.metadata.namespace!, props.allNamespaces)) {
            issues.push({
                type: BannerType.WARNING,
                bodyTextElement: (
                    <p className="m-0">
                        This namespace is duplicated between one or more recording files but a namespace must be unique.
                    </p>
                ),
                boldText: 'Namespace duplicated',
            })
        }

        return issues
    }

    const getBanners = () => {
        const issuesThatNeedABanner = checkForIssues()

        if (issuesThatNeedABanner.length === 0) {
            return <></>
        }

        return (
            <div className="rounded-bottom-3 remotive-primary-10-background p-2 pt-2" style={{ marginTop: -4 }}>
                {/* <div className="d-flex flex-shrink-1"> */}
                <div>
                    {issuesThatNeedABanner.map((it) => (
                        <TextBanner
                            key={it.boldText}
                            type={it.type}
                            boldText={it.boldText}
                            bodyTextElement={it.bodyTextElement}
                        />
                    ))}
                </div>
            </div>
        )
    }

    const automatchLabel = (metadata: RecordingMetaData | undefined) => {
        const labelDetails = getLabelDetails(metadata)
        if (labelDetails === undefined) {
            return <></>
        }

        return (
            <div className={`rounded-2 mx-2 flex-truncate ${labelDetails.backgroundColor}`}>
                <p className={`m-0 remotive-font-xs ${labelDetails.textColor}  mx-2 text-truncate`}>
                    {labelDetails.label}
                </p>
            </div>
        )
    }

    const getLabelDetails = (
        metadata: RecordingMetaData | undefined
    ):
        | {
              label: string
              backgroundColor: string
              textColor: string
          }
        | undefined => {
        switch (metadata?.databaseDetails?.selection) {
            case DatabaseSelectionType.USER:
                return {
                    label: `Set by user`,
                    backgroundColor: 'remotive-primary-50-background',
                    textColor: 'text-light',
                }

            case DatabaseSelectionType.RECORDING:
                return {
                    label: `From recording`,
                    backgroundColor: 'remotive-primary-50-background',
                    textColor: 'text-light',
                }

            case DatabaseSelectionType.AUTO:
                const matchPercentage = matchPercentageAsNumber(metadata)
                return {
                    label: `${matchPercentage}% match`,
                    backgroundColor:
                        matchPercentage > AUTOMATCH_THRESHOLD
                            ? 'remotive-success-40-background'
                            : 'remotive-warning-40-background',
                    textColor:
                        matchPercentage > AUTOMATCH_THRESHOLD
                            ? 'remotive-success-90-color'
                            : 'remotive-warning-100-color',
                }

            default:
                return undefined
        }
    }

    const recordingFileListItem = (
        recordingFile: RecordingFile,
        project: Project,
        billableUnit: UserBillableUnitInfo
    ) => {
        return (
            <div key={recordingFile.fileName} className="m-1 my-1">
                <div className={`rounded remotive-primary-10-background`}>
                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <div className="col-4 col-md-5 px-2 pt-1 pb-1">
                                <div className="border-end h-100">
                                    <p title={recordingFile.displayName} className="m-0 remotive-font-md text-truncate">
                                        {recordingFile.displayName}
                                    </p>

                                    {recordingFile.metadata?.type && (
                                        <p
                                            title={recordingFile.metadata.type}
                                            className="m-0 remotive-font-sm text-secondary text-truncate"
                                        >
                                            {recordingFile.metadata.type}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-3 px-2 pt-1 pb-1">
                                <div className="border-end h-100">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="m-0 remotive-font-md">Database</p>
                                        {automatchLabel(recordingFile.metadata)}
                                    </div>
                                    <p
                                        title={recordingFile.metadata?.databaseDetails?.name}
                                        className="m-0 remotive-font-sm text-secondary text-truncate"
                                    >
                                        {recordingFile.metadata?.databaseDetails?.name ?? 'N/A'}
                                    </p>
                                </div>
                            </div>
                            <div className="col-2 px-2 pt-1 pb-1">
                                <div className="h-100 border-end">
                                    <p className="m-0 remotive-font-md text-truncate">Namespace</p>
                                    <p
                                        title={recordingFile.metadata?.namespace}
                                        className="m-0 remotive-font-sm text-secondary text-truncate"
                                    >
                                        {recordingFile.metadata?.namespace}
                                    </p>
                                </div>
                            </div>
                            <div className="col-3 col-md-2 d-flex justify-content-center align-items-center remotive-primary-60-color text-truncate">
                                <button
                                    className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0 me-1'}
                                    disabled={
                                        !hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project)
                                    }
                                    onClick={() => {
                                        productAnalyticsClient.track(AnalyticsActions.EDIT_RECORDING_FILE)
                                        props.editRecordingFunction(recordingFile)
                                    }}
                                >
                                    <EditIcon sx={{ fontSize: 19 }} />
                                </button>
                                <button
                                    className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0 me-1'}
                                    disabled={
                                        !hasPermission(Permission.PROJECT_VIEWER_RECORDING, billableUnit, project)
                                    }
                                    onClick={() => {
                                        productAnalyticsClient.track(AnalyticsActions.DOWNLOAD_RECORDING_FILE)
                                        downloadRecordingFile(recordingFile.fileName)
                                    }}
                                >
                                    <DownloadIcon sx={{ fontSize: 20 }} />
                                </button>
                                <button
                                    className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                    disabled={
                                        !hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project)
                                    }
                                    onClick={() =>
                                        props.openConfirmationDialog({
                                            dialogTitle: 'Are you sure?',
                                            bodyText: (
                                                <>
                                                    Are you sure you want to delete the recording file{' '}
                                                    <b>{recordingFile.fileName}</b>?
                                                </>
                                            ),
                                            bodySubtitle: 'You can not undo this action.',
                                            confirmButtonText: 'Yes, delete it',
                                            cancelButtonText: 'No, cancel',
                                            handleCancelFunction: () => console.log,
                                            handleConfirmFunction: () => {
                                                productAnalyticsClient.track(AnalyticsActions.DELETE_RECORDING_FILE)
                                                deleteRecordingFile(recordingFile)
                                            },
                                        } as ConfirmDialogProperties)
                                    }
                                >
                                    {componentState === ComponentState.DELETING ? (
                                        <Spinner size="sm" className="me-1" />
                                    ) : (
                                        <DeleteIcon sx={{ fontSize: 20 }} />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>{getBanners()}</div>
            </div>
        )
    }

    return recordingFileListItem(props.recordingFile, props.project, props.billableUnit)
}
