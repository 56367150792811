import { RecordingAnnotation } from '../../../../api/CloudApi/types'
import { animObject, AnnotationsOptions, AxisPlotBandsOptions, AxisPlotLinesOptions } from 'highcharts'
import { Duration, ZonedDateTime } from '@js-joda/core'
import { number } from 'yup'
import { Chart } from 'highcharts/highstock'
import { drawCompleteHeader } from './CustomRectDrawer'
import { createDefaultRenderableAnnotation } from './DefaultRenderableAnnotation'
import { createExperimentalRenderableAnnotation } from './ExperimentalRenderableAnnotation'

export interface RenderableAnnotation {
    plotLines: Array<AxisPlotLinesOptions>
    plotBands: AxisPlotBandsOptions | undefined
    highchartAnnotations: AnnotationsOptions | undefined
    onRender: (chart: Chart) => void
}

export const annotationBackgroundColor = (
    annotation: RecordingAnnotation,
    selectedAnnotation: RecordingAnnotation | undefined,
    opacity?: '10%' | '80%'
) => {
    const opacityInHex = opacity === '10%' ? '19' : opacity === '80%' ? 'cc' : ''
    return annotation.id === selectedAnnotation?.id ? `#0b8551${opacityInHex}` : `#002649${opacityInHex}`
}

export const commentIconAsSvgString = (color: 'white' | 'primary' | 'success') => {
    const hexColors = new Map([
        ['success', '0b8551'],
        ['primary', '0b3f70'],
        ['white', 'fff'],
    ])
    const selectedHexColor = `#${hexColors.get(color) ?? 'fff'}`
    return `<svg width="9" height="9" fill="${selectedHexColor}">
  <path d="M0.75 0.75H7.75C8.02614 0.75 8.25 0.973858 8.25 1.25V5.75C8.25 6.02614 8.02614 6.25 7.75 6.25H3L0.75 7.75V1.25C0.75 0.973858 0.973858 0.75 1.25 0.75Z" 
        fill="${selectedHexColor}"/>
</svg>
`
}

export interface RenderableAnnotationProps {
    annotation: RecordingAnnotation
    maxDuration: number
    selectedAnnotation: RecordingAnnotation | undefined
    onClick: (annotation: RecordingAnnotation) => void
    findClosestYValue: () => number
}

export enum AnnotationTheme {
    DEFAULT,
    EXPERIMENTAL
}

export function createRenderableAnnotation(annotationTheme: AnnotationTheme, props: RenderableAnnotationProps) {
    switch (annotationTheme) {
        case AnnotationTheme.DEFAULT:
            return createDefaultRenderableAnnotation(props)

        case AnnotationTheme.EXPERIMENTAL:
            return createExperimentalRenderableAnnotation(props)

        default:
            throw Error(`BUG! unknown annotation theme ${annotationTheme}`)
    }
}
