import Highcharts from 'highcharts/highstock'
import HighchartsBoost from 'highcharts/modules/boost'
import Exporting from 'highcharts/modules/exporting'
import Accessibility from 'highcharts/modules/accessibility'
import AnnotationsModule from 'highcharts/modules/annotations'
import CloudApi from '../../../../../api/CloudApi'
import { ReactElement, useEffect, useState } from 'react'
import {
    AuthenticatedUser,
    FrameEntry,
    Project,
    RecordingAnnotation,
    RecordingSession,
    SignalEntry,
    SignalIdentifier,
} from '../../../../../api/CloudApi/types'
import { toast } from 'react-toastify'
import LoadingContainer from '../../../../../components/LoadingContainer'
import ErrorContainer from '../../../../../components/ErrorContainer'
import CloseRounded from '@mui/icons-material/CloseRounded'
import RuleRounded from '@mui/icons-material/RuleRounded'
import SpeakerNotesRounded from '@mui/icons-material/SpeakerNotesRounded'
import UpgradeRounded from '@mui/icons-material/UpgradeRounded'
import { PanelKey, PlottableSignalEntry, SharedExtremes, TimeSeriesPanel } from '../../Types'
import { Form, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { formattedToastMessage } from '../../../../../utils/toast'
import SelectSignalsModal from '../../SelectSignalsModal'
import SignalInChartLabel from './SignalInChartLabel'
import { isLocalOrDevEnvironment } from '../../../../../utils/featureToggle'
import AnnotationComponent from '../../AnnotationContainer'
import { CloseIcon, SettingsIcon } from '../../../../../assets/Icons'
import AddAnnotationModal from '../../AnnotationContainer/AddAnnotationModal'
import TimeSeriesChart from './TimeSeriesChart'

HighchartsBoost(Highcharts)

interface TimeSeriesContainerProps {
    updatePanel: (panel: TimeSeriesPanel) => void
    removeThisPanelFunction: () => void
    currentUser: AuthenticatedUser | undefined
    currentProject: Project | undefined
    recordingSession: RecordingSession | undefined
    selectedSignals: Array<SignalEntry>
    hiddenSignals: Array<SignalEntry>
    availableFrameEntries: Array<FrameEntry>
    availableAnnotations: Array<RecordingAnnotation> | undefined
    panelKey: PanelKey
    safeFetchAndSetAvailableAnnotations: () => Promise<Array<RecordingAnnotation>>
    sharedExtremes: SharedExtremes
    setSharedExtremes: (newExtremes: SharedExtremes) => void
}

const TIMESERIES_VISUALIZATION_HEIGHT = 430

export default function TimeSeriesContainer(props: TimeSeriesContainerProps) {
    Exporting(Highcharts)
    Accessibility(Highcharts)
    AnnotationsModule(Highcharts)

    const [componentState, setComponentState] = useState(ComponentState.LOADING)

    // Signals
    const [selectableSignals, setSelectableSignals] = useState<Array<FrameEntry>>(props.availableFrameEntries)
    const [selectedSignals, setSelectedSignals] = useState<Array<SignalEntry>>(props.selectedSignals ?? [])
    const [hiddenSignals, setHiddenSignals] = useState<Array<SignalEntry>>(props.hiddenSignals ?? [])
    const [showSelectSignalsModal, setShowSelectSignalsModal] = useState<boolean>(selectedSignals.length === 0)
    const [plottableSignalEntries, setPlottableSignalEntries] = useState<Array<PlottableSignalEntry> | undefined>()

    // Settings
    const [showSettingsPopover, setShowSettingsPopover] = useState<boolean>(false)
    const [showOnlyMyAnnotations, setShowOnlyMyAnnotations] = useState(false)
    const [showAllAnnotations, setShowAllAnnotations] = useState(true)
    const [showYAxis, setShowYAxis] = useState(true)
    const [showXAxis, setShowXAxis] = useState(true)
    const [useSuggestedMinMax, setUseSuggestedMinMax] = useState(false)

    // Annotations
    const [lastClickedAnnotationTimestamp, setLastClickedAnnotationTimestamp] = useState<number | undefined>(undefined)
    const [lastClickedAnnotationTimestampEnd, setLastClickedAnnotationTimestampEnd] = useState<number | undefined>(
        undefined
    )
    const [selectedAnnotation, setSelectedAnnotation] = useState<RecordingAnnotation | undefined>()
    const [isAddAnnotationActive, setIsAddAnnotationActive] = useState<boolean>(false)
    const [showAddAnnotationModal, setShowAddAnnotationModal] = useState<boolean>(false)

    useEffect(() => {
        console.log('Mounting TimeSeriesContainer!')
    }, [])

    useEffect(() => {
        if (showAddAnnotationModal) {
            setIsAddAnnotationActive(false)
        }
    }, [showAddAnnotationModal])

    useEffect(() => {
        if (showAllAnnotations) {
            setShowOnlyMyAnnotations(false)
        }
    }, [showAllAnnotations])

    useEffect(() => {
        if (showOnlyMyAnnotations) {
            setShowAllAnnotations(false)
        }
    }, [showOnlyMyAnnotations])

    useEffect(() => {
        setSelectableSignals(props.availableFrameEntries)
        setComponentState(ComponentState.DONE)
    }, [props.availableFrameEntries])

    useEffect(() => {
        if (props.recordingSession?.sessionId && (selectedSignals.length > 0 || hiddenSignals.length > 0)) {
            const panelToStore = { panelKey: props.panelKey, selectedSignals, hiddenSignals } as TimeSeriesPanel
            props.updatePanel(panelToStore)
        }
    }, [selectedSignals, hiddenSignals])

    useEffect(() => {
        if (selectedSignals.length > 0) {
            loadSignalData()
            setHiddenSignals(
                hiddenSignals.filter((it) =>
                    selectedSignals
                        .map((frame) => constructSignalNameKey(frame.name, frame.frameName, frame.namespace))
                        .includes(constructSignalNameKeyFromSignalEntry(it))
                )
            )
        } else {
            setPlottableSignalEntries(undefined)
        }
    }, [selectedSignals])

    const onError = (err: any) => {
        console.error(err)
        setComponentState(ComponentState.ERROR)
    }

    const loadSignalData = async () => {
        setComponentState(ComponentState.LOADING)
        setPlottableSignalEntries(undefined)
        if (selectedSignals.length > 0) {
            const signalNames: Array<SignalIdentifier> = selectedSignals?.map((s) => ({
                namespace: s.namespace,
                frameName: s.frameName,
                signalName: s.name,
            }))
            CloudApi.getSignalsTimeseries(props.currentProject!.uid, props.recordingSession!.sessionId, signalNames)
                .then((res) => {
                    setComponentState(ComponentState.DONE)
                    const plottableSignalEntries = res.data.signals
                        .map((it) => {
                            const selectedSignal = selectedSignals.find(
                                (selectedSignal) => `${selectedSignal.frameName}.${selectedSignal.name}` === it.name
                            )
                            if (selectedSignal === undefined) {
                                console.warn(`Could not find signal metadata for ${it.name}`)
                                return undefined
                            }
                            return {
                                signalData: it.signals,
                                signalEntry: selectedSignal,
                            } as PlottableSignalEntry
                        })
                        .filter((it) => it !== undefined) as Array<PlottableSignalEntry>
                    setPlottableSignalEntries(plottableSignalEntries)
                    if (res.data.signals.length === 0) {
                        toast.warn(
                            formattedToastMessage(
                                'Graph issue',
                                "We couldn't create a graph using the currently selected signals, there are no data points available."
                            ),
                            { autoClose: 20_000 }
                        )
                    } else if (res.data.signals.length !== selectedSignals.length) {
                        toast.warn(
                            formattedToastMessage(
                                'Graph issue',
                                "We couldn't find data points for all your currently selected signals. The signals without data points have been disabled."
                            ),
                            { autoClose: 20_000 }
                        )
                    }
                })
                .catch(onError)
        } else {
            setComponentState(ComponentState.DONE)
        }
    }

    const exportSignalData = async () => {
        if (selectedSignals.length > 0) {
            const signalNames: Array<SignalIdentifier> = selectedSignals?.map((s) => ({
                namespace: s.namespace,
                frameName: s.frameName,
                signalName: s.name,
            }))
            CloudApi.exportTimeseries(props.currentProject!.uid, props.recordingSession!.sessionId, signalNames)
                .then((res) => {
                    // TODO - How to show link to user? As link, open tab, redirect?
                    window.open(`/p/${props.currentProject?.uid}/files/?currentPath=${res.data}`, '_blank')
                })
                .catch((err: any) => {
                    toast.error(
                        formattedToastMessage(
                            'Error',
                            err?.response?.data ||
                                'Something went wrong when exporting signal data. Please refresh the application and try again.'
                        )
                    )
                })
        } else {
            setComponentState(ComponentState.DONE)
        }
    }

    const constructSignalNameKeyFromSignalEntry = (signalEntry: SignalEntry) => {
        return constructSignalNameKey(signalEntry.name, signalEntry.frameName, signalEntry.namespace)
    }

    const constructSignalNameKey = (signalName: string, frameName: string, namespace: string) => {
        const name = `${namespace}-${frameName}-${signalName}`.toLowerCase()
        return name
    }

    const hexColorFromHighchartsColor = (
        color: undefined | string | Highcharts.GradientColorObject | Highcharts.PatternObject,
        isSignalInGraph: boolean
    ) => {
        if (!isSignalInGraph || color === undefined) {
            return '#dde1e6' // Appears hidden on disabled element
        }
        return color.toString()
    }

    const currentlySelectedSignals = () => {
        return selectedSignals.map((signal, index) => {
            const chartColor = Highcharts.getOptions().colors![index]
            const isSignalInGraph =
                plottableSignalEntries !== undefined &&
                plottableSignalEntries.map((it) => it.signalEntry).includes(signal)
            const isSignalHidden =
                props.hiddenSignals.find(
                    (it) => constructSignalNameKeyFromSignalEntry(it) === constructSignalNameKeyFromSignalEntry(signal)
                ) === undefined
            return (
                <div key={constructSignalNameKeyFromSignalEntry(signal)} className="m-1">
                    <SignalInChartLabel
                        signal={signal}
                        isSignalHidden={isSignalHidden}
                        isSignalInGraph={isSignalInGraph}
                        hiddenSignals={hiddenSignals}
                        constructSignalNameKeyFromSignalEntry={constructSignalNameKeyFromSignalEntry}
                        setHiddenSignals={setHiddenSignals}
                        htmlColor={hexColorFromHighchartsColor(chartColor, isSignalInGraph)}
                    />
                </div>
            )
        })
    }

    const closeButton = () => {
        return (
            <div className="d-flex flex-column align-items-center">
                <button
                    style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }}
                    onClick={() => props.removeThisPanelFunction()}
                    className="btn p-0 remotive-btn-no-bg"
                >
                    <div className="d-flex align-items-center" title="Close entire panel">
                        <CloseRounded sx={{ fontSize: 22 }} />
                    </div>
                </button>
            </div>
        )
    }

    const settingsButton = () => {
        return (
            <div className="d-flex flex-column align-items-center">
                <button
                    disabled={selectedSignals.length === 0}
                    className={`btn remotive-btn-no-bg remotive-btn p-0 m-0`}
                >
                    <div className="d-flex align-items-center mx-1" title="Settings for this signal time series panel">
                        <OverlayTrigger
                            trigger="click"
                            rootClose
                            show={showSettingsPopover}
                            onToggle={(newState: boolean) => setShowSettingsPopover(newState)}
                            placement="top"
                            overlay={settingsPopover}
                        >
                            <div className="d-flex align-items-center">
                                <SettingsIcon sx={{ fontSize: 20 }} />
                            </div>
                        </OverlayTrigger>
                    </div>
                </button>
            </div>
        )
    }

    const toolbar = () => {
        const ICON_SIZE = 14
        return (
            <div style={{ marginTop: -2 }} className="remotive-primary-60-border px-1">
                <div className="d-flex align-items-start">
                    <div className="">
                        <button
                            disabled={componentState === ComponentState.LOADING}
                            onClick={() => setShowSelectSignalsModal(true)}
                            title="Select signals to visualize in this panel"
                            className="btn remotive-btn-no-bg p-0 px-3 m-0"
                        >
                            <div className=" d-flex flex-column align-items-center">
                                <RuleRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Select signals</p>
                            </div>
                        </button>
                    </div>
                    <div className="ms-2">
                        <button
                            disabled={componentState === ComponentState.LOADING}
                            title="Add an annotation to the graph"
                            onClick={() => setIsAddAnnotationActive(!isAddAnnotationActive)}
                            className="btn remotive-btn-no-bg p-0 m-0 px-2"
                        >
                            <div
                                className={`d-flex flex-column align-items-center ${
                                    isAddAnnotationActive ? 'remotive-success-60-color' : ''
                                }`}
                            >
                                <SpeakerNotesRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Annotate</p>
                            </div>
                        </button>
                    </div>

                    {/* This features is not ready yet!
                    <div className="">
                        <button
                            disabled={componentState === ComponentState.LOADING}
                            title="Measure time difference between signals"
                            className="btn remotive-btn-no-bg p-0 px-2 m-0"
                        >
                            <div className="d-flex flex-column align-items-center">
                                <StraightenRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                <p className="remotive-font-xxs m-0">Measure</p>
                            </div>
                        </button>
                    </div> 
                    */}
                    {isLocalOrDevEnvironment() && (
                        <div className="">
                            <button
                                onClick={() => exportSignalData()}
                                disabled={componentState === ComponentState.LOADING}
                                title="Export signal data"
                                className="btn remotive-btn-no-bg p-0 px-2 m-0"
                            >
                                <div className="d-flex flex-column align-items-center">
                                    <UpgradeRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                    <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Export</p>
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const selectSignalsButton = (size: 'sm' | 'lg') => {
        const isSmall = size === 'sm'
        return (
            <div className="d-flex flex-column align-items-center ms-2">
                <button
                    disabled={componentState === ComponentState.LOADING}
                    onClick={() => setShowSelectSignalsModal(true)}
                    className={`btn remotive-btn-primary ${isSmall ? 'remotive-btn-sm' : 'remotive-btn'} my-0`}
                >
                    <div className="d-flex align-items-center mx-1" title="Select signals to visualize in this panel">
                        {showSelectSignalsModal ? (
                            <>
                                <Spinner size="sm" />
                            </>
                        ) : (
                            <>
                                <RuleRounded sx={{ fontSize: isSmall ? 17 : 24 }} className="me-2" />
                                <p className="m-0">Select signals</p>
                            </>
                        )}
                    </div>
                </button>
                <>
                    {!isSmall && (
                        <p className="m-0 mt-1 remotive-font-sm text-secondary">
                            Select some signals to visualize a time series graph
                        </p>
                    )}
                </>
            </div>
        )
    }

    const popoverSettingsItem = (title: string, actionElement: ReactElement) => {
        return (
            <div className="d-flex justify-content-between align-items-center rounded px-2 py-1 m-1 remotive-primary-20-background">
                <p className="m-0 remotive-font-sm lexend-bold">{title}</p>
                <div className="d-flex justify-content-center align-items-center flex-row align-items-center">
                    {actionElement}
                </div>
            </div>
        )
    }

    const settingsPopover = (
        <Popover
            id="popover-basic"
            className="border-2 remotive-primary-20-border remotive-primary-0-background shadow rounded-4 me-2"
            style={{ maxWidth: 400 }}
        >
            <Popover.Body style={{ zIndex: '0 !important', width: 300 }} className="p-3 lexend-regular">
                <div>
                    <div>
                        {popoverSettingsItem(
                            'Show Y-axis',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={showYAxis}
                                        disabled={
                                            componentState === ComponentState.LOADING || selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => setShowYAxis(e.target.checked)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {popoverSettingsItem(
                            'Show X-axis',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={showXAxis}
                                        disabled={
                                            componentState === ComponentState.LOADING || selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => setShowXAxis(e.target.checked)}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div>
                        {popoverSettingsItem(
                            'Show all annotations',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={showAllAnnotations}
                                        disabled={
                                            componentState === ComponentState.LOADING || selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => {
                                            setSelectedAnnotation(undefined)
                                            setShowAllAnnotations(e.target.checked)
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div>
                        {popoverSettingsItem(
                            'Show only my annotations',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={showOnlyMyAnnotations}
                                        disabled={
                                            componentState === ComponentState.LOADING || selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => {
                                            setSelectedAnnotation(undefined)
                                            setShowOnlyMyAnnotations(e.target.checked)
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {popoverSettingsItem(
                            'Use suggested min/max',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={useSuggestedMinMax}
                                        disabled={
                                            componentState === ComponentState.LOADING || selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => setUseSuggestedMinMax(e.target.checked)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    )

    const addAnnotationModeBanner = () => {
        return (
            <div className="d-flex justify-content-center" style={{ marginTop: 2, marginBottom: 1 }}>
                <div className="rounded-3 remotive-success-10-background p-2">
                    <div className="d-flex align-items-center ps-3 pe-1">
                        <SpeakerNotesRounded sx={{ fontSize: 17 }} className="remotive-success-60-color me-3" />
                        <p className="mb-0 remotive-font-md remotive-success-100-color">
                            You are in annotation mode, click or drag in the graph to create an annotation.
                        </p>
                        <button
                            onClick={() => {
                                setIsAddAnnotationActive(false)
                            }}
                            className="btn remotive-btn-no-bg p-0 m-0 ms-3 me-1 text-dark d-flex align-items-center"
                        >
                            <CloseIcon className="p-0 m-0" sx={{ fontSize: 17 }} />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {selectableSignals && selectableSignals.length > 0 && (
                <div className="p-2 h-100 w-100 pb-1">
                    <>
                        <div className="d-flex w-100 justify-content-between">
                            <div className="d-flex align-items-start">
                                <div className="d-flex align-items-center border-end">
                                    <div className="me-2">{closeButton()}</div>
                                    <div className="me-3">
                                        <p className="remotive-font-md lh-sm remotive-primary-60-color m-0">
                                            Signal Time Series
                                        </p>
                                    </div>
                                </div>
                                <div className="">{selectedSignals.length > 0 && toolbar()}</div>
                            </div>

                            <div className="d-flex">{settingsButton()}</div>
                        </div>
                        {isAddAnnotationActive ? (
                            addAnnotationModeBanner()
                        ) : (
                            <div className="d-flex justify-content-start flex-row flex-wrap">
                                {currentlySelectedSignals()}
                            </div>
                        )}
                    </>

                    {componentState === ComponentState.LOADING && <LoadingContainer spinnerSize="sm" />}
                    {componentState === ComponentState.ERROR && (
                        <ErrorContainer
                            errorSubText={'We encountered a problem while fetching signal data.'}
                            errorText={'Signal data error'}
                        />
                    )}
                    {componentState === ComponentState.DONE && (
                        <div className="container-fluid px-0">
                            {selectedSignals.length <= 0 && (
                                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                    {selectSignalsButton('lg')}
                                </div>
                            )}
                            <div className="row">
                                <TimeSeriesChart
                                    currentUser={props.currentUser}
                                    maxHeight={TIMESERIES_VISUALIZATION_HEIGHT}
                                    panelKey={props.panelKey}
                                    plottableSignalEntries={plottableSignalEntries}
                                    availableAnnotations={props.availableAnnotations}
                                    selectedSignals={selectedSignals}
                                    hiddenSignals={hiddenSignals}
                                    graphSettings={{
                                        showYAxis: showYAxis,
                                        showXAxis: showXAxis,
                                        showAllAnnotations: showAllAnnotations,
                                        showOnlyMyAnnotations: showOnlyMyAnnotations,
                                        useSuggestedMinMax: useSuggestedMinMax,
                                    }}
                                    setSelectedAnnotation={setSelectedAnnotation}
                                    selectedAnnotation={selectedAnnotation}
                                    isAddAnnotationActive={isAddAnnotationActive}
                                    setShowAddAnnotationModal={setShowAddAnnotationModal}
                                    setLastClickedAnnotationTimestamp={setLastClickedAnnotationTimestamp}
                                    setLastClickedAnnotationTimestampEnd={setLastClickedAnnotationTimestampEnd}
                                    sharedExtremes={props.sharedExtremes}
                                    setSharedExtrems={props.setSharedExtremes}
                                />
                                <AnnotationComponent
                                    className={`${
                                        selectedAnnotation === undefined ? '' : 'col-6 col-md-4 col-xxl-3 ps-1'
                                    }`}
                                    refreshAnnotation={async () => {
                                        const annotations = await props.safeFetchAndSetAvailableAnnotations()
                                        setSelectedAnnotation(
                                            annotations.find((it) => it.timestamp === selectedAnnotation?.timestamp)
                                        )
                                    }}
                                    currentUser={props.currentUser}
                                    currentProject={props.currentProject}
                                    currentRecordingSession={props.recordingSession}
                                    annotation={selectedAnnotation}
                                    maxHeight={TIMESERIES_VISUALIZATION_HEIGHT}
                                    onClose={() => setSelectedAnnotation(undefined)}
                                    onDelete={async () => {
                                        await props.safeFetchAndSetAvailableAnnotations()
                                        setSelectedAnnotation(undefined)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}

            <AddAnnotationModal
                show={showAddAnnotationModal}
                currentProject={props.currentProject}
                currentRecordingSession={props.recordingSession}
                annotationTimestamp={lastClickedAnnotationTimestamp}
                annotationTimestampEnd={lastClickedAnnotationTimestampEnd}
                handleCloseFunction={async () => {
                    setLastClickedAnnotationTimestamp(undefined)
                    setLastClickedAnnotationTimestampEnd(undefined)
                    setShowAddAnnotationModal(false)
                }}
                getAllAnnotations={() => props.safeFetchAndSetAvailableAnnotations()}
            />
            <SelectSignalsModal
                show={showSelectSignalsModal}
                selectableSignalsWithParentFrame={selectableSignals}
                selectedSignals={selectedSignals}
                handleCloseFunction={(numberOfSelectedSignals: number) => {
                    if (numberOfSelectedSignals <= 0) {
                        props.removeThisPanelFunction()
                    }
                    setShowSelectSignalsModal(false)
                }}
                selectSignalsFunction={setSelectedSignals}
            />
        </>
    )
}

enum ComponentState {
    LOADING,
    DONE,
    ERROR,
    UPLOADING,
    DELETING,
}
