import { NAVIGATION_TOP_BAR_ID } from '../components/navigation/NavigationBar'

export enum BootstrapBreakpoint {
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200,
    XXL = 1400,
}
export const ScreenSize = {
    isBootstrapXS() {
        return window.innerWidth < BootstrapBreakpoint.SM
    },

    isBootstrapSm() {
        return window.innerWidth >= BootstrapBreakpoint.SM && window.innerWidth < BootstrapBreakpoint.MD
    },

    isBootstrapMd() {
        return window.innerWidth >= BootstrapBreakpoint.MD && window.innerWidth < BootstrapBreakpoint.LG
    },

    isBootstrapLg() {
        return window.innerWidth >= BootstrapBreakpoint.LG && window.innerWidth < BootstrapBreakpoint.XL
    },

    isBootstrapXl() {
        return window.innerWidth >= BootstrapBreakpoint.XL && window.innerWidth < BootstrapBreakpoint.XXL
    },

    isBootstrapXxl() {
        return window.innerWidth >= BootstrapBreakpoint.XXL
    },

    isSmallerOrEqualTo(size: BootstrapBreakpoint) {
        return window.innerWidth <= size
    },

    isSmallerThan(size: BootstrapBreakpoint) {
        return window.innerWidth < size
    },

    isLargerThan(size: BootstrapBreakpoint) {
        return window.innerWidth > size
    },

    isLargerOrEqualTo(size: BootstrapBreakpoint) {
        return window.innerWidth >= size
    },

    getAllowedMaxWidth() {
        const topBar = document.getElementById(NAVIGATION_TOP_BAR_ID)
        if (topBar === null) {
            return document.documentElement.getBoundingClientRect().width
        }
        console.log(topBar.getBoundingClientRect().width)
        return topBar.getBoundingClientRect().width
    },
}