import { Chart } from 'highcharts'
import { RecordingAnnotation } from '../../../../api/CloudApi/types'

export const ANNOTATION_HEADER_RECT_KEY = 'annotationsHeader'

const annotationHeaderColor = (
    annotation: RecordingAnnotation,
    selectedAnnotation: RecordingAnnotation | undefined
) => {
    return annotation.id === selectedAnnotation?.id ? `#479e77` : `#3a5871`
}

const drawTab = (
    chart: Chart,
    annotationHeaderParentElement: any,
    xAxis: {
        from: number
        to: number
    },
    drawingArea: {
        lowerLimit: number
        upperLimit: number
    },
    isSelectedAnnotation: boolean,
    annotation: RecordingAnnotation,
    selectedAnnotation: RecordingAnnotation | undefined,
    onClick: Function
) => {
    destroyTabOnReRender(annotationHeaderParentElement, annotation)

    const TAB_HEIGHT = 17
    const TAB_WIDTH = 21
    const TAB_WIDTH_MODIFIER = Math.abs(annotation.comments.length).toString().length * 6
    const TAB_FULL_WIDTH = TAB_WIDTH + TAB_WIDTH_MODIFIER
    const shouldDisplayTab = xAxis.to - xAxis.from > TAB_FULL_WIDTH

    annotationHeaderParentElement[`${String(annotation.id)}-tab-start`] = chart.renderer
        .rect(xAxis.from - 1, drawingArea.upperLimit, shouldDisplayTab ? 10 : 2, TAB_HEIGHT, shouldDisplayTab ? 2 : 1) // Start drawing 1px before and make it 2px wider to account for the line width
        .attr({
            fill: annotationHeaderColor(annotation, selectedAnnotation),
            zIndex: isSelectedAnnotation ? 100 : 3, // Ensure it's on top of the grid
        })
        .on('click', () => onClick(annotation))
        .add()

    // @ts-ignore
    annotationHeaderParentElement[`${String(annotation.id)}-tab-stop`] = chart.renderer
        .rect(xAxis.from - 1, drawingArea.upperLimit, shouldDisplayTab ? TAB_FULL_WIDTH : 0, TAB_HEIGHT, 10) // Start drawing 1px before and make it 2px wider to account for the line width
        .attr({
            fill: annotationHeaderColor(annotation, selectedAnnotation),
            zIndex: isSelectedAnnotation ? 100 : 3, // Ensure it's on top of the grid
        })
        .on('click', () => onClick(annotation))
        .add()

    // @ts-ignore
    annotationHeaderParentElement[`${String(annotation.id)}-tab-icon`] = chart.renderer
        .path([
            ['M', 0.75, 0.75], // Move to (0.75, 0.75)
            ['H', 7.75], // Horizontal line to (7.75, 0.75)
            ['C', 8.02614, 0.75, 8.25, 0.973858, 8.25, 1.25], // Cubic curve to (8.25, 1.25)
            ['V', 5.75], // Vertical line to 5.75
            ['C', 8.25, 6.02614, 8.02614, 6.25, 7.75, 6.25], // Cubic curve to (7.75, 6.25)
            ['H', 3], // Horizontal line to (3, 6.25)
            ['L', 0.75, 7.75], // Line to (0.75, 7.75)
            ['V', 1.25], // Vertical line back to 1.25
            ['C', 0.75, 0.973858, 0.973858, 0.75, 1.25, 0.75], // Cubic curve
            ['Z'], // Close the path
        ])
        .translate(xAxis.from + 4 + TAB_WIDTH_MODIFIER, drawingArea.upperLimit + 4)
        .attr({
            fill: 'white',
            zIndex: isSelectedAnnotation ? 100 : 3, // Ensure it's on top of the grid
        })
        .on('click', () => onClick(annotation))
        .add()

    // @ts-ignore
    annotationHeaderParentElement[`${String(annotation.id)}-tab-text`] = chart.renderer
        .text(`${annotation.comments.length}`, xAxis.from + 2, drawingArea.upperLimit + 12)
        .addClass('remotive-font-xs')
        .attr({
            fill: 'white',
            zIndex: isSelectedAnnotation ? 100 : 3, // Ensure it's on top of the grid
        })
        .on('click', () => onClick(annotation))
        .add()
}

const destroyTabOnReRender = (annotationHeaderParentElement: any, annotation: RecordingAnnotation) => {
    const elementsToDestroy = [
        `${annotation.id}-tab-start`,
        `${annotation.id}-tab-stop`,
        `${annotation.id}-tab-text`,
        `${annotation.id}-tab-icon`,
    ]

    elementsToDestroy.forEach((key) => {
        const element = annotationHeaderParentElement[key]
        if (element) {
            element.destroy()
        }
    })
}

const drawBar = (
    chart: Chart,
    annotationHeaderParentElement: any,
    xAxis: {
        from: number
        to: number
    },
    drawingArea: {
        lowerLimit: number
        upperLimit: number
    },
    isSelectedAnnotation: boolean,
    annotation: RecordingAnnotation,
    selectedAnnotation: RecordingAnnotation | undefined,
    onClick: Function
) => {
    destroyBarOnReRender(annotationHeaderParentElement, annotation)

    annotationHeaderParentElement[`${String(annotation.id)}-bar`] = chart.renderer
        .rect(xAxis.from - 1, drawingArea.lowerLimit, xAxis.to - xAxis.from + 2, 5, 1) // Start drawing 1px before and make it 2px wider to account for the line width
        .attr({
            fill: annotationHeaderColor(annotation, selectedAnnotation),
            zIndex: isSelectedAnnotation ? 100 : 3, // Ensure it's on top of the grid
        })
        .on('click', () => onClick(annotation))
        .add()
}

const destroyBarOnReRender = (annotationHeaderParentElement: any, annotation: RecordingAnnotation) => {
    if (annotationHeaderParentElement[`${String(annotation.id)}-bar`]) {
        annotationHeaderParentElement[`${String(annotation.id)}-bar`].destroy()
    }
}

export const drawCompleteHeader = (
    chart: Chart,
    xAxis: {
        from: number
        to: number
    },
    drawingArea: {
        upperLimit: number
        lowerLimit: number
    },
    isSelectedAnnotation: boolean,
    annotation: RecordingAnnotation,
    selectedAnnotation: RecordingAnnotation | undefined,
    onClick: Function
): void => {
    // @ts-ignore
    const annotationHeaderParentElement = chart[ANNOTATION_HEADER_RECT_KEY]
    drawTab(
        chart,
        annotationHeaderParentElement,
        xAxis,
        drawingArea,
        isSelectedAnnotation,
        annotation,
        selectedAnnotation,
        onClick
    )
    drawBar(
        chart,
        annotationHeaderParentElement,
        xAxis,
        drawingArea,
        isSelectedAnnotation,
        annotation,
        selectedAnnotation,
        onClick
    )
}
