import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import {
    AuthenticatedUser,
    Project,
    RecordingFile,
    RecordingSession,
    UserBillableUnitInfo,
} from '../api/CloudApi/types'
import { ClickableArrowIcon, CopyIcon, DeleteIcon, LockedIcon } from '../assets/Icons'
import { formatAsDatetime } from '../utils/datetime'
import { Permission, hasPermission } from '../utils/permission'
import CloudApi from '../api/CloudApi'
import { ProjectRecordingRoutes, ProjectRoutes } from '../Routes'
import { formattedToastMessage } from '../utils/toast'
import ConfirmDialog from './modals/ConfirmDialog'
import {
    ProductAnalyticsContext,
    ProductAnalyticsProperties,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../utils/ProductAnalytics'

interface RecordingListItemProps {
    isRecentlyUploaded: boolean
    recording: RecordingSession
    isLocked: boolean
    project: Project
    billableUnit: UserBillableUnitInfo
    productAnalyticsProperties: ProductAnalyticsProperties
    pageStateFunction?: Function
    recordingStateFunction?: Function
    recordingSessionToCopyFunction?: Function
    useProjectNameInsteadOfFilesLength?: boolean
}

enum ComponentState {
    DELETING,
    DONE,
}

export default function RecordingListItem(props: RecordingListItemProps) {
    const [showDeleteFileConfirmDialog, setShowDeleteFileConfirmDialog] = useState<boolean>(false)
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DONE)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.productAnalyticsProperties?.currentUser,
        billableUnit: props.productAnalyticsProperties?.currentBillableUnit,
    } as ProductAnalyticsProps)

    const AnalyticsActions = {
        DELETE_RECORDING: createAnalyticsTrackingKey(props.productAnalyticsProperties.productAnalyticsContext, 'DeleteRecording'),
        COPY_RECORDING: createAnalyticsTrackingKey(props.productAnalyticsProperties.productAnalyticsContext, 'CopyRecording'),
    }

    const getSubtitle = () => {
        return props.useProjectNameInsteadOfFilesLength ? (
            <>
                In project <b>{props.project.displayName}</b>
            </>
        ) : (
            `${props.recording.recordings.length} file(s)`
        )
    }

    const deleteRecordingSession = async (recording: RecordingSession, recordingStateFunction: Function) => {
        setComponentState(ComponentState.DELETING)
        try {
            await CloudApi.deleteRecordingSession(props.project.uid, recording.sessionId)
            recordingStateFunction()
        } catch (err: any) {
            toast.error(formattedToastMessage('Error', 'Failed to delete recording session.'))
            setComponentState(ComponentState.DONE)
        }
    }

    const askToDeleteRecordingSession = async () => {
        setShowDeleteFileConfirmDialog(true)
    }

    const recordingListItem = (
        recording: RecordingSession,
        isLocked: boolean,
        project: Project,
        billableUnit: UserBillableUnitInfo
    ) => {
        return (
            <div className="d-flex align-items-center mb-1">
                <div className="col-7 col-md-8 p-1 border-end d-flex align-items-center justify-content-between">
                    <NavLink
                        reloadDocument={props.useProjectNameInsteadOfFilesLength}
                        to={isLocked ? '' : ProjectRecordingRoutes.toRecordingDetails(project.uid, recording.sessionId)}
                        className={
                            'w-100 text-dark text-decoration-none remotive-btn-no-bg d-flex align-items-center justify-content-between  text-truncate'
                        }
                    >
                        <div className="text-truncate">
                            <p className="remotive-font-md mb-0 text-truncate">{recording.displayName}</p>
                            <p className="remotive-font-sm text-secondary mb-0 text-truncate">{getSubtitle()}</p>
                        </div>
                        <div className="d-flex">
                            {isLocked && <LockedIcon className="mx-1" />}
                            {props.isRecentlyUploaded && (
                                <div className="rounded-5 p-1 remotive-success-30-background">
                                    <p className="remotive-font-xs m-0 px-2 remotive-success-100-color">
                                        Recently uploaded
                                    </p>
                                </div>
                            )}
                            <ClickableArrowIcon className="ms-2" />
                        </div>
                    </NavLink>
                </div>
                <div className="col-3 col-md-2 p-1 px-2 text-start">
                    <p className="m-0 remotive-font-md text-truncate">Uploaded</p>
                    <p className="remotive-font-sm text-secondary mb-0 text-truncate">
                        {formatAsDatetime(recording.uploaded)}
                    </p>
                </div>
                <div className="w-100 px-1 d-flex justify-content-end  align-items-center">
                    {props.pageStateFunction !== undefined && props.recordingStateFunction !== undefined && (
                        <button
                            className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                            disabled={!hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project)}
                            onClick={() => {
                                productAnalyticsClient.track(AnalyticsActions.DELETE_RECORDING)
                                askToDeleteRecordingSession()
                            }}
                        >
                            {componentState === ComponentState.DELETING ? (
                                <Spinner size="sm" className="me-1" />
                            ) : (
                                <DeleteIcon sx={{ fontSize: 20 }} />
                            )}
                        </button>
                    )}
                    {props.recordingSessionToCopyFunction !== undefined && (
                        <button
                            className={'ms-1 btn remotive-btn-no-bg p-1 px-0 border-0'}
                            disabled={!hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project)}
                            onClick={() => {
                                productAnalyticsClient.track(AnalyticsActions.COPY_RECORDING)
                                props.recordingSessionToCopyFunction!(recording.sessionId)
                            }}
                        >
                            <CopyIcon sx={{ fontSize: 20 }} />
                        </button>
                    )}
                </div>
            </div>
        )
    }

    return (
        <>
            {recordingListItem(props.recording, props.isLocked, props.project, props.billableUnit)}
            <ConfirmDialog
                bodyText={
                    <>
                        Are you sure you want to delete the recording session <b>{props.recording.displayName}</b> with
                        session id <b>{props.recording.sessionId}</b>?
                    </>
                }
                bodySubtitle={`Everything related to this recording session will be deleted.`}
                cancelButtonText="No, cancel"
                confirmButtonText="Yes, delete it"
                handleCancelFunction={() => console.log('User cancelled recording session item deletion')}
                handleCloseFunction={() => setShowDeleteFileConfirmDialog(false)}
                handleConfirmFunction={() => deleteRecordingSession(props.recording, props.recordingStateFunction!)}
                show={showDeleteFileConfirmDialog}
            />
        </>
    )
}
