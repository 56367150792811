export function formatNumberWithMetricPrefix(number: number): string {
    if (number < 1000) {
        return `${number}`
    }

    const units = ['', 'K', 'M', 'B', 'T']
    let unitIndex = 0
    let num = number

    while (num >= 1000 && unitIndex < units.length - 1) {
        num /= 1000
        unitIndex++
    }

    return `${(num ?? 0).toFixed(2)}${units[unitIndex]}`
}
