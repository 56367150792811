import { InfoIcon } from '../../assets/Icons'

interface InfoBoxProps {
    message: JSX.Element
}

export default function InfoBox(props: InfoBoxProps) {
    return (
        <>
            <div className="d-flex justify-content-start mb-3 mt-2 rounded-4 remotive-success-10-background p-3 ">
                <div
                    style={{ marginTop: 12, marginRight: 12, zIndex: 0 }}
                    className="p-1 end-0 top-0 position-absolute d-flex remotive-success-10-background rounded-5"
                >
                    <InfoIcon className="remotive-success-40-color" sx={{ fontSize: 25 }} />
                </div>
                <div style={{ zIndex: 1 }}>
                    <p className="mb-1 text-dark remotive-font-md">{props.message}</p>
                </div>
            </div>
        </>
    )
}
