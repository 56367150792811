export const COMMON_COMPRESSED_FILE_TYPES: string[] = [
    '.zip', // ZIP archive
    '.rar', // RAR archive
    '.7z', // 7-Zip archive
    '.tar', // TAR archive
    '.gz', // Gzip compressed file
    '.tgz', // Gzip compressed TAR archive
    '.bz2', // Bzip2 compressed file
    '.xz', // XZ compressed file
    '.lz', // Lzip compressed file
    '.lzma', // LZMA compressed file
    '.cab', // Cabinet file
    '.iso', // ISO disk image
    '.dmg', // Apple Disk Image
    '.apk', // Android Package
    '.jar', // Java Archive
    '.war', // Web Application Archive
    '.ear', // Enterprise Application Archive
    '.tar.gz', // Gzip compressed TAR archive
    '.tar.bz2', // Bzip2 compressed TAR archive
    '.tar.xz', // XZ compressed TAR archive
    '.z', // Z compressed file
    '.s7z', // 7-Zip self-extracting archive
    '.arj', // ARJ compressed file
    '.deb', // Debian software package
    '.rpm', // Red Hat Package Manager file
]

export const COMMON_TEXT_FILE_TYPES: string[] = [
    '.txt', // Plain text file
    '.md', // Markdown file
    '.html', // HTML file
    '.htm', // HTML file
    '.xml', // XML file
    '.json', // JSON file
    '.yaml', // YAML file
    '.yml', // YAML file
    '.csv', // CSV file
    '.log', // Log file
    '.rtf', // Rich Text Format file
    '.tex', // LaTeX file
    '.ini', // INI configuration file
    '.cfg', // Configuration file
    '.conf', // Configuration file
    '.properties', // Properties file
    '.bat', // Batch file
    '.sh', // Shell script file
    '.sql', // SQL file
    '.tsv', // Tab-separated values file
    '.scss', // SASS CSS file
    '.css', // CSS file
    '.js', // JavaScript file
    '.jsx', // JavaScript XML file
    '.ts', // TypeScript file
    '.tsx', // TypeScript XML file
    '.pdf', // PDF files
]

export const COMMON_IMAGE_FILE_TYPES: string[] = [
    '.jpg', // JPEG image
    '.jpeg', // JPEG image
    '.png', // PNG image
    '.gif', // GIF image
    '.bmp', // Bitmap image
    '.tiff', // TIFF image
    '.tif', // TIFF image
    '.webp', // WebP image
    '.svg', // SVG image
    '.ico', // Icon image
    '.heif', // High Efficiency Image Format
    '.heic', // High Efficiency Image Coding
    '.raw', // Raw image
    '.psd', // Photoshop document
    '.ai', // Adobe Illustrator document
    '.eps', // Encapsulated PostScript
    '.indd', // Adobe InDesign document
    '.jfif', // JPEG File Interchange Format
    '.jp2', // JPEG 2000 image
    '.apng', // Animated PNG
    '.avif', // AV1 Image File Format
]

export const COMMON_VIDEO_FILE_TYPES: string[] = [
    '.mp4', // MPEG-4 Video
    '.avi', // Audio Video Interleave
    '.mov', // QuickTime Movie
    '.wmv', // Windows Media Video
    '.flv', // Flash Video
    '.mkv', // Matroska Video
    '.webm', // WebM Video
    '.mpeg', // MPEG Video
    '.mpg', // MPEG Video
    '.m4v', // MPEG-4 Video
    '.3gp', // 3GPP Multimedia File
    '.3g2', // 3GPP2 Multimedia File
    '.f4v', // Flash MP4 Video
    '.f4p', // Flash MP4 Video
    '.f4a', // Flash MP4 Audio
    '.f4b', // Flash MP4 Audio
    '.ogv', // Ogg Video
    '.rm', // RealMedia
    '.rmvb', // RealMedia Variable Bitrate
    '.ts', // Transport Stream
    '.vob', // DVD Video Object
    '.mpe', // MPEG Video
    '.mxf', // Material Exchange Format
    '.dv', // Digital Video
    '.asf', // Advanced Systems Format
    '.mts', // AVCHD Video
    '.m2ts', // Blu-ray BDAV Video
]

export const COMMON_SOUND_FILE_TYPES: string[] = [
    '.mp3', // MP3 audio
    '.wav', // Waveform Audio File Format
    '.aac', // Advanced Audio Coding
    '.flac', // Free Lossless Audio Codec
    '.ogg', // Ogg Vorbis
    '.wma', // Windows Media Audio
    '.alac', // Apple Lossless Audio Codec
    '.aiff', // Audio Interchange File Format
    '.aif', // Audio Interchange File Format
    '.aifc', // Audio Interchange File Format
    '.m4a', // MPEG-4 Audio
    '.mp2', // MPEG-1 Audio Layer II
    '.mka', // Matroska Audio
    '.opus', // Opus audio
    '.ra', // RealAudio
    '.ram', // RealAudio Metadata
    '.au', // Audio file format
    '.dts', // Digital Theater Systems
    '.ac3', // Audio Codec 3
    '.amr', // Adaptive Multi-Rate
    '.mid', // MIDI audio
    '.midi', // MIDI audio
    '.rmi', // RMID audio
    '.voc', // Creative Voice file
    '.vqf', // TwinVQ audio
    '.caf', // Core Audio Format
    '.oga', // Ogg audio
    '.spx', // Speex audio
    '.gsm', // GSM 6.10 audio
]
