import { useState, useEffect } from 'react'
import { Modal, InputGroup, Form } from 'react-bootstrap'
import AnnotationComponent from '../AnnotationContainer'
import {
    AuthenticatedUser,
    FrameEntry,
    Project,
    RecordingAnnotation,
    RecordingSession,
} from '../../../../api/CloudApi/types'
import { CloseIcon, CrossIcon } from '../../../../assets/Icons'

interface AnnotationComponentAsModal {
    show: boolean
    closeModal: () => void
    selectedAnnotation: RecordingAnnotation | undefined
    currentUser: AuthenticatedUser | undefined
    currentProject: Project | undefined
    currentRecordingSession: RecordingSession | undefined
    refreshAnnotation: Function
    onDelete: Function
}

export default function AnnotationComponentAsModal(props: AnnotationComponentAsModal) {
    return (
        <>
            <Modal centered className="col-12 lexend-regular" show={props.show} onHide={props.closeModal}>
                <Modal.Body className="pt-2 pb-4 pe-5 ps-2 mt-2 mb-2 me-1">
                    <div className="d-flex flex-row">
                        <div className="d-flex justify-content-start align-items-start pt-1">
                            <button onClick={() => props.closeModal()} className="btn remotive-btn-no-bg m-0 p-0">
                                <CrossIcon />
                            </button>
                        </div>
                        <AnnotationComponent
                            className="col-12"
                            refreshAnnotation={props.refreshAnnotation}
                            currentUser={props.currentUser}
                            currentProject={props.currentProject}
                            currentRecordingSession={props.currentRecordingSession}
                            annotation={props.selectedAnnotation}
                            maxHeight={400}
                            commentMaxWidth={330}
                            titleOffset={-40}
                            onClose={undefined}
                            onDelete={props.onDelete}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
