export const BROKER_URL_LOCAL_STORAGE_KEY = 'brokerUrlKey'
export const BROKER_API_KEY_LOCAL_STORAGE_KEY = 'brokerApiKey'

export function getUserAuthToken() {
    return getCookie('auth')
}

function getCookie(name: string) {
    return document.cookie
        .split(';')
        .map((row) => row.trim())
        .find((row) => row.startsWith(`${name}=`))
        ?.split('=')[1]
}

export function deletePlaybackBrokerUrl() {
    return localStorage.removeItem(BROKER_URL_LOCAL_STORAGE_KEY)
}

export function setPlaybackBrokerUrl(brokerUrl?: string | null) {
    if (brokerUrl) {
        return localStorage.setItem(BROKER_URL_LOCAL_STORAGE_KEY, brokerUrl)
    }
}

export function getPlaybackBrokerUrl(): string | undefined {
    return localStorage.getItem(BROKER_URL_LOCAL_STORAGE_KEY) ?? undefined
}

export function getBrokerDetails() {
    return {
        brokerUrl: getPlaybackBrokerUrl(),
        brokerAccessToken: getUserAuthToken(),
    }
}
