import { useState, useEffect } from 'react'
import { Modal, InputGroup, Form } from 'react-bootstrap'
import { FrameEntry, SignalEntry } from '../../../../api/CloudApi/types'
import SignalItem from './SignalItem'
import WarningRounded from '@mui/icons-material/WarningRounded'
import CloseRounded from '@mui/icons-material/CloseRounded'
import { PageState } from '../../../../types/PageState'
import LoadingContainer from '../../../../components/LoadingContainer'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../../utils/toast'
import { Pagination } from './Pagination'
import { BootstrapBreakpoint, ScreenSize } from '../../../../utils/ScreenSize'

interface SelectSignalsModalProps {
    show: boolean
    title?: string
    signalLimit?: number
    selectableSignalsWithParentFrame: Array<FrameEntry>
    selectedSignals: Array<SignalEntry>
    handleCloseFunction: (numberOfSelectedSignals: number) => void
    selectSignalsFunction: (signals: Array<SignalEntry>) => void
}

const DEFAULT_SELECTED_SIGNALS_LIMIT = 6

const ITEMS_PER_PAGE = 20

export default function SelectSignalsModal(props: SelectSignalsModalProps) {
    const [signalsFilteredBySearch, setSignalsFilteredBySearch] = useState<Array<FrameEntry> | undefined>(undefined)
    const [selectedButNotAppliedSignals, setSelectedButNotAppliedSignals] = useState<Array<SignalEntry>>([])
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        console.log('Mounting select signals modal!')
    }, [])

    useEffect(() => {
        if (props.show) {
            setCurrentPage(1)
            setSelectedButNotAppliedSignals(props.selectedSignals)
            setSignalsFilteredBySearch(props.selectableSignalsWithParentFrame)
        }
    }, [props.show, props.selectedSignals, props.selectableSignalsWithParentFrame])

    const totalPages = signalsFilteredBySearch ? Math.ceil(signalsFilteredBySearch.length / ITEMS_PER_PAGE) : 1

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage)
        }
    }

    const paginatedFrameEntries = signalsFilteredBySearch?.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    )

    const searchOnChange = (event: any) => {
        const searchKeyInLowerCase = new String(event.target.value).toLowerCase()
        if (searchKeyInLowerCase.length >= 3) {
            const frameAfterSearch: Array<FrameEntry> = props.selectableSignalsWithParentFrame
                .map((frame) => {
                    if (
                        frame.name.toLowerCase().includes(searchKeyInLowerCase) ||
                        frame.namespace.toLowerCase().includes(searchKeyInLowerCase) ||
                        frame.id.toString().includes(searchKeyInLowerCase) ||
                        frame.comments?.toLowerCase().includes(searchKeyInLowerCase)
                    ) {
                        // // If frame name contains search key, return frame + all signals
                        return frame
                    } else {
                        // If frame doesn't contain search key, filter containing signals on search key and then return frame + signals
                        const signalsAfterNameSearch = frame.signals.filter((signal) =>
                            signal.name.toLowerCase().includes(searchKeyInLowerCase)
                        )
                        if (signalsAfterNameSearch.length > 0) {
                            return { ...frame, signals: signalsAfterNameSearch } as FrameEntry
                        }

                        // If signal doesn't contain the searh key, check description
                        const signalsAfterDescriptionSearch = frame.signals.filter((signal) => {
                            return signal.comments?.toLowerCase().includes(searchKeyInLowerCase)
                        })
                        if (signalsAfterDescriptionSearch.length > 0) {
                            return { ...frame, signals: signalsAfterDescriptionSearch } as FrameEntry
                        }
                    }
                    // No match
                    return undefined
                })
                .filter((frame) => frame !== undefined) as Array<FrameEntry>
            setCurrentPage(1)
            setSignalsFilteredBySearch(frameAfterSearch)
        }
        if (searchKeyInLowerCase.length === 0) {
            setSignalsFilteredBySearch(props.selectableSignalsWithParentFrame)
        }
    }

    const createSignalList = (
        namespace: string,
        frameParent: string,
        signals: Array<SignalEntry>,
        isDisabled: boolean
    ) => {
        return signals.map((signal) => (
            <div key={`${signal.namespace}-${frameParent}-${signal.name}::parent`}>
                <div className="ms-2">
                    <SignalItem
                        thisSignalEntry={signal}
                        selectedSignals={selectedButNotAppliedSignals}
                        removeSelectedSignalFunction={removeSelectedButNotAppliedSignal}
                        addSelectedSignalFunction={addSelectedButNotAppliedSignal}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
        ))
    }

    const createFrameAndSignalsList = () => {
        if (paginatedFrameEntries && paginatedFrameEntries.length > 0) {
            return paginatedFrameEntries
                .sort((a, b) => {
                    if (a.cnt === 0 && b.cnt !== 0) return 1
                    if (a.cnt !== 0 && b.cnt === 0) return -1
                    return a.name.localeCompare(b.name)
                })
                .map((frame) => {
                    return (
                        <div key={`${frame.namespace}-${frame.name}-list-signal`} className="my-3 text-truncate">
                            {frameAndSignalItem(frame)}
                        </div>
                    )
                })
        }
        if (signalsFilteredBySearch !== undefined) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <WarningRounded sx={{ fontSize: 20 }} className="text-warning me-2" />
                    <p className="my-5 remotive-font">No signals in this recording match the current query</p>
                </div>
            )
        }
        return (
            <>
                <LoadingContainer spinnerSize="sm" />
            </>
        )
    }

    const frameAndSignalItem = (frameEntry: FrameEntry) => {
        // We should disable signal picking for two reasons, no signals where
        // recorded or the frame does not have any numeric signals to graph
        const isDisabled = frameEntry.cnt <= 0 || !frameEntry.hasNumericSignals

        return (
            <>
                <div className="remotive-font-md d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <p title="Frame ID and Frame name" className="m-0 lexend-bold">
                            {`${frameEntry.id} ${frameEntry.name}`}{' '}
                        </p>
                        <p title="Namespace" className="m-0 ms-2 text-secondary remotive-font-sm">
                            {`${frameEntry.namespace}`}{' '}
                        </p>
                    </div>

                    <div className="m-0 text-secondary remotive-font-sm flex-truncate">
                        {isDisabled && (
                            <div className="d-flex align-items-center ms-3 flex-truncate">
                                <WarningRounded className="text-warning me-1" sx={{ fontSize: 12 }} />
                                <p className="m-0 text-truncate">
                                    {frameEntry.cnt > 0 && <>This frame does not have any numeric signals to show</>}
                                    {frameEntry.cnt <= 0 && <>This frame is not present in this recording</>}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-row align-items-end lh-1">
                    <p title="Senders" className="m-0 remotive-font-xs remotive-primary-50-color">
                        {frameEntry.senders
                            ? frameEntry.senders.map((it, index) =>
                                  index === frameEntry.senders.length - 1 ? it : `${it}, `
                              )
                            : 'N/A'}
                    </p>
                    <p title="Receivers" className="m-0 ms-4 remotive-font-xs remotive-primary-50-color">
                        {frameEntry.receivers
                            ? frameEntry.receivers.map((it, index) =>
                                  index === frameEntry.receivers.length - 1 ? it : `${it}, `
                              )
                            : 'N/A'}
                    </p>
                    <p title="Cycle Time" className="m-0 ms-4 remotive-font-xs remotive-primary-50-color">
                        {frameEntry.cycleTime ? `${frameEntry.cycleTime}ms` : 'N/A'}
                    </p>
                </div>
                <p className="m-0 remotive-font-sm text-secondary">{frameEntry.comments}</p>
                {createSignalList(frameEntry.namespace, frameEntry.name, frameEntry.signals, isDisabled)}
            </>
        )
    }

    const closeModal = () => {
        props.handleCloseFunction(selectedButNotAppliedSignals.length)
        setSignalsFilteredBySearch(props.selectableSignalsWithParentFrame)
    }

    const createPanels = () => {
        props.selectSignalsFunction(selectedButNotAppliedSignals)
        closeModal()
    }

    const addSelectedButNotAppliedSignal = (signalEntry: SignalEntry) => {
        const limit = props.signalLimit ?? DEFAULT_SELECTED_SIGNALS_LIMIT
        if (selectedButNotAppliedSignals.length < limit) {
            setSelectedButNotAppliedSignals([...selectedButNotAppliedSignals, signalEntry])
        } else {
            toast.error(
                formattedToastMessage(
                    'Signal limit reached',
                    `You can not have more than ${limit} signal(s) select. Add another panel to visualize more signal(s).`
                ),
                { autoClose: 10_000 }
            )
        }
    }

    const removeSelectedButNotAppliedSignal = (signalEntry: SignalEntry) => {
        setSelectedButNotAppliedSignals(
            selectedButNotAppliedSignals.filter(
                (it) => constructSignalNameKeyFromEntry(it) !== constructSignalNameKeyFromEntry(signalEntry)
            )
        )
    }

    const constructSignalNameKeyFromEntry = (signalEntry: SignalEntry) => {
        return constructSignalNameKey(signalEntry.name, signalEntry.frameName)
    }

    const constructSignalNameKey = (signalName: string, frameName: string) => {
        const name = `${frameName}::${signalName}`.toLowerCase()
        console.log(name)
        return name
    }

    const currentlySelectedSignals = () => {
        return selectedButNotAppliedSignals.map((signal) => (
            <div key={`${signal.namespace}-${signal.frameName}-${signal.name}`} className="me-1 mt-1">
                <div className="rounded remotive-primary-10-background p-1 px-2">
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="m-0 ms-1 remotive-font-sm">
                                {signal.name}{' '}
                                <span className="remotive-primary-60-color ms-1 remotive-font-xs">{`${
                                    signal.unit ?? 'unitless'
                                }`}</span>
                            </p>
                            <div>
                                <p className="m-0 remotive-font-xs ms-1 text-secondary">{signal.namespace}</p>
                            </div>
                            <button
                                className="ms-2 btn remotive-btn-sm m-0 p-0 remotive-btn-no-bg"
                                onClick={() => removeSelectedButNotAppliedSignal(signal)}
                            >
                                <CloseRounded sx={{ fontSize: 20 }} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ))
    }

    return (
        <>
            <>
                <Modal size="xl" centered className="col-12 lexend-regular" show={props.show} onHide={closeModal}>
                    <Modal.Body className="pb-0">
                        <div className="mb-2">
                            <InputGroup>
                                <Form.Control
                                    autoFocus={props.show}
                                    placeholder={`Search, e.g ${
                                        props.selectableSignalsWithParentFrame?.[0]?.name ?? 'Speed'
                                    }`}
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    onChange={searchOnChange}
                                />
                            </InputGroup>
                            <p className="m-0 ms-1 remotive-font-xs text-secondary">{`Showing ${
                                signalsFilteredBySearch
                                    ?.sort((a, b) => {
                                        const aIsNonNumericOrEmpty = a.cnt === 0 || a.hasNumericSignals === false
                                        const bIsNonNumericOrEmpty = b.cnt === 0 || b.hasNumericSignals === false

                                        if (aIsNonNumericOrEmpty && !bIsNonNumericOrEmpty) return 1 // a goes after b if a is non-numeric or empty
                                        if (!aIsNonNumericOrEmpty && bIsNonNumericOrEmpty) return -1 // b goes after a if b is non-numeric or empty
                                        return a.name.localeCompare(b.name) // Otherwise, sort alphabetically by name
                                    })
                                    .map((it) => it.signals.length)
                                    .reduce((accumulator, current) => accumulator + current, 0) ?? 0
                            } of ${props.selectableSignalsWithParentFrame
                                .map((it) => it.signals.length)
                                .reduce((accumulator, current) => accumulator + current, 0)} results`}</p>
                        </div>
                        <Form>
                            <div className="row">
                                <div className="m-0 col-6 mb-1">
                                    <div
                                        title="Information about Senders, Receivers and Cycle time is displayed below each frame name."
                                        className="d-flex align-items-end"
                                    >
                                        <p className="m-0 lexend-bold remotive-font">Name</p>
                                        <p className="m-0 ms-2 remotive-font-sm text-secondary">Namespace</p>
                                    </div>
                                </div>
                                <div className="m-0 col-2 mb-1 d-flex flex-column align-items-start">
                                    <p className="m-0 lexend-bold remotive-font">Unit</p>
                                </div>
                                <div className="m-0 col-2 mb-1 d-flex flex-column align-items-start">
                                    <p className="m-0 lexend-bold remotive-font">Length</p>
                                </div>
                                <div className="m-0 col-2 mb-1 d-flex flex-column align-items-start">
                                    <p className="m-0 lexend-bold remotive-font">Min - max</p>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="w-100 border-bottom border-top py-1"
                                    style={{
                                        overflowY: 'scroll',
                                        height: ScreenSize.isSmallerOrEqualTo(BootstrapBreakpoint.LG) ? '45vh' : '58vh',
                                    }}
                                >
                                    {createFrameAndSignalsList()}
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                    <div className="border-0 p-3 text-start">
                        {/* We could tell the user about cost here */}
                        <div className="d-flex justify-content-center align-items-center">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                itemsPerPage={ITEMS_PER_PAGE}
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <p className="m-0 lexend-bold remotive-font-sm">Selected signals:</p>
                                <div style={{ height: 75 }} className="overflow-y-auto d-flex flex-wrap text-truncate">
                                    {currentlySelectedSignals()}
                                </div>
                            </div>

                            <div className="d-flex align-self-end ms-3 d-none d-lg-flex">
                                <button className="btn remotive-btn remotive-btn-primary me-3" onClick={closeModal}>
                                    Cancel
                                </button>
                                <button className="btn remotive-btn remotive-btn-success" onClick={createPanels}>
                                    Apply
                                </button>
                            </div>
                        </div>
                        <div className="d-lg-none">
                            <div className="d-flex justify-content-center ms-3 mt-2">
                                <button className="btn remotive-btn remotive-btn-primary me-3" onClick={closeModal}>
                                    Cancel
                                </button>
                                <button className="btn remotive-btn remotive-btn-success" onClick={createPanels}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        </>
    )
}
