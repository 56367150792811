import { Accordion, Button, Card, Dropdown, Form, Spinner } from 'react-bootstrap'
import {
    AuthenticatedUser,
    BrokerConfigurationFolder,
    BrokerContainer,
    Project,
    RecordingFile,
    RecordingSession,
    UserBillableUnitInfo,
} from '../../../api/CloudApi/types'
import { toast } from 'react-toastify'
import { ArrowNorthIcon, ArrowSouthIcon, DropdownIcon, DropupIcon, PrepareRecordingIcon } from '../../../assets/Icons'
import { useEffect, useRef, useState } from 'react'
import ApplyToBrokerModal from '../../../components/modals/ApplyToBrokerModal'
import CliHintContainer, { CliHint } from '../../../components/CliHintContainer'
import BrokerAppWithMountedRecording from '../../../components/BrokerAppWithMountedRecording'
import { ProductAnalyticsContext } from '../../../utils/ProductAnalytics'
import { UserRecordingSessionContext } from 'src/types/Context'

interface PlaybackTabProps {
    userRecordingSessionContext: UserRecordingSessionContext
    selectedRecordingFiles: RecordingFile[] | undefined
    setSelectedRecordingFiles: (recordingFiles: RecordingFile[] | undefined) => void
    showDemoModal: boolean
    setShowDemoModal: (newValue: boolean) => void
    showHelpModal: boolean
    setShowHelpModal: (newValue: boolean) => void
    brokers: Array<BrokerContainer> | undefined
    refreshBrokers: () => void
}

export function PlaybackTab(props: PlaybackTabProps) {
    const [showApplyToBrokerModal, setShowApplyToBrokerModal] = useState(false)
    const allowSelectBroker = useRef<boolean>(false)
    const [showPlayDropdown, setShowPlayDropdown] = useState(false)
    const [selectedBrokerConfig, setSelectedBrokerConfig] = useState<BrokerConfigurationFolder>()
    const [brokerContainer, setBrokerContainer] = useState<BrokerContainer>()
    const [isPlaybackAccordionCollapsed, setIsPlaybackAccordionCollapsed] = useState<boolean>(false)

    useEffect(() => {
        setIsPlaybackAccordionCollapsed(brokerContainer !== undefined)
    }, [brokerContainer])

    const HIGHLIGHT_DEMO_OBJECT_STYLE = props.showDemoModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}
    const HIGHLIGHT_HELP_OBJECT_STYLE = props.showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}

    const DEFAULT_AUTOGENERATED_BROKER_CONFIG_NAME = 'default'

    const playOptionDropdown = () => {
        return (
            <>
                <Dropdown className="d-inline-block" show={showPlayDropdown}>
                    <Dropdown.Menu style={{ right: 0 }} className="py-2 remotive-dropdown-light border-0 shadow">
                        <Dropdown.Item
                            as={Button}
                            className="m-0 py-0 px-3 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-center"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                allowSelectBroker.current = true
                                applyRecordingSessionToBroker()
                            }}
                        >
                            Select broker
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    const applyRecordingSessionToBroker = () => {
        props.setShowHelpModal(false)
        props.setShowDemoModal(false)
        setBrokerContainer(undefined)
        setShowApplyToBrokerModal(true)
    }

    const selectableConfigFileListItem = (
        name: string,
        description: string,
        isChecked: boolean,
        onClickFunction: Function
    ) => {
        return (
            <div key={name} className={`d-flex text-truncate m-1`}>
                <div className="d-flex px-2 p-1 rounded remotive-primary-10-background align-items-center">
                    <Form.Check
                        checked={isChecked}
                        onChange={() => onClickFunction()}
                        className="remotive-font-md"
                        style={{ paddingTop: '2px' }}
                        type="radio"
                        label={name}
                    ></Form.Check>
                    <div className="px-2">
                        <p className="m-0 text-secondary remotive-font-sm">{description}</p>
                    </div>
                </div>
            </div>
        )
    }

    const selectableConfigFileList = () => {
        const configFiles =
            props.userRecordingSessionContext.currentRecordingSession?.brokerConfigurations
                .sort((folder1, folder2) => (folder1.name === DEFAULT_AUTOGENERATED_BROKER_CONFIG_NAME ? -1 : 1))
                .map((folder) => {
                    const name =
                        folder.name === DEFAULT_AUTOGENERATED_BROKER_CONFIG_NAME ? 'No transformation' : folder.name
                    const desc =
                        folder.name === DEFAULT_AUTOGENERATED_BROKER_CONFIG_NAME ? '' : `${folder.files.length} file(s)`

                    // TODO - Refactor so this is understandable
                    const selection =
                        folder.name === DEFAULT_AUTOGENERATED_BROKER_CONFIG_NAME
                            ? selectedBrokerConfig
                                ? selectedBrokerConfig?.name === DEFAULT_AUTOGENERATED_BROKER_CONFIG_NAME
                                : true
                            : selectedBrokerConfig?.name === folder.name

                    return selectableConfigFileListItem(name, desc, selection, () => {
                        setSelectedBrokerConfig(folder)
                    })
                }) || []
        return <div className="d-flex flex-wrap">{configFiles}</div>
    }

    const selectableRecordingFileList = () => {
        const recordingFiles = props.userRecordingSessionContext.currentRecordingSession?.recordings.map((recordingFile) =>
            selectableRecordingFileListItem(recordingFile)
        )
        return <div className="d-flex flex-wrap">{recordingFiles}</div>
    }

    const selectableRecordingFileListItem = (recordingFile: RecordingFile) => {
        const isChecked =
            props.selectedRecordingFiles?.find((file) => recordingFile.fileName === file.fileName) !== undefined
        return (
            <div key={recordingFile.fileName} className={`d-flex flex-truncate m-1`}>
                <div className="d-flex px-2 p-1 rounded remotive-primary-10-background flex-truncate align-items-center">
                    <div className="d-flex flex-row align-items-center flex-truncate">
                        <Form.Check
                            checked={isChecked}
                            disabled={true}
                            onChange={() => {
                                toast.warn('We only support playing all files at the moment...')
                                // We don't support selecting files yet, all files will be played by default
                                if (isChecked) {
                                    props.setSelectedRecordingFiles(
                                        props.selectedRecordingFiles?.filter(
                                            (file) => file.fileName !== recordingFile.fileName
                                        )
                                    )
                                } else {
                                    props.setSelectedRecordingFiles([
                                        ...(props.selectedRecordingFiles || []),
                                        recordingFile,
                                    ])
                                }
                            }}
                            className="remotive-font-md pe-2"
                            style={{ paddingTop: '2px' }}
                        ></Form.Check>
                        <p className="m-0 remotive-font-md text-truncate">{recordingFile.fileName}</p>
                    </div>
                    <div className="px-2">
                        <p className="m-0 text-secondary remotive-font-sm">{`${recordingFile.metadata?.namespace}`}</p>
                    </div>
                    <div className="px-2">
                        <p className="m-0 text-secondary remotive-font-sm">{`${
                            recordingFile.metadata?.type ?? 'N/A'
                        }`}</p>
                    </div>
                    <div className="px-2 flex-truncate">
                        <p className="m-0 text-secondary remotive-font-sm text-truncate">{`${recordingFile.metadata?.databaseDetails?.name}`}</p>
                    </div>
                </div>
            </div>
        )
    }

    const demoTextConfigure = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    props.showDemoModal ? 'block' : 'none'
                }`}
                style={{ ...HIGHLIGHT_DEMO_OBJECT_STYLE, marginTop: -185, marginLeft: 0 }}
            >
                <p className="text-light m-0 fs-6">
                    1. Optionally, select another <br />
                    configuration, e.g VSS
                </p>
                <div className="d-flex justify-content-center">
                    <ArrowSouthIcon sx={{ fontSize: 30 }} className="mt-3" />
                </div>
            </div>
        )
    }

    const helpTextPlayAction = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    props.showHelpModal || props.showDemoModal ? 'block' : 'none'
                }`}
                style={{ ...HIGHLIGHT_HELP_OBJECT_STYLE, marginBottom: 50, marginRight: 30 }}
            >
                <p className="text-light m-0">
                    Press to prepare all recording files <br />
                    of this recording session for playback
                </p>
                <div className="d-flex justify-content-end">
                    <ArrowSouthIcon sx={{ fontSize: 30 }} className="mt-2" />
                </div>
            </div>
        )
    }

    const handleCloseFunction = (brokerContainer?: BrokerContainer) => {
        if (brokerContainer !== undefined) {
            setBrokerContainer(brokerContainer)
        }
        setShowApplyToBrokerModal(false)
    }

    const demoTextPlayAction = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    props.showDemoModal ? 'block' : 'none'
                }`}
                style={{ ...HIGHLIGHT_DEMO_OBJECT_STYLE, marginBottom: -65, marginRight: 32 }}
            >
                <div className="d-flex justify-content-end">
                    <ArrowNorthIcon sx={{ fontSize: 30 }} className="mt-2" />
                </div>
                <p className="text-light m-0 fs-6">2. Press here to prepare this recording session for playback</p>
            </div>
        )
    }

    const cliHints = () => {
        const hints: Array<CliHint> = []
        hints.push({
            title: 'Mount recording on broker ("prepare for playback" button above)',
            command: `remotive cloud recordings mount ${props.userRecordingSessionContext.currentRecordingSession?.sessionId} --project ${props.userRecordingSessionContext.currentProject?.uid}`,
        })
        if (
            selectedBrokerConfig !== undefined &&
            selectedBrokerConfig?.name !== DEFAULT_AUTOGENERATED_BROKER_CONFIG_NAME
        ) {
            hints.push({
                title: 'Mount recording on broker with transformation',
                command: `remotive cloud recordings mount ${props.userRecordingSessionContext.currentRecordingSession?.sessionId} --transformation-name '${selectedBrokerConfig.name}'  --project ${props.userRecordingSessionContext.currentProject?.uid}`,
            })
        }
        hints.push(
            {
                title: 'Plays recording (requires mount first)',
                command: `remotive cloud recordings play ${props.userRecordingSessionContext.currentRecordingSession?.sessionId} --project ${props.userRecordingSessionContext.currentProject?.uid}`,
            },
            {
                title: 'Pause recording (requires mount first)',
                command: `remotive cloud recordings pause ${props.userRecordingSessionContext.currentRecordingSession?.sessionId} --project ${props.userRecordingSessionContext.currentProject?.uid}`,
            },
            {
                title: 'Seek to 1 minute into recording (requires mount first)',
                command: `remotive cloud recordings seek ${props.userRecordingSessionContext.currentRecordingSession?.sessionId} --seconds 60 --project ${props.userRecordingSessionContext.currentProject?.uid}`,
            },
            {
                title: 'Stop recording (requires mount first)',
                command: `remotive cloud recordings stop ${props.userRecordingSessionContext.currentRecordingSession?.sessionId} --project ${props.userRecordingSessionContext.currentProject?.uid}`,
            }
        )
        return hints
    }

    return (
        <>
            <Card
                className="shadow-sm rounded-4 border-0 text-start mb-1"
                id="recording-details-playback-accordion"
                style={HIGHLIGHT_DEMO_OBJECT_STYLE}
            >
                <Card.Body className="p-0 d-flex flex-column">
                    <div className="mx-0 d-flex align-items-center flex-fill flex-truncate">
                        <Accordion
                            defaultActiveKey={'0'}
                            aria-expanded="false"
                            flush
                            className="mx-2 p-1 w-100 flex-truncate"
                        >
                            <Accordion.Item
                                className="mx-0 px-0 flex-truncate"
                                eventKey={brokerContainer !== undefined ? '1' : '0'}
                            >
                                <Accordion.Header className="mx-0 p-0 flex-truncate">
                                    <div className="m-1 flex-truncate">
                                        <div className="m-0 p-0 text-truncate">
                                            <b className="me-2">
                                                {props.userRecordingSessionContext.currentRecordingSession?.displayName !== undefined ? (
                                                    'Options'
                                                ) : (
                                                    <span className="p-0 m-0">
                                                        <Spinner className="me-2" size="sm" />
                                                        Loading...
                                                    </span>
                                                )}
                                            </b>
                                        </div>
                                        <div className="d-flex justify-content-between w-100">
                                            <p className="remotive-font-md text-secondary m-0 mt-1 p-0 text-truncate">
                                                {`This recording session is configured with ${
                                                    selectedBrokerConfig?.name || 'the default configuration.'
                                                }`}
                                            </p>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body
                                    onEntered={(eventKey) => setIsPlaybackAccordionCollapsed(true)}
                                    onExited={(eventKey) => setIsPlaybackAccordionCollapsed(false)}
                                    className="mx-0 px-0 pb-2"
                                >
                                    {demoTextConfigure()}
                                    <div>
                                        <p className="m-0 remotive-font-md">Signal transformations</p>
                                        {selectableConfigFileList()}
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <p className="m-0 remotive-font-md">Recording files</p>
                                        </div>

                                        {selectableRecordingFileList()}
                                    </div>
                                    <div
                                        id="recording-details-playback-button"
                                        className="mx-1 mt-0 d-flex mb-0 justify-content-end flex-column align-items-end"
                                    >
                                        <div className="me-0 my-2">
                                            <button
                                                style={{
                                                    position: 'relative',
                                                    zIndex: 1,
                                                    ...HIGHLIGHT_DEMO_OBJECT_STYLE,
                                                    ...HIGHLIGHT_HELP_OBJECT_STYLE,
                                                    borderRadius: '40px 0px 0px 40px',
                                                    paddingTop: 10,
                                                    paddingBottom: 9,
                                                }}
                                                className="btn ps-2 pe-1 m-0 mt-1 remotive-btn-primary"
                                                onClick={() => {
                                                    allowSelectBroker.current = false
                                                    applyRecordingSessionToBroker()
                                                }}
                                            >
                                                <div className="d-flex p-0 justify-content-center align-items-center ps-2">
                                                    <PrepareRecordingIcon sx={{ fontSize: 22 }} />
                                                    <p className="d-none d-sm-block mx-2 m-0 remotive-font-md">
                                                        Prepare for playback
                                                    </p>
                                                    <p className="d-block d-sm-none mx-2 m-0 remotive-font-md">
                                                        Playback
                                                    </p>
                                                </div>
                                            </button>
                                            <button
                                                style={{
                                                    position: 'relative',
                                                    zIndex: 1,
                                                    ...HIGHLIGHT_DEMO_OBJECT_STYLE,
                                                    ...HIGHLIGHT_HELP_OBJECT_STYLE,
                                                    borderRadius: '0px 40px 40px 0px',
                                                    paddingTop: 5,
                                                    paddingBottom: 5,
                                                }}
                                                className="btn border-0 m-0 mt-1 ps-0 pe-1 remotive-btn-primary-dark border-start border-2"
                                                onBlur={() => {
                                                    setShowPlayDropdown(false)
                                                }}
                                                onClick={() => {
                                                    setShowPlayDropdown(!showPlayDropdown)
                                                }}
                                            >
                                                {showPlayDropdown ? (
                                                    <DropupIcon sx={{ fontSize: 35 }} />
                                                ) : (
                                                    <DropdownIcon sx={{ fontSize: 35 }} />
                                                )}
                                            </button>
                                        </div>
                                        {playOptionDropdown()}
                                        {helpTextPlayAction()}
                                        {demoTextPlayAction()}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Card.Body>
            </Card>
            <BrokerAppWithMountedRecording
                isPlaybackAccordionCollapsed={isPlaybackAccordionCollapsed}
                brokerContainer={brokerContainer}
                availableBrokers={props.brokers}
                setBrokerContainer={setBrokerContainer}
                currentRecordingSession={props.userRecordingSessionContext.currentRecordingSession}
            />
            <CliHintContainer
                productAnalyticsProperties={{
                    currentBillableUnit: props.userRecordingSessionContext.currentBillableUnit,
                    currentUser: props.userRecordingSessionContext.currentUser,
                    productAnalyticsContext: ProductAnalyticsContext.RD_PLAYBACK_TAB,
                }}
                hints={cliHints()}
                topMargin={brokerContainer === undefined ? undefined : 'mt-1'}
                bottomMargin={brokerContainer === undefined ? undefined : 'mb-1'}
            />
            <ApplyToBrokerModal
                allowSelectBroker={allowSelectBroker.current}
                currentUser={props.userRecordingSessionContext.currentUser}
                refreshBrokers={() => props.refreshBrokers()}
                brokers={props.brokers}
                currentProject={props.userRecordingSessionContext.currentProject}
                recordingSession={props.userRecordingSessionContext.currentRecordingSession}
                brokerConfigFolder={selectedBrokerConfig}
                recordingFile={undefined}
                show={showApplyToBrokerModal}
                handleCloseFunction={handleCloseFunction}
            />
        </>
    )
}
